import axios from 'axios'
import customAxios from '@/axios/config'

export default {
  namespaced: true,
  state: {
    losSettings: [],
  },
  mutations: {
    updateLosSettings(state, payload) {
      state.losSettings = payload
    },
  },
  actions: {
    async getLosSettings({ commit }) {
      const { data } = await axios.get('api/los-settings')
      commit('updateLosSettings', data)
      return data
    },
    async getLosSetting(store, { losSettingId } = {}) {
      return axios.get(`/api/los-setting/${losSettingId}/`).catch(console.error)
    },
    async updateLosSetting({ dispatch }, { id, ...payload }) {
      await customAxios.put(`/api/los-settings/${id}`, payload)
      dispatch('getLosSettings')
    },
    async createLosSetting({ dispatch }, payload) {
      await customAxios.post(`/api/los-settings`, payload)
      dispatch('getLosSettings')
    },
  },
}
