var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loading),expression:"!loading"}]},[_c('div',{staticClass:"grey lighten-4"},[_c('v-container',{staticClass:"max-w-1600 pb-0"},[_c('v-tabs',{attrs:{"slider-color":"white","background-color":"#f6f6f7","active-class":"white","grow":_vm.isMobile},model:{value:(_vm.paramsTab),callback:function ($$v) {_vm.paramsTab=$$v},expression:"paramsTab"}},[_c('v-tab',{attrs:{"disabled":!(
              _vm.showModule('accounting') &&
              (_vm.hasBankAccounts || _vm.isAdmin || _vm.isAccountant)
            ),"href":"#accounting"}},[_c('v-icon',{staticClass:"mr-md-2"},[_vm._v("$calculator")]),_c('span',{staticClass:"d-none d-md-inline text-capitalize"},[_vm._v(_vm._s(_vm.$t('Bank Transactions')))])],1),(
            _vm.showModule('accounting') && _vm.hasAbility('modify-bank-accounts')
          )?_c('v-tab',{attrs:{"href":"#bank-accounts"}},[_c('v-icon',{staticClass:"mr-md-2"},[_vm._v("mdi-bank-outline")]),_c('span',{staticClass:"d-none d-md-inline text-capitalize"},[_vm._v("Bank Accounts")])],1):_vm._e(),(
            _vm.showModule('accounting') && _vm.hasAbility('modify-bank-accounts')
          )?_c('v-tab',{attrs:{"href":"#bank-account-settings"}},[_c('v-icon',{staticClass:"mr-md-2"},[_vm._v("mdi-cog-outline")]),_c('span',{staticClass:"d-none d-md-inline text-capitalize"},[_vm._v("Bank Account Settings")])],1):_vm._e(),(
            _vm.isDesignedVr &&
            _vm.showModule('accounting') &&
            _vm.hasAbility('modify-bank-accounts')
          )?_c('v-tab',{attrs:{"href":"#ledger"}},[_c('v-icon',{staticClass:"mr-md-2"},[_vm._v("mdi-book-open-variant")]),_c('span',{staticClass:"d-none d-md-inline text-capitalize"},[_vm._v("Ledger Accounts")])],1):_vm._e(),(
            _vm.isDesignedVr &&
            _vm.showModule('accounting') &&
            _vm.hasAbility('modify-bank-accounts')
          )?_c('v-tab',{attrs:{"href":"#general-ledger"}},[_c('v-icon',{staticClass:"mr-md-2"},[_vm._v("mdi-file-document-multiple-outline")]),_c('span',{staticClass:"d-none d-md-inline text-capitalize"},[_vm._v(" General Ledger ")])],1):_vm._e(),(
            _vm.isDesignedVr &&
            _vm.showModule('accounting') &&
            _vm.hasAbility('modify-bank-accounts')
          )?_c('v-tab',{attrs:{"href":"#ledger-settings"}},[_c('v-icon',{staticClass:"mr-md-2"},[_vm._v("mdi-cog-transfer-outline")]),_c('span',{staticClass:"d-none d-md-inline text-capitalize"},[_vm._v(" Ledger Settings ")])],1):_vm._e()],1)],1)],1),_c('div',[_c('div',[_c('v-tabs-items',{attrs:{"touchless":""},model:{value:(_vm.paramsTab),callback:function ($$v) {_vm.paramsTab=$$v},expression:"paramsTab"}},[(_vm.hasAbility('modify-bank-accounts'))?_c('v-tab-item',{attrs:{"value":"bank-accounts"}},[_c('bank-accounts')],1):_vm._e(),(_vm.hasBankAccounts || _vm.isAdmin || _vm.isAccountant)?_c('v-tab-item',{staticClass:"pl-4 pb-2 pr-4 main-background",attrs:{"value":"accounting"}},[_c('bank-transactions',{staticClass:"pt-3"})],1):_vm._e(),(_vm.isDesignedVr && _vm.hasAbility('modify-bank-accounts'))?_c('v-tab-item',{attrs:{"value":"ledger"}},[_c('ledger-accounts')],1):_vm._e(),(_vm.hasAbility('modify-bank-accounts'))?_c('v-tab-item',{attrs:{"value":"bank-account-settings"}},[_c('bank-account-settings')],1):_vm._e(),(_vm.isDesignedVr && _vm.hasAbility('modify-bank-accounts'))?_c('v-tab-item',{attrs:{"value":"general-ledger"}},[_c('general-ledger')],1):_vm._e(),(_vm.isDesignedVr && _vm.hasAbility('modify-bank-accounts'))?_c('v-tab-item',{attrs:{"value":"ledger-settings"}},[_c('ledger-settings')],1):_vm._e()],1)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }