<template>
  <div class="ma-6">
    <v-card
      outlined
      class="d-flex justify-space-between py-4 px-8 rounded-lg pre-checkin-container"
    >
      <pre-checkin-stat
        label="Satisfaction Score"
        :value="stats.reviews_avg_score"
        color="var(--v-success600-base)"
      />
      <pre-checkin-stat
        label="Total Revenue"
        :value="stats.fare_accommodations_total"
        color="var(--v-primary600-base)"
      />
      <pre-checkin-stat
        label="Top Interests"
        :value="stats.top_interests"
        color="var(--v-secondary600-base)"
      />
      <pre-checkin-stat
        label="Upcoming visits"
        :value="stats.future_reservations_count"
        color="var(--v-dark500-base)"
      />
    </v-card>
    <div class="me-2 my-2 d-flex justify-space-between">
      <div>
        <v-btn outlined small class="filter-btn" disabled> Filters </v-btn>
      </div>
      <div>
        <v-text-field
          v-model="search"
          outlined
          dense
          prepend-inner-icon="mdi-magnify"
          label="Search"
          hide-details
          class="small-text-field"
          @keyup="searchDebouncer(1, $event)"
        />
      </div>
    </div>
    <guest-modal
      :key="selectedGuestId"
      :value.sync="showModal"
      :guest-id="selectedGuestId"
    />
    <v-data-table
      :headers="headers"
      :items="items"
      disable-sort
      class="elevation-0"
      :loading="guestBookLoading"
      :options="pagination"
      :server-items-length="pagination.count"
      @click:row="rowClick"
      @pagination="paginationChanged($event)"
    >
      <template #item.full_name="{ item }">
        <div class="d-flex align-center my-2">
          <div class="me-2">
            <v-avatar size="35" color="light400">
              <span class="black--text font-weight-medium">{{
                initialsFromFullName(item.full_name)
              }}</span>
            </v-avatar>
          </div>
          <div>
            <div class="text-body-2 font-weight-medium d-flex align-center">
              {{ item.full_name }}
              <div
                v-for="tag in item.extra_data?.user_tags || []"
                :key="tag"
                class="text-capitalize font-weight-medium py-0 px-2 rounded-pill text-caption black950--text ms-2 d-flex align-center"
                :style="`background-color: ${randomColors(tag)}1A;`"
              >
                <div
                  class="dot me-1"
                  :style="`background-color: ${randomColors(tag)};`"
                />
                {{ tag }}
              </div>
            </div>
            <div class="dark500--text text-caption font-weight-medium">
              {{ item.primary_email }}
            </div>
          </div>
        </div>
      </template>
      <template #item.profile_tags="{ item }">
        <v-chip
          v-for="tag in item.extra_data?.profile_tags"
          :key="tag"
          label
          small
          outlined
          class="me-2"
        >
          {{ tag }}</v-chip
        >
      </template>
    </v-data-table>
  </div>
</template>

<script>
import PreCheckinStat from 'components/crm/pre-checkin-stat.vue'
import GuestModal from 'components/guests/guest-modal.vue'
import CommonFunctions from 'components/mixins/common_functions'
import FormattersMixin from 'components/mixins/formatters-mixin'
import debounce from 'lodash/debounce'

export default {
  name: 'PreCheckIns',
  mixins: [CommonFunctions, FormattersMixin],
  components: {
    PreCheckinStat,
    GuestModal,
  },
  data() {
    return {
      searchDebouncer: null,
      search: '',
      showModal: false,
      selectedGuestId: null,
      pagination: {},
      guestBookLoading: false,
      stats: {},
      headers: [
        { text: 'Full Name', value: 'full_name' },
        {
          text: 'Lifetime Value',
          value: 'fare_accommodations_total',
          align: 'center',
        },
        { text: 'Reviews', value: 'extra_data.reviews_count', align: 'center' },
        {
          text: 'Club member',
          value: 'extra_data.club_member',
          align: 'center',
        },
        {
          text: 'Last Comm',
          value: 'extra_data.last_communication',
          align: 'center',
        },
        {
          text: 'Last Visit',
          value: 'extra_data.last_reservation',
          align: 'center',
        },
        { text: 'Profile Tags', value: 'profile_tags' },
      ],
      items: [
        {
          guest: { full_name: 'John Doe', email: 'chaim@boomnow.com' },
          value: '$1,200',
          id: 1,
          review: 5,
          member: 'Yes',
          last_comm: '2023-09-15',
          last_visit: '2023-09-10',
          profile_tags: ['Short Stay', 'Business'],
          user_tag: 'VIP',
        },
      ],
    }
  },
  mounted() {
    this.searchDebouncer = debounce(this.fetchGuests, 500)
    this.fetchGuests()
  },
  methods: {
    async fetchGuests(page = 1) {
      this.guestBookLoading = true
      const { guests, pagination, stats } = await this.$store.dispatch(
        'guests/fetchGuests',
        {
          term: this.search,
          guest_book: true,
          page,
        }
      )
      this.stats = stats
      this.pagination = pagination
      this.items = guests.map(g => {
        const { last_communication, last_reservation, lifetime_value } =
          g.extra_data || {}
        return {
          ...g,
          extra_data: {
            ...g.extra_data,
            last_communication: this.parseDate(last_communication),
            last_reservation: this.parseDate(last_reservation),
            lifetime_value: this.toMoney(lifetime_value),
          },
        }
      })
      this.guestBookLoading = false
    },
    rowClick(item) {
      this.selectedGuestId = item.id
      this.showModal = true
    },
    paginationChanged(pagination) {
      if (pagination.page === 1) return
      this.fetchGuests(pagination.page)
    },
  },
}
</script>

<style>
.pre-checkin-container {
  border-color: none !important;
  background-color: var(--v-light100-base) !important;
}
.filter-btn {
  border-color: var(--v-light400-base);
}
.small-text-field {
  transform: scale(0.8);
  left: 30px;
}
.dot {
  width: 5;
  height: 5;
  border-radius: 50%;
  display: inline-block;
}
</style>
