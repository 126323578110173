<template>
  <v-row no-gutters class="justify-space-between align-center flex-wrap">
    <v-col v-if="paidAt" class="mb-2" cols="auto">
      <p class="text-caption mb-0">Invoice created at</p>
      <span class="font-weight-medium">{{ paidAt }}</span>
    </v-col>
    <v-col v-if="dueDate" class="mb-2" cols="auto">
      <p class="text-caption mb-0">Due Date</p>
      <span class="font-weight-medium">{{ dueDate }}</span>
    </v-col>
    <v-col v-if="createdByName" class="mb-2" cols="auto">
      <p class="text-caption mb-0">Created by</p>
      <span class="font-weight-medium"> {{ createdByName }} </span>
    </v-col>
    <v-col v-if="invoiceNumber" class="mb-2" cols="auto">
      <p class="text-caption mb-0">Invoice Number</p>
      <span class="font-weight-medium"> {{ invoiceNumber }} </span>
    </v-col>
    <v-col v-if="reference" class="mb-2" cols="auto">
      <p class="text-caption mb-0">Reference</p>
      <span class="font-weight-medium"> {{ reference }} </span>
    </v-col>
    <v-col v-if="amount" class="mb-2" cols="auto">
      <p class="text-caption mb-0">Amount</p>
      <span class="font-weight-medium">
        {{ dollarFormatter(amount) }}
      </span>
    </v-col>
  </v-row>
</template>

<script>
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import FormattersMixin from 'components/mixins/formatters-mixin'
export default {
  mixins: [CommonFunctions, PermissionsMixin, FormattersMixin],
  props: [
    'paidAt',
    'dueDate',
    'amount',
    'createdByName',
    'invoiceNumber',
    'reference',
  ],
  computed: {
    totalCost() {
      return (
        (this.laborCost || 0) +
        (this.materialCost || 0) +
        (this.managementFee || 0)
      )
    },
  },
}
</script>
