<template>
  <v-main>
    <!--    <v-snackbar bottom right :value="updateExists" :timeout="0" color="primary">-->
    <!--      An update is available-->
    <!--      <v-btn text @click="refreshApp"> Update </v-btn>-->
    <!--    </v-snackbar>-->
    <app-header :drawer.sync="drawer" :show-back-button="showBackButton" />
    <task-drawer :value="taskId" :task-id="taskId" />
    <v-navigation-drawer
      v-model="drawer"
      app
      fixed
      temporary
      :right="RTLmode"
      hide-overlay
      style="z-index: 1000"
      width="300"
    >
      <v-list nav dense>
        <v-list-item class="justify-space-between mb-4">
          <v-icon small @click="drawer = false">$cancel</v-icon>
          <router-link
            class="mx-auto"
            :to="defaultRouteByRole"
            @click="display = 'listings'"
          >
            <img class="mt-2 mr-2" style="zoom: 0.7" :src="logo" height="50" />
          </router-link>
          <locale-changer class="mx-2" />
        </v-list-item>
        <login-as-tenant v-if="isSystemAdmin && !isAdminTenant" />
        <v-select
          v-if="user && isAdmin"
          :value="user.zone_id"
          :items="listingZones"
          :label="$t('Zone')"
          dense
          hide-details
          outlined
          class="pb-2 mt-1"
          @change="onZoneChange($event)"
        />
        <template v-for="(item, index) in filteredMenuItems">
          <v-list-group
            v-if="item.submenu"
            :key="index"
            :value="isGroupActive(item.submenu)"
            active-class="grey lighten-4"
            no-action
          >
            <template #activator>
              <v-list-item-action class="mr-1 ml-3">
                <v-icon small>{{ item.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  <span class="text-body-2 font-weight-medium dark700--text">
                    {{ $t(item.text) }}
                  </span>
                </v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item
              v-for="child in item.filteredSubmenu"
              :key="child.title"
              :to="child.value"
              class="ps-12"
              active-class="grey lighten-4"
            >
              <v-list-item-content>
                <v-list-item-title class="d-flex align-center">
                  <v-icon small class="me-2">{{ child.icon }}</v-icon>
                  <span class="text-body-2 font-weight-medium dark700--text">
                    {{ $t(child.text) }}
                  </span>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>

          <v-list-item
            v-else
            :key="index"
            :to="item.value"
            active-class="grey lighten-4"
          >
            <v-list-item-action class="mr-1 ml-3">
              <v-icon small>{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                <span class="text-body-2 font-weight-medium dark700--text">
                  {{ $t(item.text) }}
                </span>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
      <template v-if="user" #append>
        <v-list>
          <template>
            <v-divider />
            <v-list-item to="/dashboard/user-profile">
              <v-list-item-avatar>
                <user-avatar
                  :image="user.picture"
                  :name="`${user.first_name} ${user.last_name}`"
                  :role="user.role"
                  :size="40"
                />
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title
                  v-if="user.first_name || user.last_name"
                  class="font-weight-light"
                >
                  {{ user.first_name }} {{ user.last_name }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  <span class="text-caption font-weight-light">
                    {{ user.email }}
                  </span>
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn icon small @click.prevent="logout">
                  <v-icon small>$logout</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </template>
        </v-list>
      </template>
    </v-navigation-drawer>
    <div v-if="loading" class="center-overlay">
      <LoaderAnimationPalms />
    </div>
    <video-popup v-if="$store.state.chosenVideo"></video-popup>
    <info-item-pop v-if="$store.state.chosenInfoItem" />
    <inbox-popup v-if="$store.state.chosenConversation"></inbox-popup>
    <listing-setup-task-thread v-if="$store.state.chosenListingSetupTask" />
    <div v-if="user" class="flex-fill">
      <router-view />
    </div>
    <vr-footer v-show="display === 'listings' && !loading && !noFooterPage" />
  </v-main>
</template>

<script>
import axios from 'axios'
import { mapActions, mapState } from 'vuex'

import VrFooter from 'components/footer'
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import UpdateMixin from 'components/mixins/update'
import LoaderAnimationPalms from 'components/loaders/loader-animation-palms'
import InfoItemPop from 'components/info-item-pop'
import ListingSetupTaskThread from 'components/listing-setup-task-thread'
import VideoPopup from 'components/video-popup'
import InboxPopup from 'components/inbox-popup'
import menuItems from 'components/menu/menu-items'
import UserAvatar from 'components/common/user-avatar'
import AppHeader from 'components/layouts/app-header'
import Mention from 'quill-mention'
import Quill from 'quill'
import hotjar from '@/utils/hotjar'
import LocaleChanger from 'components/layouts/locale-changer.vue'
import TaskDrawer from 'components/listing-report/task-drawer.vue'
import LoginAsTenant from 'components/tenant-settings/login-as-tenant.vue'

Quill.register('modules/mention', Mention)
export default {
  components: {
    LoginAsTenant,
    TaskDrawer,
    LocaleChanger,
    AppHeader,
    LoaderAnimationPalms,
    'vr-footer': VrFooter,
    ListingSetupTaskThread,
    VideoPopup,
    InboxPopup,
    UserAvatar,
    InfoItemPop,
  },
  mixins: [CommonFunctions, PermissionsMixin, UpdateMixin],
  data() {
    return {
      drawer: false,
      showBackButton: false,
      registration: null,
      updateExists: false,
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler(to, from) {
        if (
          to &&
          from &&
          to.name === from.name &&
          (!to.params.id || to.params.id == from.params.id)
        )
          return
        this.$store.commit('destroyCurrentEditListing')
        this.showBackButton =
          this.$router.history._startLocation !== to.fullPath
        if (to.path.includes('/field-management')) {
          this.$store.dispatch('getRequestsByFilter', {
            request_type: 'Repair',
          })
        } else if (to.path.includes('/listing-setup/')) {
          this.$store.dispatch('getListingSetup', to.path.split('/')[3])
        } else if (to.path.includes('/dashboard/pricing/')) {
          this.$store.dispatch('getListingPricing', to.path.split('/')[3])
        } else if (to.path.includes('/guests/')) {
          this.$store.dispatch('getCurrentGuest', to.path.split('/')[3])
        } else if (to.path.includes('/company-activity')) {
          this.$store.dispatch('getCompanyActivity')
        } else if (to.path === '/dashboard/experience-management') {
          this.$store.dispatch('getExperienceRequests')
        }
      },
    },
  },
  beforeDestroy() {
    this.$store.dispatch('unsubscribeFromApplicationChannel')
    this.$store.dispatch('unsubscribeFromInquiryChannel')
  },
  async created() {
    document.addEventListener('swUpdated', this.updateAvailable, { once: true })
    try {
      await this.$store.dispatch('getConfiguration')
    } catch (err) {
      return this.$router.push('/login')
    }
    await this.$store.dispatch('regions/getRegions')
    await this.$store.dispatch('zones/getZones')
    // await this.$store.dispatch('getAiPolicy')
    this.$store.dispatch('getBankAccount')
    const user = await this.$store.dispatch('getCurrentUser')
    this.$i18n.locale = user.locale
    hotjar.identify(user)
    this.$store.dispatch('userGroups/getUserGroups')
    this.$store.dispatch('users/getAllUsers')
    this.$store.dispatch('users/getTeams')
    this.$store.dispatch('getPmsData')
    this.$store.dispatch('getUserNotifications')
    this.$store.dispatch('getListingsPicker')
    this.$store.dispatch('getListingsCities')
    this.$store.dispatch('getAllCities')
    this.$store.dispatch('storages/getStorages')
    if (!this.isInvestor) {
      this.$store.dispatch('getListingTaskCatalogItems')
    }
    this.registerChannels()
    window.Intercom('boot', {
      api_base: 'https://api-iam.intercom.io',
      app_id: 'rjv8g8a2',
      name: `${user.first_name} ${user.last_name}`, // Full name
      email: user.email, // the email for your user
      tenant_id: user.tenant_id,
      id: user.id,
      phone: user.phone,
      role: user.role,
    })
  },
  computed: {
    ...mapState(['user', 'loading']),
    ...mapState('zones', ['zones']),
    logo() {
      return this.$store.state.app.configuration.logo_url
    },
    taskId() {
      return this.$route.query.reportTaskDrawer
    },
    filteredMenuItems() {
      return this.filterMenuItems(menuItems)
    },
    display: {
      get() {
        return this.$store.state.display
      },
      set(display) {
        this.$store.commit('updateDisplay', display)
      },
    },
    noFooterPage() {
      return ['lt-page', 'multi-calendar'].includes(this.$route.name)
    },
    listingZones() {
      const availableZones = this.zones.map(r => ({
        text: r.name,
        value: r.id,
      }))
      return [{ text: 'Global', value: null }].concat(availableZones)
    },
  },
  methods: {
    ...mapActions([
      'subscribeToInquiryChannel',
      'subscribeToApplicationChannel',
    ]),
    registerChannels() {
      this.subscribeToApplicationChannel()
      if (this.user.department === 'Sales') {
        this.subscribeToInquiryChannel()
      }
    },
    updateAvailable(event) {
      this.registration = event.detail
      this.updateExists = true
    },
    logout() {
      const routerElement = this.$router
      axios
        .post('/api/logout')
        .then(() => routerElement.push('/login'))
        .catch(() => ({}))
    },
    handleBack() {
      this.$router.back()
    },
    async onZoneChange(value) {
      await this.$store.dispatch('users/updateUserNew', {
        id: this.user.id,
        zone_id: value,
      })
      location.reload()
    },
    filterMenuItems(items = []) {
      return items.filter(item => {
        if (item.submenu) {
          item.active = true
          item.filteredSubmenu = this.filterMenuItems(item.submenu)
          return item.filteredSubmenu.length
        }
        if (this.user && this.user.is_demo) {
          return item.demo_only
        }
        if (item.abilityOrRole) {
          return (
            ((this.isAllowed(item.allowed_roles) &&
              this.isNotAllowed(item.not_allowed_roles)) ||
              this.hasAbility(item.abilities)) &&
            this.showModule(item.module)
          )
        }
        return (
          this.isAllowed(item.allowed_roles) &&
          this.isNotAllowed(item.not_allowed_roles) &&
          this.hasAbility(item.abilities) &&
          this.showModule(item.module)
        )
      })
    },
    isGroupActive(submenu) {
      return submenu.some(subItem => this.$route.path.includes(subItem.value))
    },
  },
}
</script>
