export const GOOGLE_MAP_API_KEY = 'AIzaSyCzvYaJgsS0jhNhEjzREy_G9Quie3zc7A4'

export const DISABLED_STORE_ITEM_STATUSES = [
  'not_available',
  'in_bag',
  'purchased',
  'future',
]

export const PROJECT_STATUSES = [
  { key: 'ACTIVE', text: 'Active' },
  { key: 'DONE', text: 'Done' },
]

export const MAX_SC_TASKS_RESULT = 600

export const TASK_STATUSES = {
  NEW: 'New',
  IN_PROGRESS: 'In Progress',
  REJECTED: 'Rejected',
  OWNER_APPROVAL: 'Owner Approval',
  OWNER_APPROVED: 'Owner Approved',
  PENDING_APPROVAL: 'Pending Approval',
  INVESTIGATION: 'Investigation',
  DONE: 'Done',
  CANT_DO: "Can't do",
  CLOSED: 'Closed',
  PAUSED: 'Paused',
  IN_REVIEW: 'In Review',
}

export const TASK_SUB_STATUSES = {
  PENDING_APPROVAL: 'Pending Approval',
  APPROVED: 'Approved',
  REJECTED: 'Rejected',
  PENDING_PAYMENT: 'Pending payment',
}

export const MANAGMENT_TABLE_TYPES = {
  LINK: 'LINK',
  CHECK: 'CHECK',
  ACTION: 'ACTION',
  COMPUTED: 'COMPUTED',
  IMAGE: 'IMAGE',
  CONFIRMED_ACTION: 'CONFIRMED_ACTION',
}

export const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

export const SHORT_MONTHS = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
]

export const INTERNAL_RESERVATION_SOURCES = [
  'Reception',
  'grand',
  'phone',
  'TheWorthington',
  'TheGrand',
  'manual',
  'Manual',
  'Designedvr.com',
]

export const LISTING_TYPES = {
  HOTEL: 'hotel',
}

export const PRIORITIES_ORDER = {
  critical: 0,
  high: 1,
  med: 2,
  low: 3,
  none: 4,
}

export const STRIPE_WEBHOOKS = [
  'account.updated',
  'account.external_account.created',
  'account.external_account.deleted',
  'account.external_account.updated',
  'balance.available',
  'billing_portal.configuration.created',
  'billing_portal.configuration.updated',
  'billing_portal.session.created',
  'capability.updated',
  'cash_balance.funds_available',
  'charge.captured',
  'charge.expired',
  'charge.failed',
  'charge.pending',
  'charge.refunded',
  'charge.succeeded',
  'charge.updated',
  'charge.dispute.closed',
  'charge.dispute.created',
  'charge.dispute.funds_reinstated',
  'charge.dispute.funds_withdrawn',
  'charge.dispute.updated',
  'charge.refund.updated',
  'checkout.session.async_payment_failed',
  'checkout.session.async_payment_succeeded',
  'checkout.session.completed',
  'checkout.session.expired',
  'coupon.created',
  'coupon.deleted',
  'coupon.updated',
  'credit_note.created',
  'credit_note.updated',
  'credit_note.voided',
  'customer.created',
  'customer.deleted',
  'customer.updated',
  'customer.discount.created',
  'customer.discount.deleted',
  'customer.discount.updated',
  'customer.source.created',
  'customer.source.deleted',
  'customer.source.expiring',
  'customer.source.updated',
  'customer.subscription.created',
  'customer.subscription.deleted',
  'customer.subscription.pending_update_applied',
  'customer.subscription.pending_update_expired',
  'customer.subscription.trial_will_end',
  'customer.subscription.updated',
  'customer.tax_id.created',
  'customer.tax_id.deleted',
  'customer.tax_id.updated',
  'customer_cash_balance_transaction.created',
  'file.created',
  'financial_connections.account.created',
  'financial_connections.account.deactivated',
  'financial_connections.account.disconnected',
  'financial_connections.account.reactivated',
  'financial_connections.account.refreshed_balance',
  'identity.verification_session.canceled',
  'identity.verification_session.created',
  'identity.verification_session.processing',
  'identity.verification_session.redacted',
  'identity.verification_session.requires_input',
  'identity.verification_session.verified',
  'invoice.created',
  'invoice.deleted',
  'invoice.finalization_failed',
  'invoice.finalized',
  'invoice.marked_uncollectible',
  'invoice.paid',
  'invoice.payment_action_required',
  'invoice.payment_failed',
  'invoice.payment_succeeded',
  'invoice.sent',
  'invoice.upcoming',
  'invoice.updated',
  'invoice.voided',
  'invoiceitem.created',
  'invoiceitem.deleted',
  'invoiceitem.updated',
  'issuing_authorization.created',
  'issuing_authorization.request',
  'issuing_authorization.updated',
  'issuing_card.created',
  'issuing_card.updated',
  'issuing_cardholder.created',
  'issuing_cardholder.updated',
  'issuing_dispute.closed',
  'issuing_dispute.created',
  'issuing_dispute.funds_reinstated',
  'issuing_dispute.submitted',
  'issuing_dispute.updated',
  'issuing_transaction.created',
  'issuing_transaction.updated',
  'mandate.updated',
  'order.created',
  'payment_intent.amount_capturable_updated',
  'payment_intent.canceled',
  'payment_intent.created',
  'payment_intent.partially_funded',
  'payment_intent.payment_failed',
  'payment_intent.processing',
  'payment_intent.requires_action',
  'payment_intent.succeeded',
  'payment_link.created',
  'payment_link.updated',
  'payment_method.attached',
  'payment_method.automatically_updated',
  'payment_method.detached',
  'payment_method.updated',
  'payout.canceled',
  'payout.created',
  'payout.failed',
  'payout.paid',
  'payout.updated',
  'person.created',
  'person.deleted',
  'person.updated',
  'plan.created',
  'plan.deleted',
  'plan.updated',
  'price.created',
  'price.deleted',
  'price.updated',
  'product.created',
  'product.deleted',
  'product.updated',
  'promotion_code.created',
  'promotion_code.updated',
  'quote.accepted',
  'quote.canceled',
  'quote.created',
  'quote.finalized',
  'radar.early_fraud_warning.created',
  'radar.early_fraud_warning.updated',
  'recipient.created',
  'recipient.deleted',
  'recipient.updated',
  'reporting.report_run.failed',
  'reporting.report_run.succeeded',
  'reporting.report_type.updated',
  'review.closed',
  'review.opened',
  'setup_intent.canceled',
  'setup_intent.created',
  'setup_intent.requires_action',
  'setup_intent.setup_failed',
  'setup_intent.succeeded',
  'sigma.scheduled_query_run.created',
  'sku.created',
  'sku.deleted',
  'sku.updated',
  'source.canceled',
  'source.chargeable',
  'source.failed',
  'source.mandate_notification',
  'source.refund_attributes_required',
  'source.transaction.created',
  'source.transaction.updated',
  'subscription_schedule.aborted',
  'subscription_schedule.canceled',
  'subscription_schedule.completed',
  'subscription_schedule.created',
  'subscription_schedule.expiring',
  'subscription_schedule.released',
  'subscription_schedule.updated',
  'tax_rate.created',
  'tax_rate.updated',
  'terminal.reader.action_failed',
  'terminal.reader.action_succeeded',
  'test_helpers.test_clock.advancing',
  'test_helpers.test_clock.created',
  'test_helpers.test_clock.deleted',
  'test_helpers.test_clock.internal_failure',
  'test_helpers.test_clock.ready',
  'topup.canceled',
  'topup.created',
  'topup.failed',
  'topup.reversed',
  'topup.succeeded',
  'transfer.created',
  'transfer.reversed',
  'transfer.updated',
]

export const GOOGLE_MAPS_URL = 'https://maps.google.com/maps'

export const BOOM_AMENITIES = [
  'Air conditioning',
  'BBQ grill',
  'Balcony',
  'Billiards / Pool Tables',
  'Carbon monoxide detector',
  'Coffee maker',
  'Dishwasher',
  'Dryer',
  'Fireplace',
  'Game room',
  'Hair dryer',
  'Hot Tub',
  'Iron',
  'Kitchen',
  'Microwave',
  'Outdoor pool',
  'Oven',
  'Ping-pong table',
  'Private pool',
  'Refrigerator',
  'Smoke detector',
  'Stove',
  'Swimming pool',
  'TV',
  'Theater room',
  'Toaster',
  'Washer',
  'outdoor fireplace',
  'sofabed',
]

export const NET_INCOME_FORMULAS = [
  'fare_accommodation + cleaning_fee - reported_channel_fee',
  'fare_accommodation - cleaning_fee - host_channel_fee - processing_fee',
  'total_payout - cleaning_fee - reported_channel_fee - processing_fee',
  'client_price - cleaning_fee - host_channel_fee - processing_fee',
  'fare_accommodation - host_channel_fee - processing_fee',
  'total_payout - cleaning_fee - reported_channel_fee',
  'total_payout - reported_channel_fee - processing_fee',
  'fare_accommodation - reported_channel_fee',
  'total_payout - cleaning_fee - host_channel_fee - processing_fee',
  'fare_accommodation',
  'host_payout',
]

export const OWNER_REVENUE_FORMULAS = [
  'net_income - management_commission',
  'fare_accommodation - management_commission',
  'fare_accommodation - management_commission - host_channel_fee',
  'fare_accommodation - management_commission - host_channel_fee - processing_fee',
]

export const MANAGEMENT_COMMISSION_FORMULAS = [
  '(fare_accommodation + cleaning_fee) * commission_percent',
  '(fare_accommodation + total_taxes) * commission_percent',
  'fare_accommodation * commission_percent',
  'net_income * commission_percent',
  'host_payout * commission_percent',
  'total_payout * commission_percent',
]
export const OWNERS_INCOME_FORMULAS = [
  'client_profit',
  'client_price',
  'fare_accommodation',
  'net_income',
  'price_total',
]
export const INCOME_VAT_FORMULA = [
  'host_payout',
  'fare_accommodation',
  'fare_accommodation + cleaning_fee',
]

export const LANGUAGES = [
  { lang: 'en', country: 'us', label: 'English' },
  { lang: 'es', country: 'es', label: 'Spanish' },
  { lang: 'he', country: 'il', label: 'Hebrew' },
  { lang: 'ru', country: 'ru', label: 'Russian' },
  { lang: 'el', country: 'gr', label: 'Greek' },
  { lang: 'fr', country: 'fr', label: 'French' },
  { lang: 'pt', country: 'pt', label: 'Portuguese' },
]

export const MARBELLA_DEMO_LISTING_ID = 7841

export const BED_CAPACITY = {
  'queen size': 2,
  mattress: 1,
  'king size': 2,
  'extra bed': 1,
  'bunk bed': 2,
  'sofa bed': 2,
  'double sofa bed': 2,
  'double bed': 2,
  'single bed': 1,
  'Bunk beds (sgl on top, dbl on bottom)': 3,
  'Armchair that can turn into a single bed': 1,
  'Pair of twin beds': 2,
  'day bed': 1,
  Wallbed: 2,
  'Pull-Out Bed': 1,
  'Canopy / Poster Bed': 2,
  'Murphy Bed': 2,
  'Rollaway Beds': 1,
  'Tribunk bed': 3,
  'French bed': 1,
  'Extra large double': 2,
  'large double': 2,
  'Floor mattress': 1,
  'Air mattress': 1,
  'futon mat': 1,
  'Double futon mat': 2,
  'baby bed': 1,
}

export const MESSAGE_CHANNELS = [
  'Airbnb',
  'PlumGuide',
  'Marriott',
  'HomeAway',
  'TripAdvisor',
  'Booking.com',
  'Expedia',
  'Agoda',
  'Travel Station',
  'Agents',
  'Direct',
  'Owner',
  'manual',
  'Zoopla',
]

export const CHEKIN_SDK_CDN = {
  STAGING: 'https://cdn.chekin.com/housings-sdk/dev/index.umd.js',
  PRODUCTION: 'https://cdn.chekin.com/housings-sdk/latest/index.umd.js',
}

export const STATUS_COLORS = {
  inquiry: {
    background: '#EA87001A',
    text: '#EA8700',
  },
  confirmed: {
    background: '#00A86B1A',
    text: '#00A86B',
  },
  canceled: {
    background: '#FF00001A',
    text: '#FF0000',
  },
  reserved: {
    background: '#FFC1071A',
    text: '#FFC107',
  },
  declined: {
    background: '#FF00001A',
    text: '#FF0000',
  },
  expired: {
    background: '#FF00001A',
    text: '#FF0000',
  },
  closed: {
    background: '#FF00001A',
    text: '#FF0000',
  },
  'agent-confirmed': {
    background: '#00A86B1A',
    text: '#00A86B',
  },
}
