<template>
  <div>
    <v-row>
      <v-col cols="12" sm="3">
        <v-btn-toggle
          v-model="selectedSettings"
          class="flex-column d-flex py-4 px-2"
          color="primary"
        >
          <v-btn :disabled="!tenantSettings" value="billing">Billing</v-btn>
          <v-btn :disabled="!tenantSettings" value="bi">BI</v-btn>
          <v-btn :disabled="!tenantSettings" value="messaging">Messaging</v-btn>
          <v-btn :disabled="!tenantSettings" value="reservations"
            >Reservations</v-btn
          >
          <v-btn :disabled="!tenantSettings" value="ai">AI</v-btn>
          <v-btn :disabled="!tenantSettings" value="bulk_actions"
            >Bulk Actions</v-btn
          >
        </v-btn-toggle>
      </v-col>
      <v-col cols="12" sm="9">
        <v-row class="w-100 pa-2">
          <div class="d-flex align-center justify-space-between w-100">
            <div class="d-flex flex-center">
              <v-autocomplete
                v-model="selectedTenant"
                :items="tenants"
                :label="$t('Tenant')"
                dense
                :disabled="!tenants.length"
                item-text="company_name"
                item-value="tenant_id"
                hide-details
                outlined
                class="d-inline-block my-2"
                @change="fetchTenantSettings"
              />
              <div v-if="selectedSettings === 'billing'" class="ms-6">
                <div class="pa-2">
                  <tenants-table />
                </div>
              </div>
            </div>
            <div class="mt-4 mb-2">
              <v-btn
                v-if="selectedTenant"
                color="primary"
                depressed
                @click="save"
              >
                Save</v-btn
              >
            </div>
          </div>
        </v-row>
        <div v-if="loading" class="w-100 text-center">
          <v-progress-circular
            :width="2"
            :size="25"
            class="ml-2"
            indeterminate
            color="primary"
          />
        </div>
        <v-row v-else class="w-100 pa-2">
          <tenant-billing
            v-if="selectedSettings === 'billing' && billing"
            v-model="billing"
            @fetch-settings="fetchTenantSettings"
          />
          <tenant-bi v-if="selectedSettings === 'bi'" v-model="bi" />
          <tenant-messaging
            v-if="selectedSettings === 'messaging'"
            v-model="messaging"
            :tenant-id="selectedTenant"
          />
          <tenant-reservations
            v-if="selectedSettings === 'reservations'"
            :tenant="selectedTenant"
          />
          <tenant-ai
            v-if="selectedSettings === 'ai'"
            :settings="tenantSettings"
            :tenant-id="selectedTenant"
          />
          <bulk-actions
            v-if="selectedSettings === 'bulk_actions'"
            :tenant="selectedTenant"
          />
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import TenantBi from './tenant-bi.vue'
import tenantBilling from 'components/tenant-settings/tenant-billing.vue'
import TenantMessaging from 'components/tenant-settings/tenant-messaging.vue'
import axios from '@/axios/config'
import TenantsTable from 'components/tenant-settings/tenants-table.vue'
import TenantReservations from 'components/tenant-settings/tenant-reservations.vue'
import TenantAi from './tenant-ai.vue'
import BulkActions from 'components/tenant-settings/bulk-actions.vue'

export default {
  components: {
    tenantBilling,
    TenantBi,
    TenantMessaging,
    TenantsTable,
    TenantReservations,
    TenantAi,
    BulkActions,
  },
  name: 'TenantSettings',
  data() {
    return {
      loading: false,
      selectedSettings: 'billing',
      tenants: [],
      tenantSettings: null,
      selectedTenant: null,
      reportsItems: [],
      reports: [],
      approvedModules: [],
      bi: null,
      billing: null,
      messaging: null,
      tenantsStatus: false,
      ai: null,
    }
  },
  mounted() {
    this.fetchTenants()
  },
  methods: {
    ...mapActions('tenants', ['getTenants', 'getTenantSettings']),
    ...mapActions('billing', ['prevMonthInvoice']),

    async fetchTenants() {
      const { data } = await this.getTenants()
      this.tenants = data
    },
    async fetchTenantSettings(tenant) {
      if (!tenant) return
      this.loading = true
      const { data: settings } = await this.getTenantSettings(tenant)

      this.tenantSettings = settings

      const { data: invoiceData } = await this.prevMonthInvoice({
        tenant_id: settings.tenant_id,
      })

      this.billing = {
        ...settings.boom_payment_model,
        billing_info: settings.billing_info,
        approved_modules: settings.approved_modules,
        tenant_id: settings.tenant_id,
        company_name: settings.company_name,
        paymentMethodId: settings.payment_method_id,
        last4: settings.payment_method && settings.payment_method.last4,
        invoice: {
          lastInvoice: invoiceData.invoice,
          charge: invoiceData.charge,
          invoiceYear: invoiceData.year,
          invoiceMonth: invoiceData.month,
        },
      }
      this.messaging = {
        twilio_credentials: settings.twilio_credentials,
        twilio_phone: settings.twilio_phone,
        messaging_email: settings.messaging_email,
      }
      await this.fetchBiData()
      this.loading = false
    },
    async fetchBiData() {
      const {
        data: { reports },
      } = await axios.get('/api/bi/reports/management')
      const appReportsKeys = this.tenantSettings.bi_reports.map(r => r.key)

      this.bi = {
        selectedReports: reports
          .filter(r => appReportsKeys.indexOf(r.key) !== -1)
          .map(r => r.key),
        reportItems: reports,
        approvedModules: this.tenantSettings.approved_modules,
      }
    },

    async save() {
      const payload = {
        modules: this.bi.approvedModules,
        per_door: this.billing.per_door,
        calculation: this.billing.calculation,
        twilio_credentials: this.messaging.twilio_credentials,
        twilio_phone: this.messaging.twilio_phone,
        messaging_email: this.messaging.messaging_email,
      }

      if (this.bi.selectedReports && this.bi.selectedReports.length > 0) {
        payload.bi_reports = this.bi.selectedReports
      }

      axios.patch(`/api/settings/${this.selectedTenant}/reports`, payload)
    },
  },
  computed: {
    mappedReportsItems() {
      return this.reportsItems.map(r => ({ text: r.title, value: r.key }))
    },
  },
}
</script>

<style></style>
