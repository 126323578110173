<template>
  <v-card class="pa-4" style="border-radius: 10px">
    <div class="d-flex pa-4">
      <div
        class="flex text-center justify-center font-weight-medium text-subtitle-1"
      >
        {{ title }}
      </div>
    </div>
    <v-form ref="form">
      <v-text-field
        v-model.trim="formData.name"
        dense
        outlined
        :rules="[required]"
        :label="$t('Type')"
      />
      <v-combobox
        v-model="formData.category"
        :items="categories"
        dense
        outlined
        :rules="[required]"
        :label="$t('Category')"
      />
      <v-select
        v-if="!isEditMode"
        v-model="formData.expense_role"
        label="Expense role"
        outlined
        :rules="[required]"
        :items="expenseRoles"
        item-text="name"
        item-value="value"
        dense
      />
      <v-card-actions class="justify-end">
        <v-btn color="primary" outlined @click="onClose">Cancel</v-btn>
        <v-btn color="primary" @click="submit">Submit</v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import FormRulesMixin from 'components/mixins/form-rules-mixin'
import { mapActions } from 'vuex'

export default {
  name: 'ExpenseTypeModal',
  mixins: [FormRulesMixin],
  props: ['categories', 'expenseRoles', 'afterSave', 'expenseType'],
  data: function () {
    return {
      formData: this.expenseType || {},
    }
  },
  computed: {
    title() {
      return this.isEditMode
        ? this.$t('Edit Expense Type')
        : this.$t('New Expense Type')
    },
    isEditMode() {
      return !!this.expenseType
    },
  },
  methods: {
    ...mapActions('expenseTypes', [
      'createNewExpenseType',
      'updateExpenseType',
    ]),
    submit() {
      if (this.$refs.form.validate()) {
        const action = this.isEditMode
          ? 'updateExpenseType'
          : 'createNewExpenseType'
        this[action](this.formData).then(() => {
          this.afterSave()
          this.onClose()
        })
      }
    },
    onClose() {
      this.$store.commit('hideModal')
    },
  },
}
</script>

<style scoped></style>
