<template>
  <v-autocomplete
    v-model="selectedExpenseType"
    :outlined="outlined"
    dense
    :clearable="clearable"
    :items="expensesTypesFiltered"
    :label="lbl"
    :search-input.sync="search"
    :rules="rules"
    :disabled="disabled"
  />
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import CommonFunctions from 'components/mixins/common_functions'

export default {
  mixins: [CommonFunctions],
  props: [
    'value',
    'label',
    'clearable',
    'outlined',
    'rules',
    'isBusinessExpense',
    'disabled',
  ],
  data() {
    return {
      selectedExpenseType: this.value,
      search: null,
    }
  },
  computed: {
    ...mapState('expenseTypes', ['expensesTypes']),
    ...mapGetters('expenseTypes', [
      'expenseTypesBusiness',
      'expenseTypesClient',
      'expenseTypesNames',
    ]),
    lbl() {
      return this.label || this.$t('Expense type')
    },
    expensesTypesFiltered() {
      if (typeof this.isBusinessExpense === 'boolean') {
        return this.isBusinessExpense
          ? this.expenseTypesBusiness
          : this.expenseTypesClient
      }
      return this.expenseTypesNames
    },
  },
  watch: {
    value(newVal) {
      this.selectedExpenseType = newVal
    },
    selectedExpenseType(newVal) {
      this.$emit('input', newVal)
    },
    isBusinessExpense() {
      this.selectedExpenseType = null
    },
  },
  created() {
    if (!this.expensesTypes.length) {
      this.fetchAllExpenseTypes()
    }
  },
  methods: {
    ...mapActions('expenseTypes', ['fetchAllExpenseTypes']),
  },
}
</script>
