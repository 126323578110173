import axios from '@/axios/config'

export default {
  namespaced: true,
  actions: {
    async fetchGuests(_store, params) {
      const { data } = await axios.get(`/api/guests`, {
        params,
      })
      return data
    },
    async updateGuest(_store, { id, ...payload }) {
      const { data } = await axios.put(`/api/guests/${id}`, payload)
      return data
    },
    async fetchGuest(_store, id) {
      const { data } = await axios.get(`/api/guests/${id}`)
      return data
    },
  },
}
