var render = function render(){var _vm=this,_c=_vm._self._c;return _c('form-dialog',{attrs:{"title":"Reservation Edit","disable-submit":!_vm.reservation.listing_id},on:{"submit":_vm.submit,"open":_vm.getRates},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({class:_vm.showBtnBorder ? 'btn-border' : '',attrs:{"depressed":"","icon":""}},on),[_c('v-icon',{attrs:{"color":_vm.color || 'info',"small":""}},[_vm._v("mdi-pencil")])],1)]}}])},[_c('v-row',[(!_vm.isInvestor)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-autocomplete',{attrs:{"label":"Listing","items":_vm.listingsItems,"dense":"","hide-details":"","item-text":"nickname","item-value":"id","outlined":""},on:{"change":_vm.getRates},model:{value:(_vm.reservation.listing_id),callback:function ($$v) {_vm.$set(_vm.reservation, "listing_id", $$v)},expression:"reservation.listing_id"}}),(!_vm.reservation.is_editable)?_c('div',{staticClass:"text-caption warning--text py-4"},[_vm._v(" "+_vm._s(`* Moving this reservation to another listing won't release those dates in ${_vm.reservation.source}`)+" ")]):_vm._e()],1):_vm._e(),_c('v-col',{attrs:{"cols":"12"}},[_c('confirmation-modal',{ref:"confirmDateChangeModal",attrs:{"text":_vm.$t('The selected dates are blocked. Please confirm this action.'),"title":"Date change confirmation","actions":[
          {
            text: 'Cancel',
            color: 'error',
            onClick: _vm.setResDateRange,
          },
          {
            text: 'Confirm',
            color: 'success',
            onClick: () => {},
          },
        ]}}),_c('date-picker',{staticClass:"mt-3",attrs:{"range":true,"hide-details":true,"allowed-dates":_vm.allowedDates,"event-color":_vm.eventColors,"disabled":!_vm.reservation.is_editable,"label":"Dates"},model:{value:(_vm.range),callback:function ($$v) {_vm.range=$$v},expression:"range"}}),_c('v-checkbox',{staticClass:"mt-0",attrs:{"disabled":!_vm.reservation.is_editable,"dense":"","label":"Recalculate price(including taxes & fees)"},model:{value:(_vm.reCalc),callback:function ($$v) {_vm.reCalc=$$v},expression:"reCalc"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"outlined":"","dense":"","disabled":!_vm.reservation.is_editable,"items":['confirmed', 'inquiry', 'canceled'],"label":_vm.$t('Status')},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }