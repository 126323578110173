<template>
  <v-card class="overflow-auto">
    <v-card-title class="text-center">
      <span class="text-h5 flex-1"
        >{{ !editMode ? $t('Create New') : $t('Edit') }}
        {{ $t('Message Automation') }}
        {{ editMode ? ' #' + messageRule.id : '' }}</span
      >
      <div class="grey--text p-absolute right-0 ma-4">
        last edited: {{ parseDate(messageRule.updated_at) }}
      </div>
    </v-card-title>
    <v-form ref="form" @submit.prevent="save">
      <v-card-text>
        <v-text-field
          v-model="messageRule.name"
          dense
          outlined
          :rules="[required]"
          :label="$t('Name')"
        />
        <div>
          <v-row>
            <v-col cols="12" class="text-subtitle-1">
              {{ $t('Status') }}
            </v-col>
            <v-col cols="3">
              <v-select
                v-model="messageRule.settings.reservation_status"
                :items="RESERVATION_STATUSES"
                :placeholder="$t('Reservation Status')"
                outlined
                hide-details
                dense
              />
            </v-col>
            <v-col cols="3">
              <v-select
                v-model="messageRule.settings.sales_status"
                clearable
                :items="CONVERSATION_SALES_STATUSES"
                :placeholder="$t('Sales Status')"
                outlined
                hide-details
                dense
              />
            </v-col>
            <v-col cols="12" class="text-subtitle-1">{{
              $t('Time settings')
            }}</v-col>
            <v-col
              v-if="
                messageRule.sent_at_timing !== 'At' && !isInquiry && !isReserved
              "
              cols="3"
            >
              <v-select
                v-model="messageRule.sent_at_number"
                outlined
                dense
                hide-details
                type="number"
                :items="timeArray"
              />
            </v-col>
            <v-col
              v-if="
                messageRule.sent_at_timing !== 'At' && !isInquiry && !isReserved
              "
              cols="3"
            >
              <v-select
                v-model="messageRule.sent_at_period"
                :items="['Minutes', 'Hours', 'Days', 'Weeks']"
                :placeholder="$t('Period')"
                outlined
                hide-details
                dense
              />
            </v-col>
            <v-col cols="3">
              <v-select
                v-model="messageRule.sent_at_timing"
                :items="['After', 'Before', 'At']"
                :placeholder="$t('Timing')"
                :disabled="isInquiry || isReserved"
                outlined
                hide-details
                dense
              />
            </v-col>
            <v-col cols="3">
              <v-select
                v-model="messageRule.sent_at_event"
                :items="['Check-in', 'Check-out', 'Confirmation', 'Creation']"
                :placeholder="$t('Event')"
                :disabled="isInquiry || isReserved"
                outlined
                dense
                hide-details
              />
            </v-col>
            <v-row cols="12" class="col-12">
              <v-col cols="6">
                <v-checkbox
                  v-model="messageRule.settings.timing.same_day_booking"
                  class="pa-1 pb-0 rounded small-text"
                  hide-details
                  :label="$t('Send on confirmation for all same day bookings')"
                />
              </v-col>
              <!--              <v-col cols="3">-->
              <!--                <v-checkbox-->
              <!--                  v-model="messageRule.settings.timing.one_day_res_confirmation"-->
              <!--                  class="pa-1 pb-0 rounded small-text"-->
              <!--                  hide-details-->
              <!--                  :label="$t('Send on confirmation for all 1 day bookings')"-->
              <!--                />-->
              <!--              </v-col>-->
            </v-row>
            <v-col cols="12" class="text-subtitle-1">{{
              $t('Listings Filters')
            }}</v-col>
            <v-col cols="12">
              <multi-property-picker
                v-model="messageRule.whitelist"
                :label="$t('All Listings')"
                multiple
                :hide-btns="true"
              />
              <multi-property-picker
                v-model="messageRule.blacklist"
                :disabled="messageRule.whitelist.length !== 0"
                :label="$t('Blacklisted listings')"
                :hide-btns="true"
              />
            </v-col>
            <v-col cols="12" class="text-subtitle-1"
              >{{ $t('Channels Filters') }}
            </v-col>
            <v-col cols="12">
              <v-autocomplete
                v-model="messageRule.allowed_channels"
                :disabled="messageRule.ignore_channels.length !== 0"
                :items="MESSAGE_CHANNELS"
                label="All Channels"
                small-chips
                outlined
                dense
                multiple
                hide-details
              />
            </v-col>
            <v-divider />
            <v-col cols="12">
              <v-autocomplete
                v-model="messageRule.ignore_channels"
                :disabled="messageRule.allowed_channels.length !== 0"
                :items="MESSAGE_CHANNELS"
                label="Channels to ignore"
                small-chips
                outlined
                dense
                multiple
                hide-details
              />
            </v-col>
            <v-col cols="12">
              <div class="text-body-1">Send only if:</div>
              <v-checkbox
                v-model="messageRule.settings.deposit"
                class="pa-1 pb-0 rounded small-text"
                :disabled="messageRule.settings.deposit_missing"
                hide-details
                :label="
                  $t('Security deposit has been paid (or not applicable)')
                "
              />
              <v-checkbox
                v-model="messageRule.settings.deposit_missing"
                :disabled="messageRule.settings.deposit"
                class="pa-1 pb-0 rounded small-text"
                hide-details
                :label="
                  $t('Security deposit is required and has not been paid')
                "
              />
              <v-checkbox
                v-model="messageRule.settings.is_moc"
                :disabled="messageRule.settings.deposit"
                class="pa-1 pb-0 rounded small-text"
                hide-details
                :label="
                  $t(
                    'Is Merchant of Record (Should be paid by the guest directly)'
                  )
                "
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="text-subtitle-1">{{
              $t('Additional Rules')
            }}</v-col>
            <v-col cols="12">
              <v-checkbox
                v-model="showPreCheckinRules"
                class="pa-1 pb-0 rounded small-text mt-0"
                hide-details
                :label="$t('Set pre-checkin rules')"
              />
            </v-col>
            <v-col v-if="showPreCheckinRules" cols="12">
              <v-radio-group
                v-model="messageRule.settings.precheckin"
                class="pb-2 ma-0"
                hide-details
                row
              >
                <v-radio label="Only Filled" value="filled" />
                <v-radio label="Only Un-Filled" value="unfilled" />
              </v-radio-group>
            </v-col>
            <v-col cols="12">
              <v-checkbox
                v-model="showPaidRules"
                class="pa-1 pb-0 rounded small-text"
                hide-details
                :label="$t('Set balance paid rules')"
              />
            </v-col>
            <v-col v-if="showPaidRules" cols="12">
              <v-radio-group
                v-model="messageRule.settings.balance"
                class="pb-2 ma-0"
                hide-details
                row
              >
                <v-radio label="Balance due is not zero" value="unpaid" />
                <v-radio label="Balance due is zero" value="paid" />
              </v-radio-group>
            </v-col>
            <v-col cols="12">
              <v-checkbox
                v-model="showScoreRules"
                class="pa-1 pb-0 rounded small-text"
                hide-details
                :label="$t('Set survey score rules')"
              />
            </v-col>
            <v-col v-if="showScoreRules" cols="12">
              <v-radio-group
                v-model="messageRule.settings.score"
                class="pb-2 ma-0"
                hide-details
                row
              >
                <v-radio label="Not filled" value="unreviewed" />
                <v-radio label="Bad review (1-2 points)" value="bad" />
                <v-radio label="Mid review (3 points)" value="mid" />
                <v-radio label="Good review (4-5 points)" value="good" />
              </v-radio-group>
            </v-col>
            <v-col cols="12">
              <v-checkbox
                v-model="showAvailabilityRules"
                class="pa-1 pb-0 rounded small-text"
                hide-details
                :label="$t('Set availability rules')"
              />
            </v-col>
            <v-col v-if="showAvailabilityRules" cols="12">
              <v-radio-group
                v-model="messageRule.settings.availability"
                class="pb-2 ma-0"
                hide-details
                row
              >
                <v-radio
                  label="Early Check-in is available"
                  value="early_check_in"
                />
                <v-radio
                  label="Late Check-out is available"
                  value="late_check_out"
                />
                <v-radio label="Day before is available" value="day_before" />
                <v-radio label="Day after is available" value="day_after" />
              </v-radio-group>
            </v-col>
          </v-row>
        </div>
        <div v-if="is_ai || messageRule.is_ai">
          <v-row>
            <v-col cols="12" class="text-subtitle-1">{{
              $t('AI settings')
            }}</v-col>
            <v-col cols="4">
              <v-select
                v-model="messageRule.ai_agent_type"
                :items="['CS', 'Sales']"
                :placeholder="$t('Agent Type')"
                outlined
                hide-details
                dense
              />
            </v-col>
            <v-col cols="4">
              <v-select
                v-model="messageRule.ai_action_type"
                :items="aiActionTypes"
                item-text="text"
                item-value="value"
                :placeholder="$t('Action Type')"
                outlined
                hide-details
                dense
              />
            </v-col>
            <v-col cols="6">
              <v-textarea
                v-model="messageRule.ai_command"
                label="AI command"
                outlined
                dense
                rows="8"
              ></v-textarea>
            </v-col>
          </v-row>
        </div>
        <div v-if="!is_ai && !messageRule.is_ai">
          <v-row class="align-center">
            <v-col cols="12" class="text-subtitle-1 mb-3 mt-3">{{
              $t('Message')
            }}</v-col>
            <v-col cols="4">
              <v-autocomplete
                v-model="send_in_channels"
                :items="channels"
                outlined
                label="Select messaging channel"
                :messages="sendInChannelMessages"
                dense
                multiple
                chips
                :rules="[required]"
                small-chips
                :item-disabled="isChannelSelectDisabled"
                @change="onChannelChange"
              />
            </v-col>
            <v-col v-if="includesWhatsapp" cols="4">
              <v-select
                v-model="messageRule.whatsapp_template_id"
                label="Template"
                :items="templateItems"
                outlined
                :rules="[required]"
                messages="Required for whatsapp"
                dense
                return-object
                @change="updateBody"
              ></v-select>
            </v-col>
            <v-col v-if="includesEmail || includesChannel" cols="4">
              <div class="">
                <v-text-field
                  v-model="messageRule.email_subject"
                  outlined
                  dense
                  :rules="[required]"
                  messages="Required for Email & Channel"
                  class="flex"
                  placeholder="Subject"
                />
              </div>
            </v-col>
            <v-col cols="12" class="correct-area">
              <div class="d-flex">
                <v-textarea
                  v-model="messageRule.message_body"
                  label="Body"
                  :readonly="includesWhatsapp"
                  outlined
                  dense
                  :error-messages="
                    hasInvalidVariables
                      ? `Invalid variables found: ${invalidVariables.join(
                          ', '
                        )}`
                      : []
                  "
                  class="flex"
                  placeholder="Type your message..."
                  @keyup="onMsgDown"
                />
              </div>
              <div
                v-if="isSMS"
                class="d-flex justify-space-between text-caption"
              >
                <div class="warning--text">
                  <span v-show="showError" class="ml-1">
                    SMS message can't contains more than
                    {{ smsCharLimit }} chars
                  </span>
                </div>
                <div>{{ charCounter }} / {{ smsCharLimit }}</div>
              </div>
              <div
                v-if="isSMS && containsEmoji"
                class="d-flex justify-space-between text-caption"
              >
                <div class="warning--text">
                  <span class="ml-1"> SMS message can't contains Emoji </span>
                </div>
              </div>
            </v-col>
            <v-row v-if="!includesWhatsapp" class="align-baseline">
              <v-col cols="4" class="ml-auto ma-3">
                <v-select
                  v-model="selectedParameter"
                  dense
                  clearable
                  persistent-hint
                  messages="Parameters longer than 255 characters will be trimmed"
                  :hint="$t('Template parameters')"
                  :label="$t('Body parameters')"
                  outlined
                  :items="parameterItems"
                  @change="updateMessageBody"
                />
              </v-col>
            </v-row>
          </v-row>
        </div>
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn color="blue darken-1" text @click.native="close">Close</v-btn>
        <v-btn
          color="blue darken-1"
          :disabled="loading || showError"
          text
          type="submit"
          >{{ $t('Save') }}
        </v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import axios from 'axios'
import { omit } from 'lodash'
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import FormRulesMixin from 'components/mixins/form-rules-mixin'
import MultiPropertyPicker from 'components/multi-property-picker.vue'
import {
  MESSAGE_CHANNELS,
  CONVERSATION_SALES_STATUSES,
  RESERVATION_STATUSES,
} from '@/consts'

export default {
  components: {
    MultiPropertyPicker,
  },
  mixins: [CommonFunctions, PermissionsMixin, FormRulesMixin],
  props: ['afterSaveFunc', 'editMode', 'currentRule', 'templates', 'is_ai'],
  computed: {
    isInquiry() {
      return this.messageRule.settings.reservation_status === 'inquiry'
    },
    isReserved() {
      return (
        this.messageRule.settings.reservation_status === 'reserved' ||
        this.messageRule.settings.reservation_status === 'agent-reserved'
      )
    },
    isSMS() {
      return this.includesSMS && this.send_in_channels.length === 1
    },
    containsEmoji() {
      const emojiRegex = /[\uD800-\uDFFF]./
      return emojiRegex.test(this.messageRule.message_body)
    },
    aiActionTypes() {
      const allTypes = {
        CS: [
          { value: 'followup', text: 'Follow up' },
          { value: 'verify', text: 'Verify info with guest' },
        ],
        Sales: [
          { value: 'resell', text: 'Resell' },
          { value: 'promotion', text: 'Promotion' },
          { value: 'followup', text: 'Follow up' },
        ],
      }

      if (this.messageRule.ai_agent_type == undefined) {
        return []
      } else {
        return allTypes[this.messageRule.ai_agent_type]
      }
    },
    templateItems() {
      return this.templates.map(t => ({
        ...t,
        text: `${t.name} ${t.lang && t.lang !== 'en' ? `(${t.lang})` : ''}`,
        value: t.id,
      }))
    },
    timeArray() {
      if (this.messageRule.sent_at_period == 'Minutes') {
        return [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55]
      } else {
        return [
          1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
          21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37,
          38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54,
          55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71,
          72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88,
          89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100,
        ]
      }
    },
    includesWhatsapp() {
      return this.send_in_channels.includes('send_whatsapp')
    },
    includesEmail() {
      return this.send_in_channels.includes('send_email')
    },
    includesChannel() {
      return this.send_in_channels.includes('send_channel')
    },
    includesSMS() {
      return this.send_in_channels.includes('send_sms')
    },
    channels() {
      return [
        {
          text: 'Email',
          value: 'send_email',
        },
        {
          text: 'SMS',
          value: 'send_sms',
        },
        {
          text: 'Whatsapp',
          value: 'send_whatsapp',
        },
        {
          text: 'Channel',
          value: 'send_channel',
        },
      ]
    },
    sendInChannelMessages() {
      const msgs = []
      if (this.includesChannel)
        msgs.push('Channel fallbacks to email if unavailable')
      if (this.includesSMS || this.includesWhatsapp)
        msgs.push('Whatsapp & sms fallbacks to channel')
      return msgs.join(', ')
    },
    bracketValidation() {
      if (!this.messageRule.message_body) {
        return {
          openCount: 0,
          closeCount: 0,
          isValid: true,
        }
      }

      const messageText = this.messageRule.message_body
      const openBrackets = (messageText.match(/{/g) || []).length
      const closeBrackets = (messageText.match(/}/g) || []).length

      return {
        openCount: openBrackets,
        closeCount: closeBrackets,
        isValid: openBrackets === closeBrackets,
      }
    },
    hasUnmatchedBrackets() {
      return !this.bracketValidation.isValid
    },
    invalidVariables() {
      if (!this.messageRule.message_body) return []

      const messageText = this.messageRule.message_body

      if (!this.bracketValidation.isValid) {
        return ['Unmatched variable brackets']
      }

      const variableRegex = /{{([^}]+)}}/g
      const matches = [...messageText.matchAll(variableRegex)]
      const usedVariables = matches.map(match => match[0])

      const validVariables = this.parameterItems.map(item => item.value)
      return usedVariables.filter(
        variable => !validVariables.includes(variable)
      )
    },
    hasInvalidVariables() {
      return this.invalidVariables.length > 0
    },
  },
  data() {
    return {
      MESSAGE_CHANNELS,
      CONVERSATION_SALES_STATUSES,
      RESERVATION_STATUSES,
      send_in_channels: [],
      parameterItems: [
        { text: 'Guest name', value: '{{guest_name}}' },
        { text: 'Company name', value: '{{company_name}}' },
        { text: 'Guest Portal Link', value: '{{portal_link}}' },
        { text: 'Internal Review Link', value: '{{review_link}}' },
        { text: 'Confirmation code', value: '{{confirmation_code}}' },
        { text: 'Check in', value: '{{check_in}}' },
        { text: 'Check in time', value: '{{check_in_time}}' },
        { text: 'Check in date', value: '{{check_in_date}}' },
        { text: 'Check out', value: '{{check_out}}' },
        { text: 'Check out time', value: '{{check_out_time}}' },
        { text: 'Check out date', value: '{{check_out_date}}' },
        { text: 'Listing nickname', value: '{{listing_nickname}}' },
        { text: 'Listing Postcode', value: '{{postcode}}' },
        { text: 'Door codes', value: '{{door_code}}' },
        { text: 'Wifi name', value: '{{wifi_name}}' },
        { text: 'Wifi password', value: '{{wifi_pass}}' },
        { text: 'Confirmation Code', value: '{{confirmation}}' },
        { text: 'Check-in Instructions', value: '{{checkin_instruct}}' },
        {
          text: 'Early Checkin Price (set availability rules)',
          value: '{{early_in_price}}',
        },
        {
          text: 'Late Checkout Price (set availability rules)',
          value: '{{late_out_price}}',
        },
        {
          text: 'Checkin a day early Price (set availability rules)',
          value: '{{add_in_price}}',
        },
        {
          text: 'Checkout a day later Price (set availability rules)',
          value: '{{add_out_price}}',
        },
        {
          text: 'Early Checkin Link (set availability rules)',
          value: '{{early_in_link}}',
        },
        {
          text: 'Late Checkout Link (set availability rules)',
          value: '{{late_out_link}}',
        },
        {
          text: 'Checkin a day early Link (set availability rules)',
          value: '{{add_in_link}}',
        },
        {
          text: 'Checkout a day later Link (set availability rules)',
          value: '{{add_out_link}}',
        },
      ],
      messageRule: this.currentRule
        ? {
            ...this.currentRule,
            settings: {
              timing: { ...this.currentRule.settings.timing },
              ...this.currentRule.settings,
            },
          }
        : {
            listings_ids: [],
            whitelist: [],
            blacklist: [],
            sent_at_period: 'Minutes',
            sent_at_timing: 'At',
            sent_at_event: 'Confirmation',
            sent_at_number: 1,
            allowed_channels: [],
            ignore_channels: [],
            settings: {
              reservation_status: 'confirmed',
              timing: {
                same_day_booking: false,
                one_day_res_confirmation: false,
              },
            },
          },
      menu: false,
      smsCharLimit: 150,
      showError: false,
      charCounter: 0,
      whatsappTemplate: null,
      showPaidRules: false,
      showScoreRules: false,
      showPreCheckinRules: false,
      showAvailabilityRules: false,
      selectedParameter: null,
    }
  },
  mounted() {
    this.charCounter = this.messageRule.message_body?.length
    this.showPreCheckinRules =
      this.messageRule.settings.precheckin !== undefined
    this.showPaidRules = this.messageRule.settings.balance !== undefined
    this.showScoreRules = this.messageRule.settings.score !== undefined
    this.showAvailabilityRules =
      this.messageRule.settings.availability !== undefined
    if (this.isSMS && this.charCounter > 150) {
      this.showError = true
    } else {
      this.showError = false
    }

    if (this.messageRule.settings.timing == undefined) {
      this.messageRule.settings.timing = {
        same_day_booking: false,
        one_day_res_confirmation: false,
      }
    }
    this.whatsappTemplate = this.templates?.find(
      t => t.body === this.messageRule.message_body
    )?.sid
    this.send_in_channels = this.channels
      .filter(c => this.messageRule[c.value])
      .map(c => c.value)
  },
  watch: {
    'messageRule.settings.reservation_status'(val) {
      if (['inquiry', 'agent-reserved'].includes(val)) {
        this.messageRule.sent_at_timing = 'At'
        this.messageRule.sent_at_event = 'Creation'
      }
    },
    showPreCheckinRules(val) {
      if (!val) {
        this.messageRule.settings = omit(
          this.messageRule.settings,
          'precheckin'
        )
      }
      if (val) {
        this.messageRule.settings = omit(this.messageRule.settings, 'balance')
        this.messageRule.settings = omit(this.messageRule.settings, 'score')
        this.messageRule.settings = omit(
          this.messageRule.settings,
          'availability'
        )
        this.showScoreRules = false
        this.showPaidRules = false
        this.showAvailabilityRules = false
      }
    },
    showPaidRules(val) {
      if (!val) {
        this.messageRule.settings = omit(this.messageRule.settings, 'balance')
      }
      if (val) {
        this.messageRule.settings = omit(
          this.messageRule.settings,
          'precheckin'
        )
        this.messageRule.settings = omit(this.messageRule.settings, 'score')
        this.messageRule.settings = omit(
          this.messageRule.settings,
          'availability'
        )
        this.showScoreRules = false
        this.showPreCheckinRules = false
        this.showAvailabilityRules = false
      }
    },
    showScoreRules(val) {
      if (!val) {
        this.messageRule.settings = omit(this.messageRule.settings, 'score')
      }
      if (val) {
        this.messageRule.settings = omit(
          this.messageRule.settings,
          'precheckin'
        )
        this.messageRule.settings = omit(this.messageRule.settings, 'balance')
        this.messageRule.settings = omit(
          this.messageRule.settings,
          'availability'
        )
        this.showPreCheckinRules = false
        this.showPaidRules = false
        this.showAvailabilityRules = false
      }
    },
    showAvailabilityRules(val) {
      if (!val) {
        this.messageRule.settings = omit(
          this.messageRule.settings,
          'availability'
        )
      }
      if (val) {
        this.messageRule.settings = omit(
          this.messageRule.settings,
          'precheckin'
        )
        this.messageRule.settings = omit(this.messageRule.settings, 'balance')
        this.messageRule.settings = omit(this.messageRule.settings, 'score')
        this.showPreCheckinRules = false
        this.showPaidRules = false
        this.showScoreRules = false
      }
    },
  },
  methods: {
    isChannelSelectDisabled(item) {
      if (item.value === 'send_channel')
        return this.includesEmail && !this.includesChannel
      if (item.value === 'send_email')
        return this.includesChannel && !this.includesEmail
      if (item.value === 'send_sms')
        return this.includesWhatsapp && !this.includesSMS
      if (item.value === 'send_whatsapp')
        return this.includesSMS && !this.includesWhatsapp
      return false
    },
    close() {
      this.$store.commit('hideModal')
    },
    onChannelChange() {
      if (!this.isSMS) this.showError = false
    },
    onMsgDown(event) {
      if (this.isSMS) {
        if (event.target.value.length > this.smsCharLimit) {
          this.showError = true
        } else if (this.containsEmoji) {
          this.showError = true
        } else {
          this.showError = false
          if (
            event.ctrlKey &&
            (event.code === 'Enter' || event.keyCode === 13)
          ) {
            this.sendMessage()
            return
          }
        }
        this.charCounter = this.messageRule.message_body.length
      } else {
        this.showError = false
      }
    },
    updateBody({ body, id }) {
      this.messageRule.message_body = body
      this.messageRule.whatsapp_template_id = id
    },
    updateMessageBody(item) {
      const textarea = this.$el.querySelector('textarea')
      if (!textarea || !item) return

      const start = textarea.selectionStart
      const end = textarea.selectionEnd
      const text = this.messageRule.message_body

      this.messageRule.message_body =
        text.substring(0, start) + item + text.substring(end)

      this.$nextTick(() => {
        textarea.focus()
        const newPosition = start + item.length
        textarea.setSelectionRange(newPosition, newPosition)
      })
    },
    save() {
      if (this.hasUnmatchedBrackets) {
        return this.$store.dispatch('showError', {
          message: 'Message contains unmatched variable brackets',
        })
      }

      if (this.hasInvalidVariables) {
        const variables = this.invalidVariables.join(', ')
        const message = `Invalid variables found: ${variables}`
        return this.$store.dispatch('showError', { message })
      }

      if (this.$refs.form.validate() && !this.showError) {
        this.channels.forEach(c => {
          this.messageRule[c.value] = this.send_in_channels.includes(c.value)
        })
        if (this.is_ai) {
          this.messageRule.is_ai = true
        }
        if (this.messageRule.sent_at_timing === 'At') {
          this.messageRule.sent_at_period = null
          this.messageRule.sent_at_number = null
        }
        this.$store.commit('updateListingLoading', true)
        if (this.editMode) {
          axios
            .patch(
              `/api/auto-message-rules/${this.messageRule.id}`,
              this.messageRule
            )
            .then(() => {
              this.$store.commit('updateListingLoading', false)
              if (this.afterSaveFunc) {
                this.afterSaveFunc()
              }
              this.close()
            })
            .catch(error => {
              console.error(error)
              this.$store.commit('updateListingLoading', false)
            })
        } else {
          axios
            .post('/api/auto-message-rules', this.messageRule)
            .then(() => {
              this.$store.commit('updateListingLoading', false)

              this.close()
              if (this.afterSaveFunc) {
                this.afterSaveFunc()
              }
            })
            .catch(error => {
              console.error(error)
              this.$store.commit('updateListingLoading', false)
            })
        }
      }
    },
  },
}
</script>
