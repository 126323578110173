import axios from 'axios'
import customAxios from '@/axios/config'

export default {
  namespaced: true,
  state: {
    taxProfiles: [],
  },
  mutations: {
    updateTaxProfiles(state, payload) {
      state.taxProfiles = payload
    },
  },
  actions: {
    async getTaxProfiles({ commit }) {
      const { data } = await axios.get('api/tax-profiles/stats')
      commit('updateTaxProfiles', data)
      return data
    },
    async getTaxProfile(store, { taxProfileId } = {}) {
      return axios
        .get(`/api/tax-profiles/${taxProfileId}/`)
        .catch(console.error)
    },
    async updateTaxProfile({ dispatch }, { id, ...payload }) {
      await customAxios.put(`/api/tax-profiles/${id}`, payload)
      dispatch('getTaxProfiles')
    },
    async createTaxProfile({ dispatch }, payload) {
      await customAxios.post(`/api/tax-profiles`, payload)
      dispatch('getTaxProfiles')
    },
  },
}
