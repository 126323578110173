<template>
  <v-container>
    <v-card>
      <v-card-title>
        Ledger Account Mappings
        <v-spacer></v-spacer>
      </v-card-title>

      <v-card-text>
        <v-expansion-panels>
          <v-expansion-panel
            v-for="(accountGroup, type) in groupedAccounts"
            :key="type"
          >
            <v-expansion-panel-header>
              {{ type }} accounts
              <template v-slot:actions>
                <v-chip small> {{ accountGroup.length }} accounts </v-chip>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th>Account</th>
                      <th>Code</th>
                      <th>Mapped Fields</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="account in accountGroup" :key="account.id">
                      <td>{{ account.name }}</td>
                      <td>{{ account.code }}</td>
                      <td>
                        <v-chip
                          v-for="(fields, source) in account.mappings"
                          :key="source"
                          class="mr-2 mb-2"
                          small
                        >
                          {{ fields.length }} {{ source }} fields
                        </v-chip>
                      </td>
                      <td>
                        <v-btn
                          small
                          text
                          color="primary"
                          @click="editMappings(account)"
                        >
                          Edit Mappings
                        </v-btn>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card-text>
    </v-card>

    <!-- Mapping Edit Dialog -->
    <v-dialog v-model="showMappingDialog" max-width="800px">
      <v-card v-if="selectedAccount">
        <v-card-title>
          Edit Mappings for {{ selectedAccount.name }}
        </v-card-title>
        <v-card-text>
          <v-tabs v-model="activeSourceTab">
            <v-tab v-for="(fields, source) in supportedSources" :key="source">
              {{ source }}
            </v-tab>

            <v-tabs-items v-model="activeSourceTab">
              <v-tab-item
                v-for="(fields, source) in supportedSources"
                :key="source"
              >
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-select
                        v-model="selectedAccount.mappings[source]"
                        :items="Object.keys(fields)"
                        :label="`${source} Fields`"
                        multiple
                        chips
                        outlined
                        :hint="getFieldsHint(fields)"
                        persistent-hint
                      >
                        <template v-slot:selection="{ item }">
                          <v-chip small>
                            {{ fields[item].label }}
                          </v-chip>
                        </template>
                        <template v-slot:item="{ item }">
                          {{ fields[item].label }}
                          <v-spacer></v-spacer>
                          <v-chip
                            x-small
                            :color="
                              fields[item].default_type === 'credit'
                                ? 'green'
                                : 'blue'
                            "
                            text-color="white"
                          >
                            {{ fields[item].default_type }}
                          </v-chip>
                        </template>
                      </v-select>
                    </v-col>
                  </v-row>
                </v-container>
              </v-tab-item>
            </v-tabs-items>
          </v-tabs>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="showMappingDialog = false">Cancel</v-btn>
          <v-btn color="primary" @click="saveMappings">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import axios from 'axios'

export default {
  name: 'LedgerSettings',

  data: () => ({
    showMappingDialog: false,
    selectedAccount: null,
    activeSourceTab: 0,
    supportedSources: {},
    accounts: [],
    loading: false,
  }),

  computed: {
    groupedAccounts() {
      return this.accounts.reduce((groups, account) => {
        const type = account.account_type
        if (!groups[type]) groups[type] = []
        groups[type].push(account)
        return groups
      }, {})
    },
  },

  methods: {
    async fetchAccounts() {
      this.loading = true
      try {
        const response = await axios.get('/api/ledger-accounts')
        this.accounts = response.data.accounts
        this.supportedSources = response.data.supported_sources
      } catch (error) {
        this.$store.dispatch('showError', 'Failed to load accounts')
      } finally {
        this.loading = false
      }
    },

    editMappings(account) {
      this.selectedAccount = {
        ...account,
        mappings: account.mappings || {},
      }
      this.showMappingDialog = true
    },

    getFieldsHint(fields) {
      return `Select fields that should post to this account`
    },

    async saveMappings() {
      try {
        await axios.patch(`/api/ledger-accounts/${this.selectedAccount.id}`, {
          ledger_account: {
            mappings: this.selectedAccount.mappings,
          },
        })
        this.showMappingDialog = false
        this.$store.dispatch('showSuccess', 'Mappings updated successfully')
        this.fetchAccounts() // Refresh the accounts list
      } catch (error) {
        this.$store.dispatch('showError', 'Failed to update mappings')
      }
    },
  },

  created() {
    this.fetchAccounts()
  },
}
</script>
