<template>
  <v-sheet width="100%">
    <v-card class="pa-4">
      <div>
        <div class="d-flex justify-space-between">
          <h3 class="mb-4">Twilio Credentials</h3>
          <v-btn
            small
            depressed
            dark
            color="orange darken-2"
            @click="onSyncTemplates"
          >
            <v-icon small class="me-1"> mdi-sync </v-icon>
            Sync Templates
          </v-btn>
        </div>

        <phone-input
          v-model="value.twilio_phone"
          required
          placeholder="Twilio Phone"
        />
        <v-text-field
          v-model="credentials.twilio_account"
          label="Twilio Account"
          required
          dense
          outlined
        />
        <v-text-field
          v-model="credentials.twilio_token"
          label="Twilio Auth Token"
          required
          dense
          outlined
        />
        <!-- remove this -->
        <v-text-field
          v-model="credentials.twilio_messaging_service"
          label="Messaging Service"
          required
          dense
          outlined
        />
      </div>
      <div class="d-flex justify-space-between">
        <h3 class="mb-4">Email Messaging</h3>
      </div>
      <v-text-field
        v-model="value.messaging_email"
        label="Messaging email address"
        placeholder="[company name]@guest.boomnowconnect.com"
        required
        dense
        outlined
      />
    </v-card>
  </v-sheet>
</template>

<script>
import PhoneInput from 'components/common/phone-input.vue'
import { mapActions } from 'vuex'

export default {
  components: { PhoneInput },
  name: 'TenantMessaging',
  props: ['value', 'tenantId'],
  computed: {
    credentials() {
      return this.value.twilio_credentials
    },
  },
  methods: {
    ...mapActions('conversations', ['syncTemplates']),
    onSyncTemplates() {
      this.syncTemplates({ tenantId: this.tenantId })
    },
  },
}
</script>

<style></style>
