<template>
  <v-layout px-5 py-3 align-center class="comp-wrapper">
    <v-flex column>
      <v-row justify-center>
        <v-col>
          <v-btn outlined color="primary" @click="switchTab(1)">
            {{ $t('Version builder') }}
          </v-btn>
          <v-btn class="mx-2" outlined color="primary" @click="switchTab(2)">
            {{ $t('Versions') }}
          </v-btn>
        </v-col>

        <v-col cols="3">
          <v-select
            v-if="aiExisitingVersions.length > 0"
            v-model="selectedAiVersionControlForm"
            :items="aiExisitingVersions"
            item-text="version_number"
            item-value="object"
            :disabled="!createAiVersionControlForm.ai_agent_type"
            label="Select AI version"
            outlined
            dense
          ></v-select>
        </v-col>
      </v-row>

      <v-flex px-5 xs9>
        <div v-if="activeTab === 1">
          <v-row class="d-flex align-baseline">
            <v-col cols="4">
              <v-select
                v-model="createAiVersionControlForm.ai_agent_type"
                :items="aiAgents"
                label="Select AI Agent"
              ></v-select>
            </v-col>
            <v-col cols="4">
              <v-text-field
                v-model="createAiVersionControlForm.version_number"
                label="Version"
              ></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                v-model="createAiVersionControlForm.version_name"
                label="Version Name"
              ></v-text-field>
            </v-col>
          </v-row>

          <p class="my-2">
            {{ $t('Base Instructions:') }}
          </p>
          <div class="custom-json-editor jse-theme-dark">
            <json-editor-vue
              v-model="createAiVersionControlForm.base_instructions"
              mode="text"
            ></json-editor-vue>
          </div>

          <p class="my-2">
            {{ $t('Tools:') }}
          </p>
          <div class="custom-json-editor jse-theme-dark">
            <json-editor-vue
              v-model="createAiVersionControlForm.tools"
              mode="text"
            ></json-editor-vue>
          </div>

          <p class="my-2">
            {{ $t('Generation Structure:') }}
          </p>
          <div class="custom-json-editor jse-theme-dark">
            <json-editor-vue
              v-model="createAiVersionControlForm.generation_structure"
              mode="text"
            ></json-editor-vue>
          </div>

          <v-row justify-center>
            <v-col class="text-right">
              <v-btn @click="saveData"> Save </v-btn>
            </v-col>
          </v-row>
        </div>

        <div v-else>
          <v-data-table :headers="headers" :items="tableData">
            <template #item.prompt="{ item }">
              <v-btn icon @click="toggleModal(item)">
                <v-icon small> info </v-icon>
              </v-btn>
            </template>

            <template #item.actions="{ item }">
              <v-btn icon @click="openAiBuilderModal(item)">
                <v-icon small>publish</v-icon>
              </v-btn>

              <ConfirmationModal
                :text="`Are you sure you want to remove version: ${item.version_number} ?`"
                @action="remove(item)"
              >
                <template #activator="slotData">
                  <v-btn icon v-on="slotData.on">
                    <v-icon small>$trash</v-icon>
                  </v-btn>
                </template>
              </ConfirmationModal>
            </template>
          </v-data-table>

          <v-dialog v-model="dialog">
            <v-card>
              <v-card-title>{{ $t('Settings') }}</v-card-title>
              <v-card-text>
                <div class="json-block">
                  <p>{{ $t('Base Instructions') }}:</p>
                  <pre class="json-code">{{
                    selectedVersion?.base_instructions
                  }}</pre>
                </div>
                <div class="json-block">
                  <p>{{ $t('Tools') }}:</p>
                  <pre class="json-code">{{ selectedVersion?.tools }}</pre>
                </div>
              </v-card-text>
              <v-card-actions>
                <v-btn @click="toggleModal">Close</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </div>
      </v-flex>
    </v-flex>
  </v-layout>
</template>

<script>
import JsonEditorVue from 'json-editor-vue'
import 'vanilla-jsoneditor/themes/jse-theme-dark.css'
import ConfirmationModal from 'components/modals/confirmation-modal'

export default {
  components: {
    JsonEditorVue,
    ConfirmationModal,
  },
  data() {
    return {
      activeTab: 1,
      createAiVersionControlForm: {
        ai_agent_type: null,
        version_number: null,
        base_instructions: undefined,
        tools: undefined,
        generation_structure: undefined,
        version_name: '',
      },
      selectedAiVersionControlForm: {
        ai_agent_type: null,
        version_number: null,
        base_instructions: undefined,
        tools: undefined,
        version_name: '',
      },
      aiAgents: ['CS', 'Sales', 'Operations'],

      headers: [
        { text: 'Version', value: 'version_number' },
        { text: 'Agent Type', value: 'ai_agent_type' },
        { text: 'Version Name', value: 'version_name' },
        { text: 'Prompt', value: 'prompt' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      dialog: false,
      selectedVersion: null,
    }
  },
  watch: {
    selectedAiVersionControlForm(newVal) {
      if (newVal) {
        this.createAiVersionControlForm = {
          ...this.createAiVersionControlForm,
          ai_agent_type: newVal.ai_agent_type,
          version_number: newVal.version_number,
          base_instructions: newVal.base_instructions,
          tools: newVal.tools,
          generation_structure: newVal.generation_structure,
          version_name: newVal.version_name,
        }
      }
    },
  },
  computed: {
    tableData() {
      return this.$store.state.aiVersionControls
    },
    aiExisitingVersions() {
      return this.$store.state.aiVersionControls
        .filter(
          item =>
            item.ai_agent_type === this.createAiVersionControlForm.ai_agent_type
        )
        .map(item => ({
          version_number: item.version_number,
          object: item,
        }))
    },
  },
  mounted() {
    const params = new URLSearchParams(this.$route.query)
    const tab = parseInt(params.get('tab'))
    if (!isNaN(tab)) {
      this.activeTab = tab
    }
  },
  created() {
    this.fetchTableData()
  },
  methods: {
    fetchTableData() {
      this.$store.dispatch('getAiVersionControls')
    },
    switchTab(tab) {
      if (this.activeTab !== tab) {
        this.$router.push({ query: { tab: tab } })
        this.activeTab = tab
        if (this.activeTab == 2) this.fetchTableData()
      }
    },
    remove(item) {
      this.$store.dispatch('updateAiVersionControl', {
        ai_version_control: {
          id: item.id,
          active: false,
        },
      })
    },
    saveData() {
      this.$store.dispatch('createAiVersionControl', {
        ai_version_control: {
          ...this.createAiVersionControlForm,
          base_instructions:
            typeof this.createAiVersionControlForm.base_instructions ===
            'string'
              ? JSON.parse(this.createAiVersionControlForm.base_instructions)
              : this.createAiVersionControlForm.base_instructions,
          tools:
            typeof this.createAiVersionControlForm.tools === 'string'
              ? JSON.parse(this.createAiVersionControlForm.tools)
              : this.createAiVersionControlForm.tools,
          generation_structure:
            typeof this.createAiVersionControlForm.generation_structure ===
            'string'
              ? JSON.parse(this.createAiVersionControlForm.generation_structure)
              : this.createAiVersionControlForm.generation_structure,
        },
      })
      this.switchTab(2)
      this.createAiVersionControlForm = {}
    },
    openAiBuilderModal(item) {
      this.$store.commit('showModal', {
        name: 'AiBuilderModal',
        maxWidth: 400,
        props: {
          selectedVersion: item,
        },
      })
    },
    toggleModal(item = null) {
      this.dialog = !this.dialog
      if (item) this.selectedVersion = item
    },
    jsonValidationRule(field) {
      try {
        JSON.parse(this.createAiVersionControlForm[field])
        return true
      } catch (error) {
        return 'Please enter valid JSON'
      }
    },
  },
}
</script>

<style scoped>
@import 'vanilla-jsoneditor/themes/jse-theme-dark.css';

.custom-json-editor {
  --jse-theme-color: #44a2a2;
  --jse-theme-color-highlight: #687177;
  --jse-panel-background: #f7f7f7;
  --jse-background-color: #3c3a3a;
}
</style>
