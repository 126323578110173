<template>
  <div>
    <form-dialog title="Create Charge" class="p-relative" @submit="submit">
      <template #activator="{ on }">
        <v-btn x-small color="warning" depressed v-on="on">Charge</v-btn>
      </template>
      <v-text-field v-model="email" outlined dense label="email" />
      <v-checkbox
        v-if="canChargeThird"
        v-model="chargeThird"
        label="Charge Third"
        dense
        @change="toggleThird"
      />
      <v-text-field
        v-model.number="amount"
        type="number"
        :rules="[required]"
        outlined
        label="amount"
        dense
        :disabled="chargeThird"
      />
      <v-overlay
        v-if="status === 'pending'"
        color="secondary lighten-2"
        absolute
      >
        <loader-animation-palms />
      </v-overlay>
    </form-dialog>
    <v-dialog v-model="dialog" max-width="400" @click:outside="cancelPayment">
      <v-sheet class="pa-5 p-relative">
        <v-overlay
          v-if="status === 'pending'"
          color="secondary lighten-2"
          absolute
        >
          <loader-animation-palms />
        </v-overlay>
        <div>
          <div>
            <span class="text-subtitle-1 font-weight-medium">Guest:</span>
            <span class="text-subtitle-2">{{ email }}</span>
          </div>
          <div>
            <span class="text-subtitle-1 font-weight-medium">Amount:</span>
            <span class="text-subtitle-2">{{ dollarFormatter(amount) }}</span>
          </div>
        </div>
        <stripe-element
          button-text="charge"
          :email="email"
          :public-key="publicKey"
          :intent-id="intent.payment_intent_id"
          :client-secret="intent.client_secret"
          @payment-processing="onProcess"
          @payment-success="paymentSuccess"
          @payment-failed="paymentFailed"
        />
      </v-sheet>
    </v-dialog>
  </div>
</template>

<script>
import FormDialog from 'components/common/form-dialog'
import FormRules from 'components/mixins/form-rules-mixin'
import StripeElement from 'components/stripe/stripe-element'
import LoaderAnimationPalms from 'components/loaders/loader-animation-palms'
import Toaster from '@/utils/toaster'
import FormattersMixin from 'components/mixins/formatters-mixin'

export default {
  name: 'ChargeModal',
  components: { LoaderAnimationPalms, StripeElement, FormDialog },
  mixins: [FormRules, FormattersMixin],
  props: ['guestEmail', 'balance', 'canChargeThird', 'intent'],
  data() {
    return {
      chargeThird: false,
      amount: this.balance,
      email: this.guestEmail,
      dialog: false,
      publicKey: null,
      status: null,
    }
  },
  watch: {
    guestEmail(value) {
      this.email = value
    },
    balance(value) {
      this.amount = value
    },
  },
  methods: {
    onProcess() {
      this.status = 'pending'
    },
    toggleThird() {
      if (this.chargeThird) {
        this.amount = +(0.33 * this.amount).toFixed(2)
      } else {
        this.amount = this.balance
      }
    },
    async paymentSuccess() {
      Toaster.show([{ type: 'success', text: 'Payment done successfully' }])
      this.$emit('charge-success')
      this.dialog = false
    },
    async cancelPayment() {
      this.amount = this.balance
      this.chargeThird = false
      this.$emit('payment-canceled')
    },
    async paymentFailed() {
      Toaster.show([{ type: 'error', text: 'Payment failed to process' }])
      this.dialog = false
    },
    async submit() {
      this.status = 'pending'
      try {
        const data = await this.$store.dispatch('stripe/createManualCharge', {
          amount: this.amount,
          intent_id: this.intent.payment_intent_id,
        })
        this.publicKey = data.publicKey
        this.dialog = true
      } catch (e) {
        console.error(e)
      }
      this.status = null
    },
  },
}
</script>

<style scoped></style>
