import axios from '@/axios/config'
import { get, keyBy } from 'lodash/fp'

export default {
  namespaced: true,
  state: {
    allUsers: [],
    usersStats: [],
    teams: {},
  },
  mutations: {
    updateUsersMap(state, value) {
      state.allUsers = value
    },
    updateUsersStats(state, userStats) {
      state.usersStats = userStats
    },
    updateTeams(state, value) {
      state.teams = value
    },
  },
  actions: {
    async getAllUsers({ commit }) {
      const { data } = await axios.get('/api/users/')
      commit('updateUsersMap', data)
    },
    async getTeams({ commit }) {
      const { data } = await axios.get('/api/teams/')
      commit('updateTeams', keyBy('id', data))
    },
    async deleteUser({ dispatch }, id) {
      await axios.delete(`/api/users/${id}`)
      dispatch('getAllUsers')
      dispatch('getUsersStats')
    },
    async resetUserCredentials(store, payload) {
      return axios.patch(`/api/users/reset`, payload)
    },
    updateUserNew: function (context, { id, ...data }) {
      return axios
        .post(`/api/users/${id}`, data)
        .then(() => {
          context.dispatch('getCurrentUser', null, { root: true })
          context.dispatch('getUsersStats')
          context.dispatch('getAllUsers')
        })
        .catch(function (error) {
          console.error(error)
        })
    },
    updateProfile: function (context, { id, ...data }) {
      return axios
        .put(`/api/users/${id}/profile`, data)
        .then(() => {
          context.dispatch('getCurrentUser', null, { root: true })
          context.dispatch('getUsersStats')
          context.dispatch('getAllUsers')
        })
        .catch(function (error) {
          console.error(error)
        })
    },
    getUsersStats: function (context) {
      axios
        .get('/api/users/stats')
        .then(({ data }) => {
          context.commit('updateUsersStats', data)
        })
        .catch(function (error) {
          console.error(error)
        })
    },
    async saveCalendarFilter({ commit }, payload) {
      try {
        const { data } = await axios.post('/api/users/filter', payload)
        commit('updateUser', data, { root: true })
      } catch (err) {
        console.error(err)
      }
    },
    async setFilterAsDefault({ commit }, payload) {
      try {
        const { data } = await axios.post('/api/users/filter/default', payload)
        commit('updateUser', data, { root: true })
      } catch (err) {
        console.error(err)
      }
    },
    async createUser({ dispatch }, payload) {
      try {
        await axios.post('/api/users', payload)
        dispatch('getUsersStats')
      } catch (err) {
        console.error(err)
      }
    },
    async validatePhone(_store, phone) {
      try {
        const { data } = await axios.get('/api/users/validate', {
          params: { phone },
        })
        return data
      } catch (err) {
        return null
      }
    },
    async deleteFilter({ commit }, payload) {
      try {
        const { data } = await axios.delete('/api/users/filter', {
          data: payload,
        })
        commit('updateUser', data, { root: true })
      } catch (err) {
        console.error(err)
      }
    },
  },
  getters: {
    usersList({ allUsers, teams }) {
      return allUsers
        .filter(u => u.role !== 'investor')
        .map(user => {
          user.team_name = get([user.team_id, 'name'], teams)
          return user
        })
    },
    usersManageList({ usersStats, teams }) {
      if (!usersStats.length) {
        return []
      }
      return usersStats.map(user => {
        user.team_name = get([user.team_id, 'name'], teams)
        user.attached_listings = user.listings_users.map(l => l.listing_id)
        return user
      })
    },
    activeUsers(state, getters) {
      return getters.usersList.filter(u => u.active)
    },
    approvers(state, getters) {
      return getters.activeUsers.filter(u =>
        u.abilities.some(a => a === 'task-approval')
      )
    },
    investors({ allUsers }) {
      return allUsers.filter(u => u.role === 'investor' && u.active)
    },
    usersMap(state, getters) {
      return keyBy('id', getters.usersList)
    },
    payableUsers(state, getters) {
      return getters.usersList.filter(
        user =>
          user.active &&
          [
            'contractor',
            'cleaning-supervisor',
            'property-manager',
            'field-pm',
            'admin',
          ].includes(user.role)
      )
    },
    payableUsersWithMissingBankInfo(state, getters) {
      return getters.payableUsers.filter(
        user =>
          !user.on_payrole &&
          (!user.ach_recipient_name ||
            !user.ach_routing_number ||
            !user.ach_account_number)
      )
    },
  },
}
