<template>
  <v-data-table
    :sort-by="['name']"
    :items="taxProfiles.tax_profiles"
    :headers="taxHeaders"
    v-on="$listeners"
  >
    <template #top>
      <v-row class="px-3 align-center">
        <v-col cols="6" md="2">
          <v-text-field
            v-model="searchTerm"
            label="Search by name..."
            prepend-inner-icon="search"
            dense
            outlined
            hide-details
          />
        </v-col>
        <v-col cols="12" class="text-right">
          <tax-profile-create-modal />
        </v-col>
      </v-row>
    </template>
    <template #item="{ item }">
      <tr class="align-center">
        <td>
          <div class="font-weight-medium text-capitalize">{{ item.name }}</div>
        </td>
        <td>
          <div v-if="item.is_default" class="text-overline">Default</div>
          <v-btn
            v-else
            color="primary"
            small
            @click="onUpdate(true, item.id, 'is_default')"
            >Set default</v-btn
          >
        </td>
        <td class="text-sm">
          <v-btn
            color="primary"
            class="text-capitalize"
            icon
            elevation="0"
            @click="editTaxProfile(item)"
          >
            <v-icon size="25">edit</v-icon>
          </v-btn>
        </td>
      </tr>
    </template>
  </v-data-table>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import TaxProfileCreateModal from 'components/tax-profiles/tax-profile-create-modal.vue'

export default {
  name: 'TaxProfileTable',
  components: { TaxProfileCreateModal },
  props: ['headers', 'fetchAction', 'items'],
  data() {
    return {
      searchTerm: '',
    }
  },

  async created() {
    await this.$store.dispatch('taxProfile/getTaxProfiles')
  },

  methods: {
    ...mapActions('taxProfile', ['updateTaxProfile']),
    onUpdate(value, id, key) {
      this.updateTaxProfile({ tax_profile: { id: id, [key]: value } })
    },
    editTaxProfile(item) {
      this.$store.commit('showModal', {
        name: 'TaxProfileEditModal',
        props: { taxProfile: item },
        isPersistent: false,
        maxWidth: 900,
      })
    },
  },
  computed: {
    ...mapState('taxProfile', ['taxProfiles']),
    taxHeaders() {
      return [
        {
          name: 'name',
          text: 'Name',
          sortable: false,
          value: 'name',
        },
        {
          name: 'is default',
          text: 'Is Default',
          sortable: false,
          value: 'is_default',
        },
        {
          name: 'actions',
          text: 'Actions',
          sortable: false,
        },
      ]
    },
  },
}
</script>

<style scoped></style>
