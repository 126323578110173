<template>
  <v-form ref="updateListing" lazy-validation @submit.prevent="updateListing">
    <div
      v-if="dirty || !!errorsCount"
      class="sticky-bar amber lighten-4 d-flex justify-end align-center"
      :class="{ 'mobile-sticky-bar': isMobile }"
    >
      <div class="font-weight-medium text-body-2 orange--text text--darken-4">
        <span v-if="dirty">You have unsaved changes. </span>
        <span v-if="!!errorsCount"
          >You have <b>{{ errorsCount }}</b>
          {{ errorsCount === 1 ? 'error' : 'errors' }}</span
        >
      </div>
      <v-btn
        class="my-4 mx-6 py-2"
        color="primary"
        depressed
        type="submit"
        small
        >Save Changes
      </v-btn>
    </div>
    <v-container @input="onChange">
      <v-sheet class="white overflow-y-auto">
        <div class="d-flex justify-space-between align-center my-3">
          <div class="text-h6">{{ $t('General property information') }}</div>
        </div>
        <v-row>
          <v-col cols="12">
            <div class="text-body-1 font-weight-medium">
              Location details
            </div></v-col
          >
          <v-col cols="12">
            <v-row no-gutters class="align-center">
              <v-col cols="9">
                <v-select
                  v-model="listing.listing_type"
                  label="Listing Type"
                  :items="['regular', 'unit', 'hotel']"
                  outlined
                  class="flex"
                  dense
                  :rules="[required]"
                  @change="onChange"
                />
              </v-col>
              <v-spacer />
              <v-col cols="auto">
                <v-checkbox
                  v-model="listing.is_multi_unit"
                  label="Is multi unit"
                  class="mt-0"
                  dense
                />
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12">
            <div class="d-flex justify-end text-caption">
              <div>{{ titleCharCounter }} / {{ 50 }}</div>
            </div>
            <v-text-field
              v-model="listing.title"
              label="Title"
              outlined
              dense
              :rules="titleRules"
              :min="20"
              @keyup="onTitleDown"
            />
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="listing.nickname"
              label="Nickname"
              outlined
              dense
              :rules="[required]"
          /></v-col>
          <v-col cols="12" sm="6" class="d-flex align-baseline">
            <v-text-field
              id="autocomplete"
              v-model="listing.address"
              autocomplete="off"
              label="Address"
              outlined
              dense
              class="me-4"
              :rules="[required]"
            />
            <place-input
              :position="{ lat: listing.lat, lng: listing.lng }"
              :map-options="mapOptions"
              @marker-moved="markerMoved"
            />
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="listing.extra_info.licence_number"
              label="Licence Number (Optional)"
              outlined
              dense
            />
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="listing.zip_code"
              label="Zip code"
              outlined
              dense
              :rules="[required]"
            />
          </v-col>
          <v-col v-if="listing.is_multi_unit" cols="6">
            <property-picker
              :not-top-header="true"
              :after-change="listingChanged"
              :value="multiUnits"
              label="Multi units"
              :allow-multiple="true"
              :clearable="true"
              :prepend-listings="prependListings"
            />
          </v-col>
          <v-card-text class="px-6">
            <p class="text-body-1 font-weight-medium">Listing details</p>
            <v-row>
              <v-col cols="12" sm="3">
                <v-text-field
                  v-model.number="listing.beds"
                  label="Num of bedrooms"
                  type="number"
                  :min="1"
                  :max="30"
                  outlined
                  dense
                  disabled
                />
              </v-col>
              <v-col cols="12" sm="3">
                <v-text-field
                  v-model.number="listing.baths"
                  label="Num of bathrooms"
                  type="number"
                  :rules="[required, isPositive]"
                  :min="1"
                  outlined
                  dense
                />
              </v-col>
              <v-col cols="12" sm="3">
                <v-select
                  v-model="listing.ota_type"
                  label="Listing Type"
                  :items="otaTypes"
                  outlined
                  dense
                  :rules="[required]"
                  @change="onChange"
                />
              </v-col>
              <v-col cols="12" sm="3">
                <v-text-field
                  v-model.number="listing.space"
                  label="Space (sqm)"
                  type="number"
                  outlined
                  dense
                  :rules="[required]"
                />
              </v-col>
              <v-col cols="12" sm="3">
                <v-text-field
                  v-model.number="listing.floor"
                  label="Floor"
                  type="number"
                  :rules="[required]"
                  :min="1"
                  outlined
                  dense
                />
              </v-col>
              <v-col cols="12" sm="3">
                <v-text-field
                  v-model="listing.check_in_time"
                  label="Check-in start"
                  type="time"
                  :placeholder="'16:00'"
                  :rules="[required]"
                  outlined
                  dense
                />
              </v-col>
              <v-col cols="12" sm="3">
                <v-text-field
                  v-model="listing.extra_info.check_in_end"
                  label="Check-in end"
                  type="time"
                  :placeholder="'22:00'"
                  :rules="[required]"
                  outlined
                  dense
                />
              </v-col>
              <v-col cols="12" sm="3">
                <v-text-field
                  v-model="listing.check_out_time"
                  label="Check-out start"
                  :placeholder="'12:00'"
                  type="time"
                  :rules="[required]"
                  outlined
                  dense
                />
              </v-col>
              <v-col cols="12" sm="3">
                <v-text-field
                  v-model.number="listing.standard_guests"
                  label="Guest capacity"
                  type="number"
                  :rules="[required, isPositive]"
                  :min="1"
                  outlined
                  dense
                />
              </v-col>
              <v-col cols="12" sm="3">
                <v-text-field
                  v-model.number="listing.accommodates"
                  label="Maximum Guests"
                  type="number"
                  :rules="[required, isPositive, enoughBeds]"
                  :min="1"
                  outlined
                  dense
                />
              </v-col>
            </v-row>
            <div class="pa-2">
              <p class="text-h6 font-weight-medium">Shared space</p>
              <div
                v-for="(room, index) in otherRooms"
                :key="index"
                class="pa-2"
              >
                <v-row no-gutters class="align-center">
                  <v-col cols="4">
                    <v-select
                      v-model="room.integration_type"
                      outlined
                      dense
                      hide-details
                      :items="roomTypes"
                      label="Room Type"
                      :rules="[required]"
                      @change="onChange"
                    />
                  </v-col>
                  <v-col cols="1" class="mx-2">
                    <v-icon
                      class="ml-auto"
                      small
                      color="warning"
                      @click="removeRoom(index)"
                      >fas fa-minus-circle</v-icon
                    >
                  </v-col>
                </v-row>
                <div
                  v-for="(amenity, ind) in room.amenities"
                  :key="ind"
                  class="py-2"
                >
                  <v-row class="align-center" no-gutters>
                    <v-col cols="6" md="4">
                      <v-select
                        v-model="room.amenities[ind].bedType"
                        outlined
                        dense
                        hide-details
                        :items="bedTypes"
                        label="Bed Type"
                        :rules="[required]"
                        @change="onChange"
                      />
                    </v-col>
                    <v-col cols="auto" class="mx-2">
                      <v-text-field
                        v-model="room.amenities[ind].quantity"
                        :rules="[required]"
                        hide-details
                        label="Amount"
                        dense
                        outlined
                      />
                    </v-col>
                    <v-icon
                      color="warning"
                      class="mx-2"
                      small
                      @click="removeBed(room, ind)"
                      >fas fa-minus-circle</v-icon
                    >
                  </v-row>
                </div>
                <v-btn
                  outlined
                  class="ma-2"
                  small
                  color="info"
                  @click="addBed(room, index)"
                  ><v-icon small left>fas fa-plus</v-icon> Add bed
                </v-btn>
                <v-sheet
                  height="1"
                  width="100%"
                  color="black"
                  class="mt-4 mb-3"
                />
              </div>
              <v-btn outlined class="my-3" color="info" @click="addRoom()"
                ><v-icon left>fas fa-plus</v-icon> Add Room
              </v-btn>
            </div>
            <div class="pa-2">
              <p class="text-h6 font-weight-medium mt-3">Bedrooms</p>
              <div
                v-for="(bedroom, index) in bedsBreakdown"
                :key="index"
                class="px-2"
              >
                <div class="align-center d-flex">
                  <span class="text-subtitle-1 font-weight-medium py-1"
                    >Bedroom #{{ index + 1 }}</span
                  >
                  <v-icon
                    color="warning"
                    class="mx-2"
                    small
                    @click="removeBedroom(index)"
                    >fas fa-minus-circle</v-icon
                  >
                </div>

                <div
                  v-for="(amenity, ind) in bedroom.amenities"
                  :key="ind"
                  class="pa-2"
                >
                  <v-row class="align-center" no-gutters>
                    <v-col cols="6" md="4">
                      <v-select
                        v-model="bedroom.amenities[ind].bedType"
                        outlined
                        dense
                        hide-details
                        :items="bedTypes"
                        label="Bed Type"
                        :rules="[required]"
                        @change="onChange"
                      />
                    </v-col>
                    <v-col cols="auto" class="mx-2">
                      <v-text-field
                        v-model="bedroom.amenities[ind].quantity"
                        :rules="[required]"
                        hide-details
                        label="Amount"
                        dense
                        outlined
                      />
                    </v-col>
                    <v-icon
                      small
                      color="warning"
                      @click="removeBed(bedroom, ind)"
                      >fas fa-minus-circle</v-icon
                    >
                  </v-row>
                </div>
                <v-btn
                  outlined
                  small
                  class="ma-2"
                  color="info"
                  @click="addBed(bedroom, index)"
                  ><v-icon small left>fas fa-plus</v-icon> Add bed
                </v-btn>
                <v-sheet
                  height="1"
                  width="100%"
                  color="black"
                  class="mt-4 mb-3"
                />
              </div>
              <v-btn
                outlined
                class="ma-2"
                color="info"
                @click="addBedroom(bedsBreakdown.length + 1)"
                ><v-icon left>fas fa-plus</v-icon> Add bedroom
              </v-btn>
            </div>
            <div class="d-flex justify-end text-caption">
              <div>{{ summaryCharCounter }} / {{ 40000 }}</div>
            </div>
            <v-textarea
              v-model="listing.marketing_content.summary"
              label="Summary"
              :rules="descriptionRules"
              outlined
              dense
              @keyup="onSummaryDown"
            />
            <v-textarea
              v-model="listing.marketing_content.space"
              label="Space"
              outlined
              dense
            />
            <v-textarea
              v-model="listing.marketing_content.access"
              label="Access"
              outlined
              dense
            />
            <v-textarea
              v-model="listing.marketing_content.notes"
              label="Notes"
              outlined
              dense
            />
            <v-textarea
              v-model="listing.marketing_content.houseRules"
              label="House rules"
              outlined
              dense
            />
            <v-textarea
              v-model="listing.marketing_content.interactionWithGuests"
              label="Interaction"
              outlined
              dense
            />
            <v-textarea
              v-model="listing.marketing_content.neighborhood"
              label="Neighborhood"
              outlined
              dense
            />
            <v-textarea
              v-model="listing.marketing_content.transit"
              label="Transit"
              outlined
              dense
            />
          </v-card-text>
        </v-row>
      </v-sheet>
    </v-container>
  </v-form>
</template>

<script>
import { loadGmaps } from '@/modules/remote-resources'
import isNil from 'lodash/isNil'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import FormRules from 'components/mixins/form-rules-mixin'
import DeviceMixin from 'components/mixins/device-mixin'
import PropertyPicker from 'components/property-picker'
import PlaceInput from 'components/common/place-input.vue'
import { mapGetters, mapState } from 'vuex'
import { BED_CAPACITY } from '@/consts'

export default {
  name: 'ListingChannelGeneral',
  components: {
    PropertyPicker,
    PlaceInput,
  },
  mixins: [FormRules, DeviceMixin],
  props: ['listing'],
  data() {
    return {
      dirty: false,
      errorsCount: 0,
      titleCharCounter: 0,
      summaryCharCounter: 0,
      mapOptions: {
        zoom: 2,
        center: { lat: 0, lng: 0 },
      },
      isMultiUnit: true,
      argsIndex: 0,
      args: [],
      bedsBreakdown:
        this.listing.room_composition.filter(
          c => c.integration_type === 'Bedroom'
        ) || [],
      otherRooms: this.listing.room_composition.filter(
        c => c.integration_type !== 'Bedroom'
      ),
    }
  },
  async mounted() {
    this.initGoogleAutocomplete()
    this.populateBedsBreakdown()
    this.$nextTick(() => {
      this.validate()
    })
    this.titleCharCounter = this.listing.title?.length
    this.summaryCharCounter =
      this.listing.marketing_content?.summary?.length || 0
  },
  methods: {
    onChange() {
      this.dirty = true
      this.validate()
    },
    validate() {
      const isValid = this.$refs.updateListing.validate()
      this.errorsCount = 0
      if (!isValid) {
        const errors = this.$refs.updateListing.inputs.filter(i => !i.valid)
        this.errorsCount = errors.length
        return errors
      }
      return []
    },
    onTitleDown() {
      this.titleCharCounter = this.listing.title.length
    },
    onSummaryDown() {
      this.summaryCharCounter = this.listing.marketing_content?.summary?.length
    },
    async updateListing() {
      const errors = this.validate()
      if (errors.length) {
        return errors[0].$el.scrollIntoView({ behavior: 'smooth' })
      }
      this.$store.commit('updateLoading', true)
      try {
        await this.$store.dispatch('listings/updateListing', {
          id: this.$route.params.id,
          payload: {
            lat: this.listing.lat,
            lng: this.listing.lng,
            address: this.listing.address,
            description: this.listing.description,
            beds: this.bedsBreakdown.length,
            nickname: this.listing.nickname,
            title: this.listing.title,
            accommodates: this.listing.accommodates,
            baths: this.listing.baths,
            space: this.listing.space,
            street: this.listing.street,
            zip_code: this.listing.zip_code,
            is_multi_unit: this.listing.is_multi_unit,
            listing_type: this.listing.listing_type,
            city_name: this.listing.city_name,
            country: this.listing.country,
            ota_type: this.listing.ota_type,
            standard_guests: this.listing.standard_guests,
            floor: this.listing.floor,
            room_composition: this.bedsBreakdown.concat(this.otherRooms),
            multi_unit_ids: this.listing.multi_unit_ids,
            days_before_arrival: 0,
            check_in_time: this.listing.check_in_time,
            check_out_time: this.listing.check_out_time,
            marketing_content: {
              summary: this.listing.marketing_content.summary,
              space: this.listing.marketing_content.space,
              access: this.listing.marketing_content.access,
              notes: this.listing.marketing_content.notes,
              houseRules: this.listing.marketing_content.houseRules,
              interactionWithGuests:
                this.listing.marketing_content.interactionWithGuests,
              neighborhood: this.listing.marketing_content.neighborhood,
              transit: this.listing.marketing_content.transit,
            },
            container_multi_id: this.listing.container_multi_id,
            extra_info: {
              rentals_property_id: this.listing.extra_info.rentals_property_id,
              check_in_end: this.listing.extra_info.check_in_end,
              contact_name: this.listing.extra_info.contact_name,
              contact_phone: this.listing.extra_info.contact_phone,
              contact_email: this.listing.extra_info.contact_email,
              licence_number: this.listing.extra_info.licence_number,
            },
          },
        })
        await this.$store.dispatch('listings/validateListing', {
          id: this.$route.params.id,
        })
      } catch (e) {
        console.log(e)
      }
      this.$store.commit('updateLoading', false)
      this.dirty = false
    },

    populateBedsBreakdown() {
      if (
        this.listing.room_composition !== null &&
        this.listing.room_composition !== undefined &&
        this.listing.room_composition.filter(
          c => c.integration_type === 'Bedroom'
        ).length > 0
      ) {
        return
      } else {
        this.updateBedsBreakdown(this.listing.beds || [])
      }
    },

    updateBedsBreakdown(arg) {
      const bedrooms = parseInt(arg) || parseInt(this.listing.beds)
      const existingBedrooms =
        this.listing.room_composition === undefined ||
        this.listing.room_composition === null
          ? 0
          : this.listing.room_composition.filter(
              c => c.integration_type === 'Bedroom'
            ).length
      const bedsToAdd =
        bedrooms - existingBedrooms > 0 ? bedrooms - existingBedrooms : 0

      for (var i = 0; i < bedsToAdd; i++) {
        this.addBedroom(i)
      }
    },

    addBedroom(idx) {
      this.bedsBreakdown.push({
        bedroomName: 'Bedroom ' + idx,
        amenities: [
          {
            bedType: '',
            quantity: '',
          },
        ],
        integration_type: 'Bedroom',
      })
    },
    addRoom() {
      this.otherRooms.push({
        bedroomName: null,
        amenities: [],
        integration_type: '',
      })
    },

    addBed(room, idx) {
      room.amenities.push({
        bedType: '',
        quantity: '',
      })
    },
    removeBed(room, idx) {
      room.amenities.splice(idx, 1)
    },
    removeBedroom(idx) {
      this.bedsBreakdown.splice(idx, 1)
    },
    removeRoom(idx) {
      this.otherRooms.splice(idx, 1)
    },
    listingChanged(val) {
      this.listing.multi_unit_ids = val
    },
    async publishListing() {
      await this.$store.dispatch('listings/publishListing', {
        id: this.$route.params.id,
      })
    },
    async initGoogleAutocomplete() {
      const googlePlaces = get(window, 'google.maps.places', null)
      if (isNil(googlePlaces)) await loadGmaps()

      const inputElem = document.getElementById('autocomplete')
      this.autocomplete = new window.google.maps.places.Autocomplete(inputElem)
      this.autocomplete.addListener(
        'place_changed',
        this.onAutocomplete.bind(this)
      )
    },
    onAutocomplete() {
      const place = this.autocomplete.getPlace()

      if (isEmpty(place.address_components)) return false

      const streetNumber = this.parsePlaceAttribute('street_number', place)
      const street = this.parsePlaceAttribute('route', place)
      if (street.includes(streetNumber)) {
        this.listing.street = street
      } else {
        this.listing.street = `${street} ${streetNumber}`
      }
      this.listing.zip_code = this.parsePlaceAttribute('postal_code', place)
      this.listing.country = this.parsePlaceAttribute('country', place)
      this.listing.city_name = this.parsePlaceAttribute('locality', place)

      this.listing.lat = place.geometry.location.lat()
      this.listing.lng = place.geometry.location.lng()
      this.listing.address = place.formatted_address
    },

    parsePlaceAttribute(attribute, place) {
      const addressComponent =
        place.address_components.find(
          component => component.types[0] === attribute
        ) || {}
      if (isEmpty(addressComponent) || isNil(addressComponent.long_name)) {
        return ''
      }

      return addressComponent.long_name
    },
    markerMoved({ lat, lng, place }) {
      this.listing.lat = lat
      this.listing.lng = lng
      this.listing.address = place.formatted_address
      const streetNumber = this.parsePlaceAttribute('street_number', place)
      const street = this.parsePlaceAttribute('route', place)
      if (street.includes(streetNumber)) {
        this.listing.street = street
      } else {
        this.listing.street = `${street} ${streetNumber}`
      }
      this.listing.zip_code = this.parsePlaceAttribute('postal_code', place)
      this.listing.country = this.parsePlaceAttribute('country', place)
      this.listing.city_name = this.parsePlaceAttribute('locality', place)
    },
    enoughBeds(v) {
      let totalBeds = 0
      const rooms = [...this.bedsBreakdown, ...this.otherRooms]
      rooms.forEach(room => {
        room.amenities.forEach(bed => {
          totalBeds += BED_CAPACITY[bed.bedType] * +bed.quantity
        })
      })
      return v <= totalBeds || 'Not enough bed space'
    },
  },
  computed: {
    ...mapState(['pmsData']),
    ...mapGetters(['otaTypes', 'roomTypes', 'bedTypes']),
    items() {
      return [
        'king size',
        'queen size',
        'extra bed',
        'bunk bed',
        'sofa bed',
        'mattress',
        'double sofa bed',
      ]
    },
    prependListings() {
      return this.$store.getters.listingsPicker
    },
    multiUnits() {
      return this.listing.multi_unit_ids || []
    },
  },
}
</script>

<style lang="scss" scoped>
.pac-item {
  line-height: 35px;
}
.sticky-bar {
  position: fixed;
  bottom: 0px;
  left: 250px;
  width: calc(100% - 250px);
  z-index: 10;
}
.mobile-sticky-bar {
  width: 100%;
  left: 0;
}

.hdpi.pac-logo:after {
  background-image: none;
}
:deep() .v-input {
  scroll-margin: 120px;
}
</style>
