<template>
  <v-card class="overflow-auto">
    <v-card-title class="text-center">
      <span class="text-h5"
        >{{ !editMode ? $t('Create New') : $t('Edit') }}
        {{ $t('Booking Engine') }}
        {{ editMode ? ' #' + bookingEngineSetting.id : '' }}</span
      >
    </v-card-title>
    <v-form ref="form" @submit.prevent="save">
      <v-card-text>
        <v-text-field
          v-model.trim="bookingEngineSetting.name"
          dense
          outlined
          hint="* Only lowercase letters, without spaces, dots or underscores"
          :persistent-hint="true"
          :rules="[required]"
          :label="`${$t('Name')} (subdomain)`"
        />
        <v-text-field
          v-model.trim="bookingEngineSetting.site_title"
          dense
          outlined
          :rules="[required]"
          :label="$t('Website Title')"
        />
        <v-text-field
          v-model.trim="bookingEngineSetting.custom_domain"
          dense
          outlined
          hide-details
          :label="$t('Custom Domain (Optional)')"
        />
        <div class="pl-1 text-body-2">
          {{
            `Your domain: ${
              bookingEngineSetting.custom_domain ||
              `${subdomain}.bookingsboom.com`
            }`
          }}
        </div>
        <div class="pl-1 text-caption grey--text">
          * Domain modifications may take several hours to take effect.
        </div>
        <v-col cols="12" class="text-subtitle-1 font-weight-medium">{{
          $t('Filters')
        }}</v-col>
        <multi-property-picker
          v-model="bookingEngineSetting.whitelist"
          :custom-listings="listings"
          :label="$t('Whitelisted Listings')"
          multiple
          :hide-btns="true"
        />
        <multi-property-picker
          v-model="bookingEngineSetting.blacklist"
          :custom-listings="listings"
          :disabled="bookingEngineSetting.whitelist.length !== 0"
          :label="$t('Blacklisted listings')"
          :hide-btns="true"
        />
        <multi-property-picker
          v-model="bookingEngineSetting.featured_listings"
          :custom-listings="listings"
          :disabled="bookingEngineSetting.featured_listings.length >= 9"
          :label="$t('Featured listings')"
          :filter-listing-ids="filteredFeaturedListings"
          :hide-btns="true"
        />
        <v-switch
          v-if="regions.length"
          v-model="bookingEngineSetting.filter_by_region"
          class="mt-0"
          size="small"
          label="filter by region instead of city"
        />
        <v-divider></v-divider>
        <v-col cols="12" class="text-subtitle-1 font-weight-medium">{{
          $t('Reservations')
        }}</v-col>
        <v-col cols="3" class="pt-0">
          <div>
            <v-text-field
              v-model.number="bookingEngineSetting.min_ahead"
              dense
              type="number"
              outlined
              messages="Reservations can be made a minimum of this many days in advance"
              :label="$t('Minimum days ahead')"
            />
          </div>
        </v-col>
        <v-divider></v-divider>
        <v-col cols="12" class="text-subtitle-1 font-weight-medium">{{
          $t('Header')
        }}</v-col>
        <v-text-field
          v-model="bookingEngineSetting.sections.header.title"
          dense
          outlined
          :label="$t('Title')"
        />
        <v-text-field
          v-model="bookingEngineSetting.sections.header.sub_title"
          dense
          outlined
          :label="$t('Sub-title')"
        />
        <v-input :value="bookingEngineSetting.bg_image" :rules="[required]" />
        <v-row>
          <v-col cols="12" md="6">
            <gallery
              :key="bookingEngineSetting.bg_image"
              button-text="Background image"
              folder="booking-engine"
              :compress="false"
              :cols="6"
              :thumbnails="true"
              readonly
              :images="
                bookingEngineSetting.bg_image
                  ? [bookingEngineSetting.bg_image]
                  : []
              "
              :on-change="afterBgUpload"
              :uploadable="true"
            />
          </v-col>
          <v-col cols="12" md="6">
            <gallery
              :key="bookingEngineSetting.logo"
              button-text="Site Logo"
              folder="booking-engine"
              :compress="false"
              :cols="6"
              readonly
              :thumbnails="true"
              :images="
                bookingEngineSetting.logo ? [bookingEngineSetting.logo] : []
              "
              :on-change="afterLogoload"
              :uploadable="true"
            />
          </v-col>
        </v-row>
        <v-col cols="12" class="text-subtitle-1 font-weight-medium">{{
          $t('Destinations')
        }}</v-col>
        <v-select
          v-model="bookingEngineSetting.destination_cities"
          label="Cities"
          outlined
          item-text="name"
          return-object
          dense
          multiple
          :hide-details="true"
          :items="destinationCities"
        />
        <v-row>
          <v-col
            v-for="(city, key) in bookingEngineSetting.destination_cities"
            :key="key"
            cols="12"
            sm="6"
            md="4"
          >
            <gallery
              :button-text="city.name"
              folder="booking-engine"
              :compress="false"
              :cols="6"
              :thumbnails="true"
              :images="city.image ? [city.image] : []"
              :on-change="imgs => afterCityUpload(imgs, city.name, key)"
              :uploadable="true"
            />
          </v-col>
        </v-row>
        <v-divider class="mt-2"></v-divider>
        <v-col cols="12" class="text-subtitle-1 font-weight-medium">{{
          $t('Theme')
        }}</v-col>
        <v-row>
          <v-col class="shrink" style="min-width: 220px">
            <v-text-field
              v-model="bookingEngineSetting.theme.main_color"
              hide-details
              class="ma-0 pa-0"
              placeholder="Main color"
              solo
            >
              <template #append>
                <div
                  :style="
                    swatchStyleMain(bookingEngineSetting.theme.main_color)
                  "
                />
              </template>
            </v-text-field>
          </v-col>
          <v-col class="shrink" style="min-width: 220px">
            <v-text-field
              v-model="bookingEngineSetting.theme.secondary_color"
              hide-details
              class="ma-0 pa-0"
              placeholder="Second color"
              solo
            >
              <template #append>
                <div
                  :style="
                    swatchStyleMain(bookingEngineSetting.theme.secondary_color)
                  "
                />
              </template>
            </v-text-field>
          </v-col>
          <v-col class="shrink" style="min-width: 220px">
            <v-text-field
              v-model="bookingEngineSetting.theme.actions_color"
              hide-details
              class="ma-0 pa-0"
              placeholder="Actions color"
              solo
            >
              <template #append>
                <div
                  :style="
                    swatchStyleMain(bookingEngineSetting.theme.actions_color)
                  "
                />
              </template>
            </v-text-field>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-col cols="12" class="text-subtitle-1 font-weight-medium">{{
          $t('Business')
        }}</v-col>
        <v-text-field
          v-model="bookingEngineSetting.business.email"
          dense
          outlined
          :label="$t('Email')"
        />
        <v-text-field
          v-model="bookingEngineSetting.business.phone"
          dense
          outlined
          :label="$t('Phone')"
        />
        <v-text-field
          v-model="bookingEngineSetting.business.name"
          dense
          outlined
          :label="$t('Name')"
        />
        <v-text-field
          v-model="bookingEngineSetting.business.address"
          dense
          outlined
          :label="$t('Address')"
        />
        <v-col cols="12" class="text-subtitle-1 font-weight-medium">{{
          $t('Social links')
        }}</v-col>
        <v-text-field
          v-model="bookingEngineSetting.social_links.facebook"
          dense
          outlined
          :label="$t('Facebook')"
        />
        <v-text-field
          v-model="bookingEngineSetting.social_links.instagram"
          dense
          outlined
          :label="$t('Instagram')"
        />
        <v-text-field
          v-model="bookingEngineSetting.social_links.google"
          dense
          outlined
          :label="$t('Google')"
        />
        <v-col cols="12" class="text-subtitle-1 font-weight-medium"
          >{{ $t('Analytics') }}
          <span>
            <info-tooltip
              text="Add your Google tag ID from Google Analytics" /></span
        ></v-col>
        <v-text-field
          v-model="bookingEngineSetting.analytics.google"
          dense
          outlined
          :label="$t('Google Analytics (gtag)')"
        />
        <v-text-field
          v-model="bookingEngineSetting.analytics.pixel"
          dense
          outlined
          :label="$t('Facebook Pixel (Pixel Id)')"
        />
        <v-divider></v-divider>
        <v-col cols="12" class="text-subtitle-1 font-weight-medium">{{
          $t('Finance')
        }}</v-col>
        <v-checkbox
          v-model="bookingEngineSetting.allow_bank_payments"
          label="Allow bank transfers"
        ></v-checkbox>
        <v-text-field
          v-model="bookingEngineSetting.custom_markup"
          dense
          outlined
          type="number"
          hint="0.22"
          :label="$t('Markup')"
        />
        <v-divider></v-divider>

        <v-col cols="12" class="text-subtitle-1 font-weight-medium">{{
          $t('Cancellation Policy')
        }}</v-col>
        <v-textarea
          v-model="bookingEngineSetting.cancellation_policy"
          dense
          outlined
          :label="$t('Cancellation Policy')"
        />
        <v-col cols="12" class="text-subtitle-1 font-weight-medium">{{
          $t('Scripts')
        }}</v-col>
        <v-textarea
          v-model="bookingEngineSetting.injected_scripts"
          dense
          outlined
          messages="Scripts will be injected into the head of the page"
          :label="$t('Scripts')"
        />
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn color="blue darken-1" text @click.native="close">Close</v-btn>
        <v-btn color="blue darken-1" :disabled="loading" text type="submit"
          >{{ $t('Save') }}
        </v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import axios from 'axios'
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import FormRulesMixin from 'components/mixins/form-rules-mixin'
import MultiPropertyPicker from 'components/multi-property-picker.vue'
import Gallery from 'components/common/gallery.vue'
import { mapGetters, mapState } from 'vuex'
import InfoTooltip from 'components/common/info-tooltip.vue'

export default {
  components: {
    Gallery,
    MultiPropertyPicker,
    InfoTooltip,
  },
  mixins: [CommonFunctions, PermissionsMixin, FormRulesMixin],
  props: [
    'afterSaveFunc',
    'editMode',
    'currentBookingEngineSetting',
    'listings',
  ],
  computed: {
    ...mapGetters(['listingCities', 'currentUser']),
    ...mapState('regions', ['regions']),

    subdomain() {
      return this.bookingEngineSetting.name
        ? this.bookingEngineSetting.name
            .replace(/ /g, '')
            .replace(/\./g, '')
            .replace(/_/g, '')
            .toLowerCase()
        : ''
    },
    destinationCities() {
      return this.listingCities.map(city => ({ name: city, image: null }))
    },
    filteredFeaturedListings() {
      const { whitelist, blacklist } = this.bookingEngineSetting
      const allListings = this.listings.map(listing => listing.id)
      let filtered = null
      if (whitelist.length) filtered = [...whitelist]
      if (blacklist.length)
        filtered = (filtered || allListings || []).filter(
          id => !blacklist.includes(id)
        )

      return filtered
    },
  },
  data() {
    return {
      menuMain: false,
      bookingEngineSetting: this.currentBookingEngineSetting
        ? {
            ...this.currentBookingEngineSetting,
          }
        : {
            sections: { header: {} },
            business: {},
            listings_ids: [],
            whitelist: [],
            blacklist: [],
            destination_cities: [],
            featured_listings: [],
            social_links: {},
            analytics: {},
            logo: null,
            theme: {
              main_color: '#44a2a2',
              secondary_color: '#dfe0f2',
              actions_color: '#464673',
            },
          },
    }
  },
  mounted() {},
  methods: {
    swatchStyleMain(color) {
      return {
        backgroundColor: color,
        cursor: 'pointer',
        height: '30px',
        width: '30px',
        borderRadius: this.menuMain ? '50%' : '4px',
        transition: 'border-radius 200ms ease-in-out',
      }
    },
    close() {
      this.$store.commit('hideModal')
    },
    addAfterImg(section, key, imgs) {
      this.bookingEngineSetting.sections[section][key] = imgs
    },
    afterBgUpload(imgs) {
      this.$set(this.bookingEngineSetting, 'bg_image', imgs[0])
    },
    afterLogoload(imgs) {
      this.$set(this.bookingEngineSetting, 'logo', imgs[0])
    },
    afterCityUpload(imgs, name, key) {
      this.bookingEngineSetting.destination_cities[key] = {
        name,
        image: imgs[0],
      }
      this.$forceUpdate()
    },
    save() {
      if (this.$refs.form.validate() && !this.showError) {
        this.$store.commit('updateListingLoading', true)
        if (this.editMode) {
          axios
            .patch(
              `/api/booking-engine-settings/${this.bookingEngineSetting.id}`,
              this.bookingEngineSetting
            )
            .then(() => {
              this.$store.commit('updateListingLoading', false)
              if (this.afterSaveFunc) {
                this.afterSaveFunc()
              }
              this.close()
            })
            .catch(error => {
              console.error(error)
              this.$store.commit('updateListingLoading', false)
            })
        } else {
          axios
            .post('/api/booking-engine-settings', this.bookingEngineSetting)
            .then(() => {
              this.$store.commit('updateListingLoading', false)

              this.close()
              if (this.afterSaveFunc) {
                this.afterSaveFunc()
              }
            })
            .catch(error => {
              console.error(error)
              this.$store.commit('updateListingLoading', false)
            })
        }
      }
    },
  },
}
</script>
