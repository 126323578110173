<template>
  <v-sheet>
    <div class="d-flex justify-space-between align-center my-3 px-3">
      <div class="text-h6">Calculation Formulas</div>
      <v-dialog v-model="dialog" persistent max-width="500px">
        <template #activator="{ on }">
          <v-btn
            small
            depressed
            color="success"
            :disabled="disabledSave"
            v-on="on"
            >Save</v-btn
          >
        </template>
        <v-card class="pa-8">
          <div class="text-center">
            <div class="font-weight-medium">
              This will affect all reservations starting from
            </div>
            <div class="d-inline-flex ma-6">
              <v-text-field
                v-model="commissionRecalcFrom"
                :max="$moment().utc().format('YYYY-MM-DD')"
                outlined
                dense
                hide-details
                type="date"
                @focus="e => e.target.showPicker()"
              />
            </div>
          </div>
          <div class="d-flex justify-end">
            <v-btn color="secondary" text @click="dialog = false">Cancel</v-btn>
            <v-btn
              color="success"
              class="font-weight-bold"
              text
              @click="updateListing"
              >Save</v-btn
            >
          </div>
        </v-card>
      </v-dialog>
    </div>
    <v-sheet max-height="700" class="overflow-y-auto px-3 pb-3">
      <v-row v-if="settings" no-gutters>
        <v-col v-if="isAdmin" cols="12" class="pa-2 mb-8">
          <h4 class="mb-4">Commission</h4>
          <v-text-field
            v-model.number="managementCommissionAbove"
            :label="$t('Management Commission')"
            outlined
            type="number"
            dense
            :clearable="isHotel && !isDisabledManagementCommissionAbove"
            :hint="$t(managementCommissionAboveHint)"
            persistent-hint
            :min="0"
            :max="1"
            step="0.01"
            :rules="[
              v => (v >= 0 && v <= 1) || $t('Value must be between 0 and 1'),
            ]"
            :disabled="isDisabledManagementCommissionAbove"
            @input="disabledSave = false"
          />
          <v-text-field
            v-if="isOptima"
            v-model="cleaningFee"
            class="mt-3"
            :label="$t('Cleaning Fee')"
            dense
            outlined
            hide-details
            @input="disabledSave = false"
          />
          <v-switch
            v-model="ownerCleaning"
            :label="$t('Owner cleaning')"
            hide-details
            @change="disabledSave = false"
          />
        </v-col>

        <v-col cols="12" class="pa-2">
          <v-select
            v-model="netIncomeFormula"
            outlined
            prepend-inner-icon="mdi-calculator"
            label="Net Income Formula"
            dense
            clearable
            :hint="
              zone.net_income_formula || settings.net_income_formula
                ? `Using system default (${
                    zone.net_income_formula || settings.net_income_formula
                  })`
                : null
            "
            :persistent-hint="!netIncomeFormula"
            :items="NET_INCOME_FORMULAS"
            @change="disabledSave = false"
          />
        </v-col>
        <v-col cols="12" class="pa-2">
          <v-select
            v-model="managementCommissionFormula"
            outlined
            prepend-inner-icon="mdi-calculator"
            label="Management Commission Formula"
            dense
            clearable
            :hint="
              zone.management_commission_formula ||
              settings.management_commission_formula
                ? `Using system default (${
                    zone.management_commission_formula ||
                    settings.management_commission_formula
                  })`
                : null
            "
            :persistent-hint="!managementCommissionFormula"
            :items="MANAGEMENT_COMMISSION_FORMULAS"
            @change="disabledSave = false"
          />
        </v-col>
        <v-col cols="12" class="pa-2">
          <v-select
            v-model="ownerRevenueFormula"
            outlined
            label="Owner Revenue Formula"
            dense
            prepend-inner-icon="mdi-calculator"
            clearable
            :hint="
              zone.owner_revenue_formula || settings.owner_revenue_formula
                ? `Using system default (${
                    zone.owner_revenue_formula || settings.owner_revenue_formula
                  })`
                : null
            "
            :persistent-hint="!ownerRevenueFormula"
            :items="OWNER_REVENUE_FORMULAS"
            @change="disabledSave = false"
          />
        </v-col>
        <v-col cols="12" class="pa-2">
          <v-select
            v-model="ownerIncomeFormula"
            outlined
            label="Owner Income Formula"
            dense
            prepend-inner-icon="mdi-calculator"
            clearable
            :hint="
              settings.owners_income_formula
                ? `Using system default (${settings.owners_income_formula})`
                : null
            "
            :persistent-hint="!ownerIncomeFormula"
            :items="OWNERS_INCOME_FORMULAS"
            @change="disabledSave = false"
          />
        </v-col>
        <v-col cols="12" class="pa-2">
          <date-picker
            v-model="cutoffDate"
            :hide-details="false"
            label="Cutoff date"
            :clearable="true"
            @change="disabledSave = false"
          />
        </v-col>
        <v-expansion-panels class="ml-2 mr-2 mt-2">
          <v-expansion-panel>
            <v-expansion-panel-header>
              <div class="mt-1">Channel Settings</div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-card>
                <v-card-text>
                  <v-sheet
                    v-for="(val, key) in channels"
                    :key="key"
                    outlined
                    class="pa-2 rounded my-2"
                  >
                    <h4 class="text-h6 primary--text">
                      {{ val }}
                    </h4>
                    <v-switch
                      v-model="channel_settings[val].override"
                      label="Override pms settings"
                    />
                    <v-row no-gutters>
                      <v-col cols="auto">
                        <v-text-field
                          v-if="
                            channel_settings[val] &&
                            channel_settings[val].override
                          "
                          v-model="channel_settings[val].default_commission"
                          label="Commission"
                          dense
                          outlined
                          hide-details
                        />
                      </v-col>
                      <v-col cols="auto" class="ml-3">
                        <v-select
                          v-if="
                            channel_settings[val] &&
                            channel_settings[val].override
                          "
                          v-model="channel_settings[val].out_of"
                          label="Out of"
                          dense
                          outlined
                          hide-details
                          :items="[
                            { text: 'Total', value: 'total' },
                            {
                              text: 'Fare Accommodation',
                              value: 'fare_accommodation',
                            },
                          ]"
                        />
                      </v-col>
                      <v-col
                        v-if="
                          channel_settings[val] &&
                          channel_settings[val].override
                        "
                        cols="12"
                        class="d-flex"
                      >
                        <v-col cols="5" class="px-0">
                          <v-text-field
                            v-model.number="
                              channel_settings[val].processing_fee_override
                            "
                            outlined
                            dense
                            label="Owner Processing %"
                          />
                        </v-col>
                      </v-col>
                    </v-row>
                    <v-switch
                      v-if="
                        channel_settings[val] && channel_settings[val].override
                      "
                      v-model="channel_settings[val].processing_fee"
                      label="Is merchant of record"
                    />
                    <v-switch
                      v-if="
                        channel_settings[val] && channel_settings[val].override
                      "
                      v-model="channel_settings[val].sales_tax"
                      label="Sales tax collected"
                    />
                    <v-switch
                      v-if="
                        channel_settings[val] && channel_settings[val].override
                      "
                      v-model="channel_settings[val].tourist_tax"
                      label="Tourist tax collected"
                    /> </v-sheet
                ></v-card-text>
              </v-card>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-row>
    </v-sheet>
  </v-sheet>
</template>

<script>
import { mapState } from 'vuex'
import isEmpty from 'lodash/fp/isEmpty'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import DatePicker from 'components/form-fields/date-picker'

import {
  NET_INCOME_FORMULAS,
  OWNER_REVENUE_FORMULAS,
  MANAGEMENT_COMMISSION_FORMULAS,
  OWNERS_INCOME_FORMULAS,
} from '@/consts'
import Common_functions from 'components/mixins/common_functions'

export default {
  name: 'ListingCommissions',
  props: ['listing'],
  components: { DatePicker },
  mixins: [PermissionsMixin, Common_functions],
  data() {
    return {
      NET_INCOME_FORMULAS,
      OWNER_REVENUE_FORMULAS,
      MANAGEMENT_COMMISSION_FORMULAS,
      OWNERS_INCOME_FORMULAS,
      channel_settings: this.listing.channel_settings,
      cutoffDate: this.listing.cutoff_date,
      netIncomeFormula: this.listing.net_income_formula,
      ownerRevenueFormula: this.listing.owner_revenue_formula,
      managementCommissionFormula: this.listing.management_commission_formula,
      managementCommissionAbove: this.listing.management_commission_above,
      ownerIncomeFormula: this.listing.owners_income_formula,
      cleaningFee: this.listing.actual_cleaning,
      ownerCleaning: this.listing.owner_cleaning,
      channels: [],
      disabledSave: true,
      dialog: false,
      commissionRecalcFrom: this.$moment()
        .utc()
        .startOf('month')
        .format('YYYY-MM-DD'),
    }
  },
  computed: {
    ...mapState(['settings']),
    zone() {
      return this.listing.zone || {}
    },
    managementCommissionAboveHint() {
      if (this.isHotel) {
        return "Commission applies to all hotel units. If empty, it's based on unit commission."
      } else if (this.isDisabledManagementCommissionAbove) {
        return `Management commission is set at ${this.parentManagementCommissionAbove}, inherited from parent settings. To change, adjust the parent commission.`
      } else {
        return null
      }
    },
    parentManagementCommissionAbove() {
      return this.listing.parent_management_commission_above
    },
    isDisabledManagementCommissionAbove() {
      return (
        this.isUnit &&
        this.parentManagementCommissionAbove !== null &&
        this.parentManagementCommissionAbove >= 0
      )
    },
    isHotel() {
      return this.listing.listing_type === 'hotel'
    },
    isUnit() {
      return !!this.listing.hotel_id
    },
  },
  async mounted() {
    await this.$store.dispatch('getSettings')
    this.channels = Object.keys(this.settings.channels || {})
    this.channel_settings = this.channels.reduce((acc, val) => {
      acc[val] = this.channel_settings[val] || {}
      return acc
    }, {})
  },
  methods: {
    async updateListing() {
      await this.$store.dispatch('listings/updateListing', {
        id: this.$route.params.id,
        payload: {
          channel_settings: this.channel_settings,
          net_income_formula: this.netIncomeFormula,
          cutoff_date: this.cutoffDate,
          management_commission_formula: this.managementCommissionFormula,
          owner_revenue_formula: this.ownerRevenueFormula,
          management_commission_above: this.managementCommissionAbove,
          owners_income_formula: this.ownerIncomeFormula,
          owner_cleaning: this.ownerCleaning,
          actual_cleaning: this.cleaningFee,
          commission_recalc_from: this.commissionRecalcFrom,
        },
      })
      this.disabledSave = true
      this.dialog = false
    },
  },
  watch: {
    channel_settings: {
      deep: true,
      handler(val, old) {
        if (!isEmpty(old)) {
          this.disabledSave = false
        }
      },
    },
  },
}
</script>

<style scoped>
::v-deep .v-messages__message {
  color: darkblue;
  font-weight: 500;
}
::v-deep .v-select__selection {
  font-size: 14px;
}
.input-container {
  border: 1px var(--v-light400-darken3) solid;
  border-radius: 4px;
  padding: 10px;
}
</style>
