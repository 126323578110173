import axios from '@/axios/config'

export default {
  namespaced: true,
  state: {
    webviewUrl: null,
    listingIotDevices: [],
    allIotDevices: [],
    publishableKey: null,
    seamComponentUuid: null,
  },
  mutations: {
    updateWebviewUrl(state, payload) {
      state.webviewUrl = payload
    },
    updateListingIotDevices(state, payload) {
      state.listingIotDevices = payload
    },
    updateIotDevices(state, payload) {
      state.allIotDevices = payload
    },
    updatePublishableKey(state, payload) {
      state.publishableKey = payload
    },
    updateSeamComponentUuid(state, payload) {
      state.seamComponentUuid = payload
    },
  },
  actions: {
    async getConnectWebview({ commit }, { listing_id }) {
      const { data } = await axios.get(`/api/seam/connect/new`, {
        params: { listing_id },
      })
      const url = data?.payload?.data?.webview?.url
      commit('updateWebviewUrl', url)
    },
    async getListingDevices({ commit }, { listing_id }) {
      const { data } = await axios.get(
        `/api/listings/${listing_id}/iot-devices`
      )
      const devices = data?.payload?.data
      commit('updateListingIotDevices', devices)
    },
    async getAllDevices({ commit }) {
      const { data } = await axios.get(`/api/iot/listing-devices`)
      const devices = data?.payload?.data?.devices
      commit('updateIotDevices', devices)
    },
    async updateDevice(
      { commit, dispatch },
      { device_id, listing_id, entry_point }
    ) {
      const payload = { device_id }

      if (listing_id !== null && listing_id !== undefined) {
        payload.listing_id = listing_id
      }

      if (entry_point !== null && entry_point !== undefined) {
        payload.entry_point = entry_point
      }

      await axios.patch(`/api/iot/listing-devices`, payload)
      await dispatch('getAllDevices')
    },
    async getPublishableKey({ commit }) {
      const { data } = await axios.get(`/api/iot/seam-keys`)
      commit('updatePublishableKey', data?.payload?.data?.publishable_key)
      commit('updateSeamComponentUuid', data?.payload?.data?.uuid)
    },
  },
}
