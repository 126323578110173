<template>
  <div class="grey lighten-4">
    <v-progress-linear v-show="conversationsLoading" indeterminate />
    <conversation-drawer mode="cs" @update-conversations="refreshCS" />
    <v-row v-if="stats && !isMobile">
      <stat-card
        label="Guests chats"
        :value="`${stats.total_chats.toLocaleString() || '-'}`"
        tooltip="Amount of guests we talked with in last day"
        :secondary="`Prev. ${stats.prev_total_chats.toLocaleString() || '-'}`"
      />
      <stat-card
        label="Closed Tickets"
        :value="`${stats.total_tickets_resolved.toLocaleString() || '-'}`"
        tooltip="Closed tickets opened last day"
        :secondary="`Prev. ${
          stats.prev_total_tickets_resolved.toLocaleString() || '-'
        }`"
      />
      <stat-card
        label="Response SLA"
        :value="`${toPercent(stats.response_time) || '-'}`"
        tooltip="Response percent under 6 min"
        :secondary="`Prev. ${toPercent(stats.prev_response_time) || '-'}`"
      />
    </v-row>
    <v-tabs
      v-if="stats"
      v-model="tab"
      grow
      center-active
      slider-color="grey"
      :class="{ 'mt-0 mt-sm-2': true, 'hide-arrows': isMobile }"
      background-color="teal lighten-5"
      @change="tabChange"
    >
      <dashboard-tab
        href="#unanswered"
        label="Unanswered"
        :value="stats.unanswered_count"
      />
      <dashboard-tab
        v-if="showModule('ai')"
        href="#ai-escalations"
        :label="isMobile ? 'AI' : 'AI Escalations'"
        :value="stats.ai_escalations_count"
      />
      <dashboard-tab
        href="#active_leads"
        :label="isMobile ? 'Open' : 'Open Tickets'"
        :value="stats.active_leads_count"
      />
      <dashboard-tab
        href="#currently_staying_angry"
        :label="isMobile ? 'Angry' : 'Staying & Angry'"
        :value="stats.currently_staying_angry"
      />
      <dashboard-tab
        href="#follow_ups"
        :label="isMobile ? 'FollowUp' : 'Follow Ups'"
        :value="stats.follow_ups_count"
      />
      <dashboard-tab
        href="#my_tickets"
        :label="isMobile ? 'Mine' : 'My Tickets'"
        :value="stats.my_tickets_count"
      />
      <dashboard-tab
        href="#overbookings"
        label="Overbookings"
        :value="stats.overbookings_count"
      />
      <dashboard-tab href="#all" label="All" />
    </v-tabs>
    <div>
      <div>
        <v-expansion-panels v-if="isMobile" accordion flat>
          <v-expansion-panel>
            <v-expansion-panel-header>Filters</v-expansion-panel-header>
            <v-expansion-panel-content class="px-3">
              <conversation-filters
                :filters="filters"
                @filter-change="updateFilter"
              />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <conversation-filters v-else @filter-change="updateFilter" />
      </div>
      <div v-if="isMobile && conversations">
        <v-divider />
        <conversations-mobile
          :items="conversations"
          :pagination="pagination"
          @paginate="paginate"
        />
      </div>
      <conversations-table
        v-else
        mode="cs"
        :headers="headers"
        :items="conversations"
        :options="pagination"
        @update-conversations="refreshCS"
        @row-click="rowClick"
        @paginate="paginate"
      />
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex'
import dashboardTab from 'components/common/dashboard-tab.vue'
import formattersMixin from 'components/mixins/formatters-mixin'
import colorsMixin from 'components/mixins/colors-mixin'
import ConversationsTable from 'components/crm/conversations-table.vue'
import ConversationFilters from './conversation-filters.vue'
import ConversationDrawer from './conversation-drawer.vue'
import StatCard from '../common/stat-card'
import DeviceMixin from 'components/mixins/device-mixin'
import ConversationsMobile from 'components/crm/conversations-mobile.vue'
import CommonFunctions from 'components/mixins/common_functions'

export default {
  components: {
    StatCard,
    dashboardTab,
    ConversationsTable,
    ConversationFilters,
    ConversationDrawer,
    ConversationsMobile,
  },
  mixins: [formattersMixin, colorsMixin, DeviceMixin, CommonFunctions],
  data() {
    return {
      conversations: null,
      tab: this.$route.query.conversation ? 'all' : 'unanswered',
      stats: null,
      conversationsLoading: false,
      pagination: { page: 1 },
      filters: {},
      drawer: false,
      conversationId: null,
      headers: [
        {
          text: '',
          value: 'reservation_status',
          sortable: false,
        },
        {
          text: 'Fun Level',
          value: 'fun_level',
          sortable: false,
        },
        {
          text: 'Guest',
          value: 'guest_full_name',
          sortable: false,
        },
        {
          text: 'Dates',
          value: 'extra_data.reservation_check_in',
          sortable: false,
        },
        {
          text: 'Property',
          value: 'extra_data.listing_nickname',
          sortable: false,
        },
        {
          text: 'Price',
          value: 'reservation_price',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Last Msg',
          value: 'last_message_from_guest',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Assignee',
          value: 'assigned_user_id',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Source',
          value: 'extra_data.reservation_source',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Action',
          value: 'actions',
          align: 'center',
          sortable: false,
        },
      ],
      items: [{}],
    }
  },
  mounted() {
    this.getCS({
      [this.tab]: true,
      conversation_id: this.$route.query.conversation,
    })
    this.intervalStats = setInterval(
      () => this.getCS({ stats_only: true }),
      1000 * 30
    )
    this.intervalList = setInterval(() => this.refreshCS(), 1000 * 60)
  },
  beforeDestroy() {
    clearInterval(this.intervalStats)
    clearInterval(this.intervalList)
  },
  methods: {
    ...mapActions(['getCsInfo']),
    refreshCS() {
      this.getCS({
        [this.tab]: true,
        ...this.filters,
        page: this.pagination.page,
        hideLoader: true,
      })
    },
    async getCS(filters = {}) {
      if (!filters.stats_only && !filters.hideLoader)
        this.conversationsLoading = true
      const { data } = await this.getCsInfo({ ...filters })
      this.stats = data.stats

      if (filters.stats_only) return
      this.conversations = data.conversations
      this.pagination = {
        page: +data.pagination.page,
        itemsPerPage: data.pagination.per_page,
        totalItems: data.pagination.conversations_count,
      }
      this.conversationsLoading = false
    },
    tabChange(tab) {
      this.filters = {}
      this.getCS({ [tab]: true })
    },
    updateFilter(field, val) {
      this.filters = { ...this.filters, [field]: val }
      this.getCS({ [this.tab]: true, ...this.filters })
    },
    paginate(page) {
      if (page === this.pagination.page) return
      this.getCS({ [this.tab]: true, ...this.filters, page })
    },
    updateConversations() {
      this.getCS({ [this.tab]: true, ...this.filters, page: 1 })
    },
    rowClick({ guesty_id }) {
      this.$router.push({ query: { conversation: guesty_id } })
    },
  },
}
</script>
<style scoped>
::v-deep .v-data-footer {
  justify-content: end;
}
::v-deep .v-tabs-bar {
  height: 100%;
}
::v-deep .hide-arrows .v-slide-group__prev {
  display: none !important;
}
::v-deep .v-expansion-panel-content__wrap {
  padding: 0;
}
</style>
