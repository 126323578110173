<template>
  <div class="class ai-train-modal ms-1">
    <v-menu
      v-model="menu"
      :close-on-click="false"
      :close-on-content-click="false"
      left
      absolute
      content-class="elevation-0 min-height-600"
    >
      <template #activator="{ on, attrs }">
        <v-btn outlined x-small color="blue-grey" v-bind="attrs" v-on="on"
          ><v-icon class="me-1" x-small color="purple darken-4"
            >mdi-creation</v-icon
          >Train</v-btn
        >
      </template>
      <v-card
        outlined
        elevation="1"
        class="pa-4 ai-border pt-0"
        style="width: 800px"
      >
        <div class="d-flex justify-end ma-2 mr-n2">
          <v-btn icon @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        <v-form
          v-if="trainData"
          ref="trainDataRef"
          @submit.prevent="submitTrainData"
        >
          <v-card-title class="text-h6 pt-0 justify-center"
            >Create {{ TRAIN_TYPES[trainType] }}</v-card-title
          >
          <v-card-text>
            <v-row class="">
              <multi-property-picker
                v-if="trainType === 'listing_item'"
                v-model="listingIds"
                class="mx-3"
              />
              <v-col cols="12">
                <v-text-field
                  v-model="trainData.topic"
                  label="Topic"
                  outlined
                  dense
                  hide-details
                  :rules="[required]"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="trainData.subject"
                  label="Subject"
                  outlined
                  dense
                  hide-details
                  :rules="[required]"
                ></v-text-field>
              </v-col>
              <v-col cols="12" class="mt-8">
                <v-textarea
                  v-model="trainData.description"
                  label="Description"
                  outlined
                  dense
                  :rules="[required]"
                ></v-textarea>
              </v-col>
            </v-row>
            <div class="text-center">
              <v-btn
                depressed
                color="success me-1"
                class="white--text"
                type="submit"
                ><v-icon class="me-2" small color="white"
                  >mdi-checkbox-marked-circle-outline
                </v-icon>
                Create {{ TRAIN_TYPES[trainType] }}</v-btn
              >
            </div>
          </v-card-text>
        </v-form>
        <v-form v-else ref="generatInfoRef" @submit.prevent="generateInfo">
          <v-textarea
            v-if="isByAi"
            v-model="badAnswer"
            label="Ai Generated Message"
            rows="8"
            outlined
            dense
            readonly
            :rules="[required]"
          >
          </v-textarea>
          <v-textarea
            v-model="goodAnswer"
            rows="8"
            label="Suggested Message"
            outlined
            dense
            :rules="[required]"
          >
          </v-textarea>
          <div class="d-flex align-center mb-4">
            <div class="me-2 mt-1 font-weight-medium">Generate:</div>
            <v-radio-group v-model="trainType" class="ma-0" row hide-details>
              <v-radio label="AI Policy" value="policy"></v-radio>
              <v-radio label="Info Item" value="listing_item"></v-radio>
            </v-radio-group>
          </div>
          <div class="d-flex justify-center">
            <v-btn
              depressed
              :loading="buttonLoading"
              color="purple lighten-2 me-1"
              class="white--text"
              @click="generateInfo"
              ><v-icon class="me-2" small color="white">mdi-creation </v-icon>
              Generate</v-btn
            >
          </div>
        </v-form>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import FormRules from 'components/mixins/form-rules-mixin'
import multiPropertyPicker from 'components/multi-property-picker'

const DEPARTMENTS = {
  'AI Sales': 'sales',
  'AI CS': 'cs',
  'AI Operations': 'operations',
}

const TRAIN_TYPES = {
  policy: 'AI Policy',
  listing_item: 'Info Item',
}

export default {
  components: { multiPropertyPicker },
  name: 'AiTrainModal',
  props: ['msg', 'listingId'],
  mixins: [FormRules],
  data() {
    return {
      TRAIN_TYPES,
      menu: false,
      listingIds: [this.listingId],
      buttonLoading: false,
      goodAnswer: '',
      badAnswer: this.msg.body,
      trainType: 'policy',
      trainData: null,
    }
  },
  computed: {
    isByAi() {
      return Object.keys(DEPARTMENTS).includes(this.msg.rep_name)
    },
  },
  mounted() {
    if (!this.isByAi) this.goodAnswer = this.msg.body
  },
  methods: {
    close() {
      this.menu = false
      this.trainData = null
      this.buttonLoading = false
    },
    generateInfo() {
      if (!this.$refs.generatInfoRef.validate()) return
      this.buttonLoading = true
      this.$store
        .dispatch('conversations/generateInfo', {
          message_id: this.msg.id,
          good_answer: this.goodAnswer,
          bad_answer: this.badAnswer,
          train_type: this.trainType,
        })
        .then(res => {
          this.trainData = res.data.train_data
          this.buttonLoading = false
        })
    },
    async submitTrainData() {
      if (!this.$refs.trainDataRef.validate()) return
      if (this.trainType === 'policy') this.createPolicy()
      else this.createInfoItem()
    },
    createInfoItem() {
      this.buttonLoading = true
      this.$store
        .dispatch('conversations/createInfoItem', {
          subject: this.trainData.subject,
          topic: this.trainData.topic,
          description: this.trainData.description,
          listing_ids: this.listingIds,
        })
        .then(() => {
          this.close()
        })
    },
    createPolicy() {
      this.buttonLoading = true
      this.$store
        .dispatch('conversations/createPolicy', {
          listing_ids: [this.msg.listing_id],
          subject: this.trainData.subject,
          topic: this.trainData.topic,
          description: this.trainData.description,
          department: this.msg.department,
        })
        .then(() => {
          this.close()
        })
    },
  },
}
</script>

<style scoped>
.ai-train-modal {
  transform: scale(0.9);
}
.gap-15 {
  grid-gap: 15px;
}

:deep() textarea {
  white-space: pre-line;
}

.ai-border {
  border: 2px solid;
  border-image-source: linear-gradient(120deg, purple, pink);
  border-image-slice: 1;
}
.min-height-600 {
  min-height: 600;
}
</style>
