<template>
  <v-card class="pa-5 overflow-auto" style="background: white">
    <div class="justify-space-between" style="display: flex">
      <div>{{ `${currentUser.name} - ${currentUser.email}` }}</div>
      <button
        class="v-icon notranslate v-icon--link material-icons theme--light"
        @click="close"
      >
        close
      </button>
    </div>
    <v-form @submit.prevent="save">
      <v-row class="mt-3">
        <v-col cols="12">
          <v-select
            v-model="currentUser.role"
            :disabled="!isAdmin"
            :items="rolesItems"
            clearable
            label="Role"
            dense
            outlined
            hide-details
          />
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model="currentUser.first_name"
            label="First"
            dense
            outlined
            hide-details
          />
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model="currentUser.last_name"
            label="Last"
            dense
            outlined
            hide-details
          />
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model="currentUser.email"
            outlined
            dense
            :rules="emailRules"
            label="E-mail"
            hide-details
            required
          />
        </v-col>
        <v-col v-if="!currentUserInvestor" cols="12">
          <v-text-field
            v-model="currentUser.company_name"
            label="Company name"
            dense
            outlined
            hide-details
          />
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            v-model="currentUser.phone"
            label="Phone number"
            dense
            hide-details
            outlined
            :rules="[invalidPhone]"
            @change="validatePhone"
          >
            <template #append>
              <v-progress-circular
                v-show="validating"
                size="15"
                width="1"
                indeterminate
              />
            </template>
          </v-text-field>
        </v-col>
        <v-col v-if="!currentUserInvestor" cols="12" sm="6">
          <v-select
            v-model="currentUser.zone_id"
            :items="listingZones"
            clearable
            label="Zone"
            dense
            outlined
            hide-details
          />
        </v-col>
        <v-col v-if="!currentUserInvestor" cols="12" sm="6">
          <v-select
            v-model="currentUser.department"
            :items="divisions"
            clearable
            label="Department"
            dense
            outlined
            hide-details
          />
        </v-col>
        <v-col v-if="!currentUserInvestor" cols="12" sm="6">
          <v-select
            v-model="currentUser.sub_department"
            :items="subDepartments[currentUser.department]"
            clearable
            label="Sub department"
            dense
            outlined
            hide-details
          />
        </v-col>
        <v-col v-if="isDesignedVr && !currentUserInvestor" cols="12" sm="6">
          <v-select
            v-model="currentUser.sub_role"
            :items="subRoles[currentUser.department] || defaultSubRoles"
            label="User Sub Role"
            dense
            outlined
            hide-details
          />
        </v-col>
        <v-col v-if="isDesignedVr && !currentUserInvestor" cols="12" sm="6">
          <v-select
            v-model="currentUser.skills"
            :items="skills[currentUser.department]"
            label="Skills"
            multiple
            dense
            outlined
            hide-details
          />
        </v-col>
        <v-col v-if="!currentUserInvestor" cols="12" sm="6">
          <v-select
            v-model="currentUser.region"
            label="Region"
            dense
            hide-details
            clearable
            outlined
            :items="listingRegions"
          />
        </v-col>
        <v-col v-if="!currentUserInvestor" cols="12" sm="6">
          <contractor-select
            label="Reports to..."
            :value.sync="currentUser.reports_to_id"
            :allow-multiple="false"
            :hide-details="true"
          />
        </v-col>
        <v-col v-if="isDesignedVr && !currentUserInvestor" cols="12">
          <v-text-field
            v-model="currentUser.field_of_expertise"
            label="Field of expertise"
            dense
            outlined
            hide-details
          />
        </v-col>
        <v-col v-if="isDesignedVr && !currentUserInvestor" cols="12">
          <v-text-field
            v-model="currentUser.aloware_user_id"
            label="Aloware ID"
            type="number"
            dense
            outlined
            hide-details
          />
        </v-col>

        <v-col v-if="!currentUserInvestor" cols="12">
          <v-combobox
            v-model="currentUser.team_name"
            label="Team"
            :items="teamsItems"
            item-text="name"
            item-value="name"
            clearable
            class="listing-select"
            dense
            outlined
            hide-details
          />
        </v-col>
        <v-col v-if="hasAbility('edit-bank-accounts')" cols="12">
          <v-combobox
            v-model="currentUser.bank_accounts_numbers"
            label="Bank accounts numbers"
            multiple
            small-chips
            deletable-chips
            outlined
            dense
            hide-details
          />
        </v-col>
        <v-col v-if="isDesignedVr" cols="12" sm="12">
          <v-text-field
            v-if="
              currentUser.role === 'cleaning-supervisor' ||
              currentUser.department === 'Cleaning'
            "
            v-model="currentUser.slack_channel"
            label="Slack channel"
            dense
            outlined
            hide-details
          />
        </v-col>
        <v-col v-if="!currentUserInvestor" cols="12">
          <v-text-field
            v-model="currentUser.bank_accounts_number"
            label="Bank account"
            dense
            outlined
            hide-details
          />
        </v-col>
        <v-col v-if="isDesignedVr" cols="12">
          <v-text-field
            v-model="currentUser.personal_address"
            label="Personal address"
            dense
            outlined
            hide-details
          />
        </v-col>
        <v-col v-if="!currentUserInvestor" cols="12">
          <v-switch
            v-model="currentUser.on_payrole"
            label="On Payrole"
            dense
            outlined
            hide-details
          />
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            v-model="currentUser.hourly_pay"
            type="number"
            label="Owner hourly charge"
            dense
            outlined
            hide-details
          />
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            v-model="currentUser.dvr_hourly_chrage"
            type="number"
            label="Company hourly cost"
            dense
            outlined
            hide-details
          />
        </v-col>
        <v-col
          v-if="
            isDesignedVr && currentUser.on_payrole && hasAbility('salaries')
          "
          cols="12"
          sm="6"
        >
          <v-text-field
            v-model="currentUser.avg_monthly_salary"
            type="number"
            label="Average Monthly Salary"
            dense
            outlined
            hide-details
          />
        </v-col>
        <v-col
          v-if="
            isDesignedVr && currentUser.on_payrole && hasAbility('salaries')
          "
          cols="12"
          sm="6"
        >
          <v-text-field
            v-model="currentUser.avg_monthly_cost"
            type="number"
            label="Average Monthly Cost"
            dense
            outlined
            hide-details
          />
        </v-col>
        <v-col v-if="!currentUserInvestor" cols="12">
          <v-text-field
            v-model="currentUser.ach_recipient_name"
            label="ACH Recipient Name"
            dense
            outlined
            hide-details
          />
        </v-col>
        <v-col v-if="!currentUserInvestor" cols="12" sm="6">
          <v-text-field
            v-model="currentUser.ach_routing_number"
            label="ACH Routing Number"
            dense
            outlined
            hide-details
          />
        </v-col>
        <v-col v-if="!currentUserInvestor" cols="12" sm="6">
          <v-text-field
            v-model="currentUser.ach_account_number"
            label="ACH Account Number"
            dense
            outlined
            hide-details
          />
        </v-col>
        <v-col v-if="!currentUserInvestor" cols="12">
          <v-select
            v-model="currentUser.groups"
            multiple
            outlined
            item-text="name"
            item-value="id"
            dense
            label="User Groups"
            :items="groups"
          />
        </v-col>
        <v-col v-if="isAdmin" cols="12">
          <filters-select
            :value.sync="currentUser.abilities"
            label="Abilities"
            outlined
            dense
            clearable
            multiple
            :items="currentUserInvestor ? investorAbilities : abilitiesItems"
            small-chips
          />
        </v-col>
        <v-col v-if="isAdmin && config.has_xero" cols="12">
          <v-checkbox
            v-model="currentUser.is_default_xero_receivable"
            label="Default Xero receivable"
            class="checkbox-txt"
          ></v-checkbox>
        </v-col>
        <v-col v-if="currentUserInvestor" cols="12">
          <multi-property-picker
            v-model="currentUser.attached_listings"
            :hide-btns="true"
          />
        </v-col>
        <v-col v-if="!currentUserInvestor" cols="12">
          <div>Profile Image</div>
          <image-field :value.sync="currentUser.picture" />
        </v-col>
      </v-row>
      <user-notifications :user="currentUser" />
      <div v-if="isDesignedVr && !currentUserInvestor">
        <div class="text-body-2">Void check images</div>
        <gallery
          :images="currentUser.void_check_image"
          :cols="6"
          folder="users"
          thumbnails
          uploadable
          readonly
          :on-change="addVoidCheckImages"
        />
        <div class="text-body-2">W9 images</div>
        <gallery
          :images="currentUser.w9_image"
          :cols="6"
          folder="users"
          thumbnails
          uploadable
          readonly
          :on-change="addW9Images"
        />
        <div class="text-body-2">Insurance proof images</div>
        <gallery
          :images="currentUser.insurance_proof_image"
          :cols="6"
          thumbnails
          folder="users"
          uploadable
          readonly
          :on-change="addInsuranceProofImages"
        />
        <div class="text-body-2">Service Agreement</div>
        <gallery
          folder="users"
          :images="currentUser.service_agreement"
          :cols="6"
          thumbnails
          uploadable
          readonly
          :on-change="addServiceAgreement"
        />
      </div>
      <v-card-actions class="justify-end">
        <v-btn color="info" outlined small @click="close">cancel</v-btn>
        <v-btn color="info" type="submit" small>save</v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import { mapState } from 'vuex'
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import RolesMixin from 'components/mixins/roles-mixin'
import MultiPropertyPicker from 'components/multi-property-picker'
import ContractorSelect from 'components/contractor-select'
import Gallery from 'components/common/gallery'
import ImageField from 'components/form-fields/image-field'
import FormRules from 'components/mixins/form-rules-mixin'
import UserNotifications from 'components/user/user-notifications.vue'
import FiltersSelect from 'components/form-fields/filters-select.vue'

export default {
  mixins: [FormRules, CommonFunctions, PermissionsMixin, RolesMixin],
  props: ['user'],
  data: function () {
    return {
      dialog: false,
      editDialog: false,
      currentUser: {
        preferences: {},
      },
      selectedListing: null,
      invalidPhone: true,
      validating: false,
    }
  },
  mounted() {
    this.currentUser = Object.assign({}, this.user)
  },
  computed: {
    ...mapState('userGroups', ['groups']),
    ...mapState('users', ['teams']),
    ...mapState('regions', ['regions']),
    ...mapState('zones', ['zones']),
    rolesItems() {
      if (this.hasAbility('super-admin')) {
        return [...this.roles, { text: 'Admin', value: 'admin' }]
      }
      return this.roles
    },
    currentUserInvestor() {
      return this.currentUser.role === 'investor'
    },
    abilitiesItems() {
      if (this.hasAbility('super-admin')) {
        return this.abilities.concat(this.adminAbilities)
      }
      return this.abilities
    },
    teamsItems() {
      return Object.values(this.teams).map(t => t.name)
    },
    listingRegions() {
      return this.regions.map(r => ({
        text: r.name,
        value: r.id + '',
      }))
    },
    listingZones() {
      const availableZones = this.zones.map(r => ({
        text: r.name,
        value: r.id,
      }))
      return [{ text: 'Global', value: null }].concat(availableZones)
    },
    config() {
      return this.$store.state.app.configuration
    },
  },
  methods: {
    async validatePhone(value) {
      if (!value) {
        this.invalidPhone = true
        return
      }
      this.validating = true
      const is_valid = await this.$store.dispatch('users/validatePhone', value)
      if (is_valid) {
        this.invalidPhone = true
      } else {
        this.invalidPhone = 'Invalid phone format'
      }
      this.validating = false
    },
    addVoidCheckImages(images) {
      this.currentUser.void_check_image = images
    },
    addW9Images(images) {
      this.currentUser.w9_image = images
    },
    addInsuranceProofImages(images) {
      this.currentUser.insurance_proof_image = images
    },
    addServiceAgreement(images) {
      this.currentUser.service_agreement = images
    },
    save() {
      this.$store.dispatch('users/updateUserNew', this.currentUser)
      this.close()
    },
    close() {
      this.$store.commit('hideModal')
    },
  },
  components: {
    UserNotifications,
    ImageField,
    MultiPropertyPicker,
    ContractorSelect,
    Gallery,
    FiltersSelect,
  },
}
</script>
