<template>
  <v-flex v-if="currentPricing" class="pt-3">
    <v-layout wrap class="align-center">
      <v-flex xs5>
        <v-select
          v-model="currentPricing.pricing_strategy_id"
          clearable
          label="Pricing Strategies"
          :items="strategyItems"
          hide-details
          outlined
          dense
          @change="changeStrategy($event)"
        />
      </v-flex>
      <v-flex xs6 pl-3>
        <SaveStrategyModal
          :filters="currentPricing"
          :strategy="currentPricing.pricing_strategy_id"
          @save="save"
        />
        <v-btn
          v-if="enableApply"
          outlined
          mid
          class="flex ml-3"
          color="success"
          @click="save()"
        >
          Apply Strategy
        </v-btn>
        <v-btn
          v-if="enableOverride"
          depressed
          outlined
          mid
          class="flex ml-3"
          color="error"
          @click="overrideStrategy()"
        >
          Override Strategy
        </v-btn>
      </v-flex>
    </v-layout>
    <v-layout v-if="isMultiUnit" wrap class="mt-1 mb-2 align-center">
      <v-flex xs1>
        <v-tooltip top mb-1>
          <template #activator="{ on }">
            <v-icon small size="xs" class="icon alt purple-icon" v-on="on"
              >fas fa-home</v-icon
            ></template
          ><br />
          <v-layout wrap style="max-width: 250px">
            <v-flex xs12 class="bolder cyan-icon"
              >The associated units are:</v-flex
            >
            <v-flex
              v-for="(
                listingPricing, index
              ) in currentPricing.multi_unit_pricings"
              :key="index"
              xs12
              >{{ listingPricing.nickname }} {{ listingPricing.id }}</v-flex
            >
          </v-layout>
        </v-tooltip>
      </v-flex>
      <v-flex xs11>
        <v-switch
          v-model="currentPricing.enable_multi_unit_pricing"
          :class="
            currentPricing.enable_multi_unit_pricing
              ? 'cyan-icon xs3'
              : 'grey-text xs3'
          "
          :label="
            'Multi unit pricing is : ' +
            (currentPricing.enable_multi_unit_pricing ? 'ON' : 'OFF') +
            ' (' +
            currentPricing.multi_unit_pricings.length +
            ' units)'
          "
          @change="save()"
        >
        </v-switch>
      </v-flex>
    </v-layout>
    <v-layout v-if="!isMultiUnit" wrap>
      <v-flex xs1>
        <v-tooltip
          v-if="
            currentPricing.group_pricings_display &&
            currentPricing.group_pricings_display.length > 0
          "
          top
          mb-1
        >
          <template #activator="{ on }">
            <v-icon small size="xs" class="icon alt purple-icon" v-on="on"
              >fas fa-home</v-icon
            ></template
          ><br />
          <v-layout wrap style="max-width: 250px">
            <v-flex xs12 class="bolder cyan-icon"
              >The associated group is:</v-flex
            >
            <v-flex
              v-for="(
                listingPricing, index
              ) in currentPricing.group_pricings_display"
              :key="index"
              xs12
              >{{ listingPricing.nickname }} -
              <span
                :class="
                  listingPricing.enable_group_pricing &&
                  listingPricing.activated
                    ? 'green-health'
                    : 'red-health'
                "
                >{{ listingPricing.enable_group_pricing ? 'ON' : 'OFF' }}</span
              ></v-flex
            >
          </v-layout>
        </v-tooltip>
      </v-flex>
      <v-flex xs12>
        <v-switch
          v-model="currentPricing.enable_group_pricing"
          :class="
            currentPricing.enable_group_pricing
              ? 'cyan-icon xs3'
              : 'grey-text xs3'
          "
          :label="
            'Group pricing is: ' +
            (currentPricing.enable_group_pricing ? 'ON' : 'OFF')
          "
          @change="save()"
        >
        </v-switch>
      </v-flex>
    </v-layout>
    <v-row class="align-center">
      <v-col cols="3">
        <v-switch
          v-model="currentPricing.scramble_prices"
          label="Auto Scramble prices"
          @change="save()"
        >
        </v-switch
      ></v-col>
      <v-col>
        <v-tooltip top>
          <template #activator="{ on }">
            <v-icon small v-on="on">info</v-icon>
          </template>
          <div>Will automatically increase/decrease by 1% daily change</div>
        </v-tooltip>
      </v-col>
    </v-row>

    <v-layout wrap>
      <v-flex xs11 class="comp-wrapper">
        <v-switch
          v-model="currentPricing.factors.min_days_enable"
          :class="
            currentPricing.enable_group_pricing
              ? 'cyan-icon xs3'
              : 'grey-text xs3'
          "
          :label="
            'Pricing control over min nights: ' +
            (currentPricing.factors.min_days_enable ? 'ON' : 'OFF')
          "
          @change="save()"
        >
        </v-switch>
        <div v-if="currentPricing.factors.min_days_enable">
          <v-row>
            <v-col>
              <v-text-field
                v-model="currentPricing.min_nights_weekend"
                label="Weekend min nights"
                type="number"
                @change="save()"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                v-model="currentPricing.min_nights_weekday"
                label="Weekday min nights"
                type="number"
                @change="save()"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row class="align-center">
            <v-col cols="3">
              <v-switch
                v-model="currentPricing.dynamic_nights_1_night"
                label="Enable 1 nights gap reservation fill"
                @change="save()"
              >
              </v-switch
            ></v-col>
            <v-col>
              <v-tooltip top>
                <template #activator="{ on }">
                  <v-icon small v-on="on">info</v-icon>
                </template>
                <div>
                  Will change min nights to 1 night incase only 1 night is
                  available
                </div>
              </v-tooltip>
            </v-col>
          </v-row>
          <v-row class="align-center">
            <v-col cols="3">
              <v-switch
                v-model="currentPricing.dynamic_nights_2_night"
                label="Enable 2 nights gap reservation fill"
                @change="save()"
              >
              </v-switch
            ></v-col>
            <v-col>
              <v-tooltip top>
                <template #activator="{ on }">
                  <v-icon small v-on="on">info</v-icon>
                </template>
                <div>
                  Will change min nights to 2 nights incase only 1 night is
                  available
                </div>
              </v-tooltip>
            </v-col>
          </v-row>
        </div>
      </v-flex>
    </v-layout>

    <v-layout
      v-if="
        currentPricing &&
        currentPricing.factors &&
        currentPricing.factors.seasonality
      "
      wrap
      mt-2
    >
      <v-flex xs12>
        <h4 class="grey-text">Month seasonailty</h4>
        <br />
      </v-flex>
      <v-flex xs1>
        <v-text-field
          v-model="currentPricing.factors.seasonality.January"
          label="Jan"
          required
          @change="sendPreview"
        ></v-text-field>
      </v-flex>
      <v-flex xs1>
        <v-text-field
          v-model="currentPricing.factors.seasonality.February"
          label="Feb"
          required
          @change="sendPreview"
        ></v-text-field>
      </v-flex>
      <v-flex xs1>
        <v-text-field
          v-model="currentPricing.factors.seasonality.March"
          label="Mar"
          required
          @change="sendPreview"
        ></v-text-field>
      </v-flex>
      <v-flex xs1>
        <v-text-field
          v-model="currentPricing.factors.seasonality.April"
          label="Apr"
          required
          @change="sendPreview"
        ></v-text-field>
      </v-flex>
      <v-flex xs1>
        <v-text-field
          v-model="currentPricing.factors.seasonality.May"
          label="May"
          required
          @change="sendPreview"
        ></v-text-field>
      </v-flex>
      <v-flex xs1>
        <v-text-field
          v-model="currentPricing.factors.seasonality.June"
          label="Jun"
          required
          @change="sendPreview"
        ></v-text-field>
      </v-flex>
      <v-flex xs1>
        <v-text-field
          v-model="currentPricing.factors.seasonality.July"
          label="Jul"
          required
          @change="sendPreview"
        ></v-text-field>
      </v-flex>
      <v-flex xs1>
        <v-text-field
          v-model="currentPricing.factors.seasonality.August"
          label="Aug"
          required
          @change="sendPreview"
        ></v-text-field>
      </v-flex>
      <v-flex xs1>
        <v-text-field
          v-model="currentPricing.factors.seasonality.September"
          label="Sep"
          required
          @change="sendPreview"
        ></v-text-field>
      </v-flex>
      <v-flex xs1>
        <v-text-field
          v-model="currentPricing.factors.seasonality.October"
          label="Oct"
          required
          @change="sendPreview"
        ></v-text-field>
      </v-flex>
      <v-flex xs1>
        <v-text-field
          v-model="currentPricing.factors.seasonality.November"
          label="Nov"
          required
          @change="sendPreview"
        ></v-text-field>
      </v-flex>
      <v-flex xs1>
        <v-text-field
          v-model="currentPricing.factors.seasonality.December"
          label="Dec"
          required
          @change="sendPreview"
        ></v-text-field>
      </v-flex>
    </v-layout>
    <v-layout wrap mt-2>
      <v-flex xs12>
        <h4 class="grey-text">Day of the week</h4>
        <br />
      </v-flex>
      <v-flex xs1>
        <v-text-field
          v-model="currentPricing.factors.day_of_week.Sunday"
          label="Sun"
          required
          @change="sendPreview"
        ></v-text-field>
      </v-flex>
      <v-flex xs1>
        <v-text-field
          v-model="currentPricing.factors.day_of_week.Monday"
          label="Mon"
          required
          @change="sendPreview"
        ></v-text-field>
      </v-flex>
      <v-flex xs1>
        <v-text-field
          v-model="currentPricing.factors.day_of_week.Tuesday"
          label="Tue"
          required
          @change="sendPreview"
        ></v-text-field>
      </v-flex>
      <v-flex xs1>
        <v-text-field
          v-model="currentPricing.factors.day_of_week.Wednesday"
          label="Wed"
          required
          @change="sendPreview"
        ></v-text-field>
      </v-flex>
      <v-flex xs1>
        <v-text-field
          v-model="currentPricing.factors.day_of_week.Thursday"
          label="Thu"
          required
          @change="sendPreview"
        ></v-text-field>
      </v-flex>
      <v-flex xs1>
        <v-text-field
          v-model="currentPricing.factors.day_of_week.Friday"
          label="Fri"
          required
          @change="sendPreview"
        ></v-text-field>
      </v-flex>
      <v-flex xs1>
        <v-text-field
          v-model="currentPricing.factors.day_of_week.Saturday"
          label="Sat"
          required
          @change="sendPreview"
        ></v-text-field>
      </v-flex>
    </v-layout>
    <v-layout wrap>
      <v-flex xs12>
        <h4 class="grey-text">
          Month Occupancy (take effect on the next 10-240 days)
        </h4>
        <br />
      </v-flex>
      <v-flex xs2>
        <v-text-field
          v-model="currentPricing.factors.month_occupancy['0-0.2']"
          label="0%-20%"
          required
          @change="sendPreview"
        ></v-text-field>
      </v-flex>
      <v-flex xs2>
        <v-text-field
          v-model="currentPricing.factors.month_occupancy['0.2-0.4']"
          label="20%-40%"
          required
          @change="sendPreview"
        ></v-text-field>
      </v-flex>
      <v-flex xs2>
        <v-text-field
          v-model="currentPricing.factors.month_occupancy['0.4-0.6']"
          label="40%-60%"
          required
          @change="sendPreview"
        ></v-text-field>
      </v-flex>
      <v-flex xs2>
        <v-text-field
          v-model="currentPricing.factors.month_occupancy['0.6-0.8']"
          label="60%-80%"
          required
          @change="sendPreview"
        ></v-text-field>
      </v-flex>
      <v-flex xs2>
        <v-text-field
          v-model="currentPricing.factors.month_occupancy['0.8-1']"
          label="80%-100%"
          required
          @change="sendPreview"
        ></v-text-field>
      </v-flex>
    </v-layout>
    <v-layout wrap>
      <v-flex xs12>
        <h4 class="grey-text">Time until booking</h4>
        <br />
      </v-flex>
      <v-flex xs2>
        <v-text-field
          v-model="currentPricing.factors.time_until_booking['0-2']"
          label="Next 3 days"
          required
          @change="sendPreview"
        ></v-text-field>
      </v-flex>
      <v-flex xs2>
        <v-text-field
          v-model="currentPricing.factors.time_until_booking['2-6']"
          label="3-7 days"
          required
          @change="sendPreview"
        ></v-text-field>
      </v-flex>
      <v-flex xs2>
        <v-text-field
          v-model="currentPricing.factors.time_until_booking['6-13']"
          label="7-14 days"
          required
          @change="sendPreview"
        ></v-text-field>
      </v-flex>
      <v-flex xs2>
        <v-text-field
          v-model="currentPricing.factors.time_until_booking['13-20']"
          label="14-21 days"
          required
          @change="sendPreview"
        ></v-text-field>
      </v-flex>
      <v-flex xs2>
        <v-text-field
          v-model="currentPricing.factors.time_until_booking['20-30']"
          label="21-30 days"
          required
          @change="sendPreview"
        ></v-text-field>
      </v-flex>
      <v-flex xs2>
        <v-text-field
          v-model="currentPricing.factors.time_until_booking['30-60']"
          label="1-2 months"
          required
          @change="sendPreview"
        ></v-text-field>
      </v-flex>
      <v-flex xs2>
        <v-text-field
          v-model="currentPricing.factors.time_until_booking['60-90']"
          label="2-3 months"
          required
          @change="sendPreview"
        ></v-text-field>
      </v-flex>
      <v-flex xs2>
        <v-text-field
          v-model="currentPricing.factors.time_until_booking['90-150']"
          label="3-5 months"
          required
          @change="sendPreview"
        ></v-text-field>
      </v-flex>
      <v-flex xs2>
        <v-text-field
          v-model="currentPricing.factors.time_until_booking['250-1500']"
          label="8 months ahead"
          required
          @change="sendPreview"
        ></v-text-field>
      </v-flex>
    </v-layout>
    <v-divider></v-divider>
  </v-flex>
</template>

<script>
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import FormattersMixin from 'components/mixins/formatters-mixin'
import SaveStrategyModal from 'components/pricing-strategies/save-strategy-modal'
import omit from 'lodash/fp/omit'
import merge from 'lodash/fp/omit'
import isEqual from 'lodash/fp/isEqual'
import { mapState } from 'vuex'
import axios from 'axios'

export default {
  name: 'PricingPage',
  components: { SaveStrategyModal },
  mixins: [CommonFunctions, PermissionsMixin, FormattersMixin],
  data: function () {
    return {
      showExtraFilters: false,
      enableSave: false,
      initialPricing: {},
      enableOverride: false,
      enableApply: false,
    }
  },
  props: ['calendarOnly', 'getPricingPreview', 'save'],
  mounted() {
    this.initialPricing = Object.assign({}, this.currentPricing)
  },
  computed: {
    ...mapState(['pricingStrategies']),
    strategyItems() {
      return this.pricingStrategies.map(a => ({ text: a.name, value: a.id }))
    },
    isMultiUnit() {
      return (
        this.currentPricing.multi_unit_pricings &&
        this.currentPricing.multi_unit_pricings.length > 0
      )
    },
    currentPricing() {
      return this.$store.state.currentPricing
    },
  },

  watch: {
    currentPricing() {
      if (this.currentPricing.pricing_strategy_id) {
        if (
          this.initialPricing.pricing_strategy_id ===
          this.currentPricing.pricing_strategy_id
        ) {
          const equal = isEqual(
            this.initialPricing.factors,
            this.currentPricing.factors
          )
          this.enableApply = false
        }
      } else {
        this.enableOverride = false
      }
    },
  },
  methods: {
    sendPreview(options) {
      this.enableSave = true
      this.getPricingPreview(options)
      this.enableOverride = true
    },
    changeStrategy(val) {
      if (val === null) {
        this.currentPricing.factors = this.initialPricing.factors
      } else {
        this.enableApply = true
        const strat = this.pricingStrategies.find(s => s.id == val)
        const sections = strat.factors.factors
        const factors = merge(this.currentPricing.factors, sections)
        this.currentPricing.factors = factors
        this.sendPreview({ strat_id: val })
      }
      this.enableOverride = false
    },
    overrideStrategy() {
      this.enableOverride = false
      this.$store.commit('updateLoading', true)
      const strat = this.pricingStrategies.find(
        s => s.id == this.currentPricing.pricing_strategy_id
      )
      const updateHash = {
        base_price: this.currentPricing.base_price,
        min_price_weekend: this.currentPricing.min_price_weekend,
        min_price_weekday: this.currentPricing.min_price_weekday,
        max_price: this.currentPricing.max_price,
        min_nights_weekend: this.currentPricing.min_nights_weekend,
        min_nights_weekday: this.currentPricing.min_nights_weekday,
        scramble_prices: this.currentPricing.scramble_prices,
        dynamic_nights_1_night: this.currentPricing.dynamic_nights_1_night,
        dynamic_nights_2_night: this.currentPricing.dynamic_nights_2_night,
        factors: this.currentPricing.factors,
        activated: this.currentPricing.activated,
        enable_emergency_mode: this.currentPricing.enable_emergency_mode,
        enable_group_pricing: this.currentPricing.enable_group_pricing,
        enable_multi_unit_pricing:
          this.currentPricing.enable_multi_unit_pricing,
        enable_trapped_between_weekends:
          this.currentPricing.enable_trapped_between_weekends,
        name: strat.name,
      }

      try {
        axios
          .patch(`/api/pricing-strategies/${strat.id}`, updateHash)
          .then(response => {
            this.save()
          })
          .catch(alert)
        this.$store.commit('updateLoading', false)
      } catch (error) {
        alert(error)
      }
    },
  },
}
</script>
