<template>
  <form-dialog
    title="Reservation Edit"
    :disable-submit="!reservation.listing_id"
    @submit="submit"
    @open="getRates"
  >
    <template #activator="{ on }">
      <v-btn
        depressed
        icon
        :class="showBtnBorder ? 'btn-border' : ''"
        v-on="on"
      >
        <v-icon :color="color || 'info'" small>mdi-pencil</v-icon>
      </v-btn>
    </template>
    <v-row>
      <v-col v-if="!isInvestor" cols="12">
        <v-autocomplete
          v-model="reservation.listing_id"
          label="Listing"
          :items="listingsItems"
          dense
          hide-details
          item-text="nickname"
          item-value="id"
          outlined
          @change="getRates"
        />
        <div
          v-if="!reservation.is_editable"
          class="text-caption warning--text py-4"
        >
          {{
            `* Moving this reservation to another listing won't release those dates
          in ${reservation.source}`
          }}
        </div>
      </v-col>
      <v-col cols="12">
        <confirmation-modal
          ref="confirmDateChangeModal"
          :text="
            $t('The selected dates are blocked. Please confirm this action.')
          "
          title="Date change confirmation"
          :actions="[
            {
              text: 'Cancel',
              color: 'error',
              onClick: setResDateRange,
            },
            {
              text: 'Confirm',
              color: 'success',
              onClick: () => {},
            },
          ]"
        />
        <date-picker
          v-model="range"
          :range="true"
          class="mt-3"
          :hide-details="true"
          :allowed-dates="allowedDates"
          :event-color="eventColors"
          :disabled="!reservation.is_editable"
          label="Dates"
        />
        <v-checkbox
          v-model="reCalc"
          :disabled="!reservation.is_editable"
          dense
          class="mt-0"
          label="Recalculate price(including taxes & fees)"
        />
      </v-col>
      <v-col cols="12">
        <v-select
          v-model="status"
          outlined
          dense
          :disabled="!reservation.is_editable"
          :items="['confirmed', 'inquiry', 'canceled']"
          :label="$t('Status')"
        />
      </v-col>
    </v-row>
  </form-dialog>
</template>

<script>
import FormDialog from 'components/common/form-dialog'
import DatePicker from 'components/form-fields/date-picker'
import CommonFunctions from 'components/mixins/common_functions'
import { mapGetters } from 'vuex'
import equal from 'lodash/fp/isEqual'
import isEmpty from 'lodash/fp/isEmpty'
import FormattersMixin from 'components/mixins/formatters-mixin'
import ConfirmationModal from 'components/modals/confirmation-modal'
import PermissionsMixin from 'components/mixins/permissions-mixin'

export default {
  name: 'ReservationEditModal',
  components: { DatePicker, FormDialog, ConfirmationModal },
  mixins: [CommonFunctions, FormattersMixin, PermissionsMixin],
  props: ['listing', 'reservation', 'color', 'showBtnBorder'],
  data() {
    return {
      reCalc: false,
      currentRates: {},
      status: this.reservation.status,
      range: [
        this.parseDate(this.reservation.check_in),
        this.parseDate(this.reservation.check_out),
      ],
    }
  },
  async mounted() {
    this.currentRates = await this.$store.dispatch(
      'listings/getListingRates',
      this.reservation.listing_id
    )
  },
  watch: {
    range: function (newRange, oldRang) {
      if (newRange !== oldRang && newRange !== this.initialResRange) {
        if (this.isBlocked(newRange)) {
          this.$refs.confirmDateChangeModal.open()
        }
      }
    },
  },
  computed: {
    ...mapGetters(['listingsPicker']),
    isOwnerVacation() {
      return this.reservation.source === 'Owner'
    },
    listingsItems() {
      if (this.listing.is_multi_unit) {
        return this.listingsPicker.filter(
          l =>
            l.is_multi_unit ||
            !l.container_multi_id ||
            l.container_multi_id === this.listing.id
        )
      }
      return this.listingsPicker
    },
    initialResRange() {
      return [
        this.parseDate(this.reservation.check_in),
        this.parseDate(this.reservation.check_out),
      ]
    },
  },
  methods: {
    async getRates(lId) {
      if (!this.reservation.listing_id) return
      this.currentRates = await this.$store.dispatch(
        'listings/getListingRates',
        this.reservation.listing_id
      )
      if (
        lId &&
        lId !== this.reservation.listing_id &&
        this.isBlocked(this.range)
      ) {
        this.$refs.confirmDateChangeModal.open()
      }
    },
    allowedDates(date) {
      if (isEmpty(this.currentRates)) {
        return true
      }
      const dayBefore = this.$moment(date)
        .subtract(1, 'day')
        .format('YYYY-MM-DD')
      return (
        (this.currentRates[date] &&
          this.currentRates[date].status === 'available') ||
        this.$moment(date).isBetween(
          this.parseDate(this.reservation.check_in),
          this.parseDate(this.reservation.check_out),
          undefined,
          '[]'
        ) ||
        (this.currentRates[dayBefore] &&
          this.currentRates[date] &&
          this.currentRates[date].status !== 'available' &&
          this.currentRates[dayBefore].status === 'available' &&
          this.reservation.listing_id === this.listing.id)
      )
    },
    eventColors(date) {
      if (
        this.currentRates[date] &&
        this.currentRates[date].status === 'available'
      ) {
        return 'green lighten-1'
      }
      return ''
    },
    async submit() {
      const payload = {}
      payload.listing_id = this.reservation.listing_id
      if (
        !equal(this.range, [
          this.parseDate(this.reservation.check_in),
          this.parseDate(this.reservation.check_out),
        ])
      ) {
        payload.check_in = this.range[0]
        payload.check_out = this.range[1]
      }
      if (this.reservation.status !== this.status) {
        payload.status = this.status
      }
      payload.recalculate_price = this.reCalc
      this.$store.commit('updateLoading', true)
      const response = await this.$store.dispatch(
        'reservation/alterReservation',
        {
          id: this.reservation.id,
          ...payload,
        }
      )
      this.$store.commit('updateLoading', false)
      if (response) {
        this.$emit('change')
      }
    },
    isBlocked(range) {
      const dateRange = this.generateDateRangeArray(range[0], range[1])
      const isAnyDateUnavailable = dateRange.some(
        date => this.currentRates[date].status !== 'available'
      )
      return isAnyDateUnavailable
    },
    setResDateRange() {
      this.range = [
        this.parseDate(this.reservation.check_in),
        this.parseDate(this.reservation.check_out),
      ]
      this.$refs.confirmDateChangeModal.close()
    },
  },
}
</script>

<style scoped>
.btn-border {
  border: solid 1px #dfe1e6;
}
</style>
