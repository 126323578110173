var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-navigation-drawer',{staticClass:"fill-available",attrs:{"temporary":"","stateless":"","right":"","app":"","width":1400,"value":Boolean(_vm.conversationId) || Boolean(_vm.newConvUser)}},[(_vm.conversationId || _vm.newConvUser)?_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.onClickOutside),expression:"onClickOutside"}]},[_c('div',{staticClass:"d-flex flex h-100 conv-container"},[(_vm.conversation)?_c('v-sheet',{staticClass:"flex-grow-1 d-flex flex-column",attrs:{"min-width":"300"}},[_c('div',{},_vm._l((_vm.conversation.outstanding_issues),function(issue){return _c('div',{key:issue.id},[_c('div',{staticClass:"w-100 amber lighten-4 font-weight-medium py-2 px-4 font-weight-medium text-caption",staticStyle:{"color":"#a34904"}},[_c('span',{staticClass:"font-weight-medium"},[_vm._v(_vm._s(issue.task_type)+" "),_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-chevron-right")]),_vm._v(" "+_vm._s(issue.sub_topic)+":")],1),_c('a',{staticClass:"ml-2 text-decoration-underline",staticStyle:{"color":"#a34904"},attrs:{"target":"_blank","href":`dashboard/listing-task/${issue.id}`}},[_vm._v(_vm._s(issue.description)+" ")])]),_c('v-divider',{staticClass:"grey lighten-2"})],1)}),0),_c('div',{staticClass:"d-flex flex-column h-100"},[_c('div',{staticClass:"flex-grow-1",staticStyle:{"height":"0"}},[_c('v-progress-linear',{directives:[{name:"show",rawName:"v-show",value:(_vm.conversationLoading),expression:"conversationLoading"}],attrs:{"indeterminate":""}}),(
                _vm.proxiedIf(
                  _ => _vm.conversation.active_listing === 'Gold Dimond'
                ) &&
                _vm.conversation.thread_plus_dvr_log &&
                !_vm.conversationLoading
              )?_c('demo-conversation-messages',{attrs:{"conversation":_vm.conversation,"drawer-mode":true},on:{"update-conversations":_vm.updateConversations,"create-service-call":_vm.openServiceCallModal,"approve-ai-suggestion":_vm.approveAiSuggestion}}):(
                _vm.conversation.thread_plus_dvr_log && !_vm.conversationLoading
              )?_c('conversation-messages',{attrs:{"conversation":_vm.conversation,"drawer-mode":true},on:{"update-conversations":_vm.updateConversations,"create-service-call":_vm.openServiceCallModal,"approve-ai-suggestion":_vm.approveAiSuggestion}}):_vm._e()],1),_c('div',[(_vm.conversation)?_c('conversation-chat-box',{ref:"conversationChatBox",attrs:{"new-conv-user":_vm.newConvUser},on:{"create-service-call":_vm.openServiceCallModal,"update-conversation-fun-level":_vm.updateConversationFunLevel}}):_vm._e()],1)])]):_vm._e(),_c('v-sheet',{staticClass:"d-flex flex-column",attrs:{"top":"64px"}},[(_vm.conversation)?_c('conversation-info',{key:_vm.conversation.id,staticClass:"transparent",attrs:{"id":"conversation-info","fetching":_vm.conversationLoading,"conversation":_vm.conversation}}):_vm._e()],1)],1)]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }