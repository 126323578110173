<template>
  <div>
    <v-row>
      <v-col cols="12" sm="3">
        <v-btn-toggle
          v-model="selectedSettings"
          class="flex-column d-flex py-4 px-2"
          color="primary"
        >
          <v-btn :disabled="!tenantSettings" value="billing">Billing</v-btn>
          <v-btn :disabled="!tenantSettings" value="bi">BI</v-btn>
          <v-btn :disabled="!tenantSettings" value="messaging">Messaging</v-btn>
          <v-btn :disabled="!tenantSettings" value="import">Import</v-btn>
          <v-btn :disabled="!tenantSettings" value="ai">AI</v-btn>
          <v-btn :disabled="!tenantSettings" value="bulk_actions"
            >Bulk Actions</v-btn
          >
          <v-btn :disabled="!tenantSettings" value="add_ons">Add-ons</v-btn>
          <v-btn :disabled="!tenantSettings" value="info">Tenant Info</v-btn>
        </v-btn-toggle>
      </v-col>
      <v-col cols="12" sm="9">
        <v-row class="w-100 pa-2">
          <div class="d-flex align-center justify-space-between w-100">
            <div class="d-flex flex-center">
              <v-autocomplete
                v-model="selectedTenant"
                :items="tenants"
                :label="$t('Tenant')"
                dense
                :disabled="!tenants.length"
                item-text="name"
                item-value="id"
                hide-details
                return-object
                outlined
                class="d-inline-block my-2"
                @change="fetchTenantSettings"
              />
              <div v-if="selectedSettings === 'billing'" class="ms-6">
                <div class="pa-2">
                  <tenants-table />
                </div>
              </div>
            </div>
            <div class="mt-4 mb-2">
              <v-btn
                v-if="selectedTenant.id"
                color="primary"
                depressed
                @click="save"
              >
                Save</v-btn
              >
            </div>
          </div>
        </v-row>
        <div v-if="loading" class="w-100 text-center">
          <v-progress-circular
            :width="2"
            :size="25"
            class="ml-2"
            indeterminate
            color="primary"
          />
        </div>
        <v-row v-else class="w-100 pa-2">
          <tenant-billing
            v-if="selectedSettings === 'billing' && billing"
            v-model="billing"
            @fetch-settings="fetchTenantSettings"
          />
          <tenant-bi v-if="selectedSettings === 'bi'" v-model="bi" />
          <tenant-messaging
            v-if="selectedSettings === 'messaging'"
            v-model="messaging"
            :tenant-id="selectedTenant.id"
          />

          <div v-if="selectedSettings === 'import'" class="w-100">
            <v-row>
              <v-col cols="12" class="mb-4">
                <v-card>
                  <v-card-title>Import Listings</v-card-title>
                  <v-card-text>
                    <v-btn
                      color="primary"
                      class="mr-4"
                      @click="downloadListingsTemplate"
                    >
                      Download Template
                    </v-btn>

                    <v-file-input
                      v-model="listingsFile"
                      label="Upload Listings Excel"
                      accept=".xlsx"
                      @change="handleListingsFileUpload"
                      :loading="uploadingListings"
                    />

                    <div v-if="listingsImportErrors.length" class="mt-4">
                      <p class="red--text">Import Errors:</p>
                      <ul>
                        <li
                          v-for="(error, i) in listingsImportErrors"
                          :key="i"
                          class="red--text"
                        >
                          {{ error }}
                        </li>
                      </ul>
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12">
                <tenant-reservations :tenant="selectedTenant.id" />
              </v-col>
              <v-col cols="12">
                <tenant-users :tenant="selectedTenant.id" />
              </v-col>
            </v-row>
          </div>

          <tenant-ai
            v-if="selectedSettings === 'ai'"
            :settings="tenantSettings"
            :tenant-id="selectedTenant.id"
          />
          <bulk-actions
            v-if="selectedSettings === 'bulk_actions'"
            :tenant="selectedTenant.id"
          />
          <add-ons v-if="selectedSettings === 'add_ons'" v-model="addOns" />
          <tenant-info
            v-if="selectedSettings === 'info'"
            :tenant="selectedTenant"
            @deactivate="deactivateTenant"
          />
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import TenantBi from './tenant-bi.vue'
import tenantBilling from 'components/tenant-settings/tenant-billing.vue'
import TenantMessaging from 'components/tenant-settings/tenant-messaging.vue'
import axios from '@/axios/config'
import customAxios from '@/axios/config'
import TenantsTable from 'components/tenant-settings/tenants-table.vue'
import TenantReservations from 'components/tenant-settings/tenant-reservations.vue'
import TenantUsers from 'components/tenant-settings/tenant-users.vue'
import TenantAi from './tenant-ai.vue'
import Toaster from '@/utils/toaster'
import BulkActions from 'components/tenant-settings/bulk-actions.vue'
import AddOns from 'components/tenant-settings/add-ons.vue'
import { downloadFile } from '@/utils/common-utils'
import TenantInfo from 'components/tenant-settings/tenant-info.vue'

export default {
  components: {
    TenantInfo,
    tenantBilling,
    TenantBi,
    TenantMessaging,
    TenantsTable,
    TenantReservations,
    TenantAi,
    BulkActions,
    AddOns,
    TenantUsers,
  },
  name: 'TenantSettings',
  data() {
    return {
      loading: false,
      selectedSettings: 'billing',
      tenants: [],
      tenantSettings: null,
      selectedTenant: {},
      reportsItems: [],
      reports: [],
      approvedModules: [],
      bi: null,
      billing: null,
      messaging: null,
      tenantsStatus: false,
      ai: null,
      addOns: {
        invoices: {
          isActiveSpaceInvoice: false,
        },
      },
      listingsFile: null,
      uploadingListings: false,
      listingsImportErrors: [],
    }
  },
  mounted() {
    this.fetchTenants()
  },
  methods: {
    ...mapActions('tenants', ['getTenants', 'getTenantSettings']),
    ...mapActions('billing', ['prevMonthInvoice']),

    async fetchTenants() {
      const { data } = await this.getTenants()
      this.tenants = data
    },
    async fetchTenantSettings(tenant) {
      if (!tenant) return
      this.loading = true
      const { data: settings } = await this.getTenantSettings(tenant.id)

      this.tenantSettings = settings

      const { data: invoiceData } = await this.prevMonthInvoice({
        tenant_id: settings.tenant_id,
      })

      this.billing = {
        ...settings.boom_payment_model,
        billing_info: settings.billing_info,
        approved_modules: settings.approved_modules,
        tenant_id: settings.tenant_id,
        company_name: settings.company_name,
        paymentMethodId: settings.payment_method_id,
        last4: settings.payment_method && settings.payment_method.last4,
        invoice: {
          lastInvoice: invoiceData.invoice,
          charge: invoiceData.charge,
          invoiceYear: invoiceData.year,
          invoiceMonth: invoiceData.month,
        },
      }
      this.messaging = {
        twilio_credentials: settings.twilio_credentials,
        twilio_phone: settings.twilio_phone,
        messaging_email: settings.messaging_email,
      }

      this.addOns = {
        invoices: {
          isActiveSpaceInvoice: settings.is_active_space_invoice,
        },
      }
      await this.fetchBiData()
      this.loading = false
    },
    async fetchBiData() {
      const {
        data: { reports },
      } = await axios.get('/api/bi/reports/management')
      const appReportsKeys = this.tenantSettings.bi_reports.map(r => r.key)

      this.bi = {
        selectedReports: reports
          .filter(r => appReportsKeys.indexOf(r.key) !== -1)
          .map(r => r.key),
        reportItems: reports,
        approvedModules: this.tenantSettings.approved_modules,
      }
    },

    async save() {
      const payload = {
        modules: this.bi.approvedModules,
        per_door: this.billing.per_door,
        calculation: this.billing.calculation,
        twilio_credentials: this.messaging.twilio_credentials,
        twilio_phone: this.messaging.twilio_phone,
        messaging_email: this.messaging.messaging_email,
        is_active_space_invoice: this.addOns.invoices.isActiveSpaceInvoice,
      }

      if (this.bi.selectedReports && this.bi.selectedReports.length > 0) {
        payload.bi_reports = this.bi.selectedReports
      }

      axios.patch(`/api/settings/${this.selectedTenant.id}/reports`, payload)
    },
    async deactivateTenant() {
      try {
        await axios.post(`/api/tenants/${this.selectedTenant.id}/deactivate`)

        // Refresh tenant settings to show updated status
        await this.getTenants()

        this.$store.dispatch('showSnackbar', {
          text: 'Tenant successfully deactivated',
          color: 'success',
        })
      } catch (error) {
        this.$store.dispatch('showSnackbar', {
          text: 'Error deactivating tenant',
          color: 'error',
        })
        console.error('Error deactivating tenant:', error)
      }
    },

    async downloadListingsTemplate() {
      try {
        const response = await axios.get('/api/listings/template', {
          responseType: 'blob',
        })
        downloadFile(response.data, 'listings_import_template.xlsx')
      } catch (error) {
        console.error('Error downloading template:', error)
      }
    },

    async handleListingsFileUpload() {
      if (!this.listingsFile) return

      this.uploadingListings = true
      this.listingsImportErrors = []

      try {
        const formData = new FormData()
        formData.append('file', this.listingsFile)

        const response = await customAxios.post(
          `/api/listings/${this.selectedTenant.id}/import`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          }
        )

        if (response.data.errors) {
          this.listingsImportErrors = response.data.errors
        } else {
          Toaster.show([
            { type: 'success', text: 'Listings imported successfully' },
          ])
        }
      } finally {
        this.uploadingListings = false
        this.listingsFile = null
      }
    },
  },
  computed: {
    mappedReportsItems() {
      return this.reportsItems.map(r => ({ text: r.title, value: r.key }))
    },
  },
}
</script>

<style></style>
