<template>
  <v-card class="overflow-y-auto">
    <v-form ref="form" @submit.prevent="save">
      <v-card-title> Listing Zone</v-card-title>
      <v-card-text>
        <v-text-field
          v-model="zone.name"
          label="Name"
          :rules="[required]"
          outlined
          rows="4"
        />
        <v-text-field
          v-model="zone.company_name"
          label="Company Name"
          outlined
          dense
          placeholder="Example: Booking Corp"
          class="mt-2 mb-2"
        />
        <multi-property-picker v-model="listingIds" />
        <v-select
          v-model="zone.currency"
          outlined
          label="Currency"
          dense
          :items="['EUR', 'USD', 'ILS', 'ZAR', 'GBP']"
        />
        <v-text-field
          v-model="zone.processing_rate"
          class="mt-2 mb-2"
          outlined
          dense
          :disabled="!isAdmin"
          label="Processing fee"
          required
        />
        <div class="d-flex flex-column my-8">
          <v-select
            v-model="zone.net_income_formula"
            prepend-inner-icon="mdi-calculator"
            outlined
            label="Net Income Formula"
            dense
            :items="NET_INCOME_FORMULAS"
          />
          <v-select
            v-model="zone.management_commission_formula"
            outlined
            prepend-inner-icon="mdi-calculator"
            label="Management Commission Formula"
            dense
            :items="MANAGEMENT_COMMISSION_FORMULAS"
          />
          <v-select
            v-model="zone.owner_revenue_formula"
            outlined
            prepend-inner-icon="mdi-calculator"
            label="Owner Revenue Formula"
            dense
            :items="OWNER_REVENUE_FORMULAS"
          />
          <v-select
            v-if="stripeItems.length"
            v-model="zone.stripe_account_id"
            :label="$t('Stripe account')"
            :items="stripeItems"
            outlined
            dense
          />
        </div>
        <!--        <v-text-field-->
        <!--          v-model="zone.booking_tax_rate"-->
        <!--          class="mt-2 mb-2"-->
        <!--          label="Total Booking Tax Rate"-->
        <!--          :disabled="!isAdmin"-->
        <!--          outlined-->
        <!--          dense-->
        <!--        />-->
        <!--        <v-text-field-->
        <!--          v-model="zone.store_tax"-->
        <!--          class="mt-2 mb-2"-->
        <!--          outlined-->
        <!--          dense-->
        <!--          label="Store tax"-->
        <!--          required-->
        <!--        />-->
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn small color="primary" outlined @click="closeModal">Cancel</v-btn>
        <v-btn small color="primary" type="submit">Save</v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import formRules from 'components/mixins/form-rules-mixin'
import MultiPropertyPicker from 'components/multi-property-picker'
import PermissionsMixin from 'components/mixins/permissions-mixin'

import {
  NET_INCOME_FORMULAS,
  OWNER_REVENUE_FORMULAS,
  MANAGEMENT_COMMISSION_FORMULAS,
} from '@/consts'

export default {
  name: 'ListingZoneModal',
  components: { MultiPropertyPicker },
  mixins: [formRules, PermissionsMixin],
  props: ['zone'],
  data() {
    return {
      name: null,
      listingIds: [],
      NET_INCOME_FORMULAS,
      OWNER_REVENUE_FORMULAS,
      MANAGEMENT_COMMISSION_FORMULAS,
    }
  },
  mounted() {
    this.listingIds = this.listingsPicker
      .filter(l => l.zone_id === this.zone.id)
      .map(l => l.id)
  },
  computed: {
    ...mapGetters(['listingsPicker']),
    ...mapState('stripe', ['accounts']),
    stripeItems() {
      return this.accounts.map(a => ({ text: a.name, value: a.id }))
    },
  },
  methods: {
    closeModal() {
      this.$store.commit('hideModal')
    },
    save() {
      if (this.$refs.form.validate()) {
        this.$store.dispatch('zones/updateZone', {
          id: this.zone.id,
          zone: this.zone,
          listings: this.listingIds,
        })
        this.closeModal()
      }
    },
  },
}
</script>

<style scoped></style>
