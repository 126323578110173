import axios from 'axios'
import { sum, values } from 'lodash'
import Toaster from '@/utils/toaster'
import get from 'lodash/fp/get'
import imgCompressor from '@/utils/imgCompressor'

const uploadUrl = 'https://api.cloudinary.com/v1_1/do4tedxg6/upload'

export async function uploadToCloudinary(
  files,
  { onUploadingChange, onUploadProgress } = {},
  config
) {
  onUploadingChange = onUploadingChange || (() => undefined)
  onUploadProgress = onUploadProgress || (() => undefined)

  const filesProgress = {}
  let totalSize = 0
  const { compress = true, folder = '', uploadPreset = 'tcu2ksyf' } = config
  onUploadingChange(true)
  onUploadProgress(0)

  const promises = files.map(async (f, index) => {
    let shouldImprove = false
    if (Array.isArray(f)) {
      shouldImprove = true
      f = f[0]
    }
    const file =
      f.type?.includes('image') && compress ? await imgCompressor(f) : f
    totalSize += file?.size || 1
    filesProgress[index] = 0

    const formData = new FormData()
    formData.append('upload_preset', compress ? uploadPreset : 'original')
    formData.append('file', file)

    formData.append(
      'folder',
      `tenants/${window.config.company_name}/${folder.replace(
        /[?&#\\%<>+ ]/g,
        ''
      )}`
    )
    if (shouldImprove) {
      formData.set('upload_preset', 'ota-images')
    }
    const uploadHeaders = {
      'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
    }
    return axios.post(uploadUrl, formData, {
      headers: uploadHeaders,
      withCredentials: false,
      onUploadProgress: progressEvent => {
        filesProgress[index] = progressEvent.loaded
        const progress = Math.min(
          Math.round(sum(values(filesProgress)) * 100) / totalSize,
          100
        )
        onUploadProgress(progress)
      },
    })
  })
  return Promise.all(promises)
    .then(result =>
      result.map(r => {
        if (r.data.eager && r.data.eager.length) {
          return r.data.eager[0].secure_url
        }
        return r.data.secure_url
      })
    )
    .catch(error => {
      const errorMessage = get('response.data.error', error) || error.message
      Toaster.show([
        { type: 'error', text: `Image upload failed - ${errorMessage}` },
      ])
      throw error
    })
    .finally(() => onUploadingChange(false))
}
