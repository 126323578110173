<template>
  <div v-if="settings">
    <v-row>
      <v-col cols="12" sm="3">
        <v-btn-toggle
          v-model="selectedSettings"
          class="flex-column d-flex"
          color="primary"
        >
          <v-btn value="reviews">Reviews</v-btn>
          <v-btn value="cs" :disabled="!showModule('ai')"
            >Customer Support</v-btn
          >
          <v-btn value="sales" :disabled="!showModule('ai')">Sales</v-btn>
          <v-btn value="operations" :disabled="!showModule('ai')"
            >Operations</v-btn
          >
          <v-btn value="sales2" :disabled="true">Marketing</v-btn>
          <v-btn value="sales4" :disabled="true">Owner relations</v-btn>
          <v-btn value="sales5" :disabled="true">Accounting </v-btn>
          <v-btn value="sales6" :disabled="true">Pricing </v-btn>
        </v-btn-toggle>
      </v-col>
      <v-col cols="12" sm="9">
        <v-sheet elevation="2" rounded class="pa-4" height="100%">
          <v-row v-if="selectedSettings === 'reviews'">
            <v-col cols="12" sm="8" class="ml-3">
              <div class="text-h6">AI Reviews config</div>
              <v-row class="justify-space-between align-start">
                <v-col cols="auto">
                  <span
                    class="text-subtitle-1 font-weight-medium secondary--text text--darken-1"
                  >
                    Co Pilot
                  </span>
                </v-col>
                <v-col cols="auto">
                  <v-switch
                    v-model="settings.reviews.co_pilot"
                    class="mt-0"
                    hide-details
                    inset
                  />
                </v-col>
              </v-row>
              <div>
                <v-checkbox
                  v-model="settings.reviews.analyze_reviews"
                  label="Create review analysis and tags"
                  :disabled="!settings.reviews.co_pilot"
                  dense
                  hide-details
                />
              </div>
              <div>
                <v-checkbox
                  v-model="settings.reviews.suggest_sc"
                  label="Suggest service calls based on review"
                  :disabled="!settings.reviews.co_pilot"
                  dense
                  hide-details
                />
              </div>
              <div>
                <v-checkbox
                  v-model="settings.reviews.suggested_reply"
                  label="Suggest public reply to guests"
                  :disabled="!settings.reviews.co_pilot"
                  dense
                  hide-details
                />
              </div>
              <v-divider class="my-3" />
              <v-row class="justify-space-between align-start mt-5">
                <v-col cols="auto">
                  <span
                    class="text-subtitle-1 font-weight-medium secondary--text text--darken-1"
                  >
                    Auto Pilot
                  </span>
                </v-col>
                <v-col cols="auto">
                  <v-switch
                    v-model="settings.reviews.auto_pilot"
                    class="mt-0"
                    :disabled="!isDesignedVr"
                    hide-details
                    inset
                  />
                </v-col>
              </v-row>
              <div>
                <v-checkbox
                  v-model="settings.reviews.create_reviews_service_calls"
                  label="Create service calls on reviews for operations team"
                  :disabled="!settings.reviews.auto_pilot"
                  dense
                  hide-details
                />
              </div>
              <div>
                <v-checkbox
                  v-model="settings.reviews.auto_reply_to_guests"
                  :disabled="!settings.reviews.auto_pilot"
                  label="Auto reply to guests"
                  dense
                  hide-details
                />
              </div>
            </v-col>
          </v-row>
          <v-row v-if="selectedSettings === 'cs'">
            <v-col cols="12" sm="8" class="ml-3">
              <div class="text-h6">AI Customer Support config</div>
              <div style="margin-top: 10px; margin-bottom: 10px">
                <v-checkbox
                  v-model="settings.cs.debug_mode"
                  label="Debug Mode"
                  dense
                  hide-details
                />
              </div>
              <v-row class="justify-space-between align-start">
                <v-col cols="auto">
                  <span
                    class="text-subtitle-1 font-weight-medium secondary--text text--darken-1"
                  >
                    Co Pilot
                  </span>
                </v-col>
                <v-col cols="auto">
                  <v-switch
                    v-model="settings.cs.co_pilot"
                    class="ma-0"
                    hide-details
                    inset
                  />
                </v-col>
              </v-row>
              <div>
                <v-checkbox
                  v-model="settings.cs.co_pilot_auto_run"
                  label="Auto run co pilot"
                  :disabled="!settings.cs.co_pilot"
                  dense
                  hide-details
                />
              </div>
              <div>
                <v-checkbox
                  v-model="settings.cs.co_pilot_log_reject_reason"
                  label="Log Reject Reason"
                  :disabled="!settings.cs.co_pilot"
                  dense
                  hide-details
                />
              </div>
              <div>
                <v-checkbox
                  v-model="settings.cs.suggested_response"
                  label="Suggested response"
                  :disabled="!settings.cs.co_pilot"
                  dense
                  hide-details
                />
              </div>
              <div>
                <v-checkbox
                  v-model="settings.cs.tag_conversations"
                  label="Tag conversation and update guest satisfaction score"
                  :disabled="!settings.cs.co_pilot"
                  dense
                  hide-details
                />
              </div>
              <div>
                <v-checkbox
                  v-model="settings.cs.co_pilot_follow_up"
                  label="Automatic AI Follow Up"
                  :disabled="!settings.cs.co_pilot"
                  dense
                  hide-details
                />
              </div>
            </v-col>
          </v-row>
          <v-divider v-if="selectedSettings === 'cs'" class="my-3" />
          <v-row v-if="selectedSettings === 'cs'">
            <v-col cols="12" sm="8" class="ml-3">
              <v-row class="justify-space-between align-start">
                <v-col cols="auto">
                  <span
                    class="text-subtitle-1 font-weight-medium secondary--text text--darken-1"
                  >
                    Auto Pilot
                  </span>
                </v-col>
                <v-col cols="auto">
                  <v-switch
                    v-model="settings.cs.auto_pilot"
                    class="ma-0"
                    hide-details
                    inset
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-row
              v-show="settings.cs.auto_pilot"
              class="justify-space-between align-start"
            >
              <v-col cols="12" sm="8" class="ml-3">
                <div style="font-size: 13px">
                  <v-checkbox
                    v-model="settings.cs.auto_pilot_follow_up"
                    label="Automatic AI Follow Up"
                    dense
                    hide-details
                  />
                </div>
                <div style="margin-top: 15px">
                  <v-textarea
                    v-model="settings.cs.max_follow_ups"
                    outlined
                    dense
                    hide-details
                    rows="1"
                    :label="$t('Max Follow Ups')"
                  />
                </div>
                <div style="margin-bottom: 15px; font-size: 13px">
                  <v-checkbox
                    v-model="settings.cs.pause_on_escalation"
                    label="Pause on Escalation"
                    dense
                    hide-details
                  />
                </div>
              </v-col>
              <v-col
                cols="12"
                sm="8"
                class="ml-3"
                style="margin-bottom: -38px; margin-top: -15px"
              >
                <multi-property-picker
                  v-model="settings.cs.auto_pilot_listing_ids"
                  :disabled="!settings.cs.auto_pilot"
                />
              </v-col>
              <v-col cols="12" sm="8" class="ml-3">
                <div style="margin-bottom: 10px; font-size: 13px">
                  <strong>Work hours</strong>
                </div>
                <v-select
                  v-model="settings.cs.auto_pilot_start_time"
                  :items="timeEntries"
                  dense
                  outlined
                  :label="$t('From')"
                  style="margin-bottom: -10px"
                  :disabled="!settings.cs.auto_pilot"
                >
                </v-select>
                <v-select
                  v-model="settings.cs.auto_pilot_end_time"
                  :items="timeEntries"
                  dense
                  outlined
                  :label="$t('To')"
                  style="margin-bottom: -30px"
                  :disabled="!settings.cs.auto_pilot"
                >
                </v-select>
              </v-col>
              <v-col cols="12" sm="8" class="ml-3">
                <div style="margin-bottom: 10px; font-size: 13px">
                  <strong>Work days</strong>
                </div>
                <div style="display: flex">
                  <div
                    v-for="day in csDays"
                    :key="day.name"
                    :class="`${dayItemClassFor(day)} ${
                      day.selected ? 'day-item-selected' : ''
                    } ${
                      settings.cs.auto_pilot
                        ? 'day-item-enabled'
                        : 'day-item-disabled'
                    }`"
                    @click="toggleCsDay(day)"
                  >
                    {{ day.name }}
                  </div>
                </div>
              </v-col>
            </v-row>
            <!--            <v-col cols="12" sm="8" class="ml-3">-->
            <!--              <v-select-->
            <!--                v-model="settings.cs.auto_pilot_tone"-->
            <!--                :items="aiAgentTones"-->
            <!--                dense-->
            <!--                outlined-->
            <!--                :label="$t('Tone')"-->
            <!--                style="margin-bottom: -10px"-->
            <!--                :disabled="!settings.cs.auto_pilot"-->
            <!--              >-->
            <!--              </v-select>-->
            <!--              <v-select-->
            <!--                v-model="settings.cs.auto_pilot_answer_only_after"-->
            <!--                :items="aiAnswerOnlyAfter"-->
            <!--                dense-->
            <!--                outlined-->
            <!--                :label="$t('Answer only after')"-->
            <!--                style="margin-bottom: -10px"-->
            <!--                :disabled="!settings.cs.auto_pilot"-->
            <!--              >-->
            <!--              </v-select>-->
            <!--              <v-select-->
            <!--                v-model="settings.cs.auto_pilot_notify_slack_channel"-->
            <!--                :items="aiCsNotifySlackChannels"-->
            <!--                dense-->
            <!--                outlined-->
            <!--                :label="$t('Notify Slack Channel')"-->
            <!--                style="margin-bottom: -30px"-->
            <!--                :disabled="!settings.cs.auto_pilot"-->
            <!--              >-->
            <!--              </v-select>-->
            <!--            </v-col>-->
          </v-row>
          <v-row
            v-if="selectedSettings === 'cs' && hasAiConfig && !tabsUpdating"
            style="width: 90%"
          >
            <v-col cols="12" class="ml-3">
              <v-row class="justify-space-between align-start mt-5">
                <v-col cols="auto">
                  <span
                    class="text-subtitle-1 font-weight-medium secondary--text text--darken-1"
                  >
                    <strong>Customer Support Policies</strong>
                  </span>
                </v-col>

                <v-col class="text-end">
                  <v-btn
                    v-if="
                      !csPolicyGroups ||
                      !csPolicyGroups.find(e => e.generation_in_progress)
                    "
                    small
                    class="float-right"
                    alt="Generate policies"
                    @click="generateAllCsPolicies"
                  >
                    Generate policies
                  </v-btn>
                  <v-btn
                    v-else
                    disabled
                    small
                    class="float-right"
                    alt="Generate policies"
                  >
                    <v-icon class="rotating">mdi-loading</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <v-row class="policy-tab" align="center">
                <v-col
                  md="auto"
                  style="padding: 0; display: flex; align-items: center"
                >
                  <v-tabs
                    v-model="selectedTab"
                    class="pa-0 ma-0"
                    style="background-color: white; width: 95%"
                    @change="csPolicyTopicChange"
                  >
                    <v-tab
                      v-for="item in csPolicyTabs"
                      :key="item"
                      style="margin-right: 25px"
                    >
                      {{ item }}
                    </v-tab>
                  </v-tabs>
                  <div
                    style="
                      display: flex;
                      flex-direction: row;
                      margin-left: 28px;
                    "
                  >
                    <div style="display: flex; flex-direction: column">
                      <i
                        class="v-icon notranslate mdi mdi-plus"
                        style="
                          margin-top: 35px;
                          cursor: pointer;
                          margin-left: -45px;
                        "
                        @click="addPolicyGroup('cs')"
                      >
                      </i>
                      <ConfirmationModal
                        v-if="settings.cs_policies_with_defaults.length"
                        :text="removePolicyGroupText('cs')"
                        @action="removePolicyGroup('cs')"
                      >
                        <template #activator="{ on }">
                          <i
                            v-if="settings.cs_policies_with_defaults.length"
                            class="v-icon notranslate mdi mdi-minus"
                            style="
                              margin-top: 12px;
                              cursor: pointer;
                              margin-left: -45px;
                            "
                            v-on="on"
                          >
                          </i>
                        </template>
                      </ConfirmationModal>
                    </div>
                    <i
                      v-if="settings.cs_policies_with_defaults.length"
                      class="v-icon notranslate mdi mdi-pencil"
                      style="margin-top: 148%; cursor: pointer; zoom: 80%"
                      @click="editPolicyGroup('cs')"
                    >
                    </i>
                  </div>
                </v-col>
              </v-row>

              <div>
                <v-row
                  v-for="policyGroup in csPolicyGroups"
                  :key="`${policyGroup.id}`"
                  class="mt-3"
                  style="margin-right: 0"
                >
                  <v-col
                    cols="12"
                    style="
                      display: flex;
                      padding: 0;
                      padding-left: 12px;
                      margin-top: 30px;
                    "
                  >
                    <div>
                      <strong>{{ policyGroup.subject }}</strong>
                    </div>
                    <i
                      class="v-icon notranslate mdi mdi-pencil"
                      style="margin-left: 8px; cursor: pointer; zoom: 80%"
                      @click="editPolicy(policyGroup)"
                    >
                    </i>
                    <ConfirmationModal
                      :text="removePolicyText(policyGroup.subject)"
                      @action="removePolicy(policyGroup)"
                    >
                      <template #activator="{ on }">
                        <i
                          class="v-icon notranslate mdi mdi-minus"
                          style="cursor: pointer; margin-left: 8px"
                          v-on="on"
                        >
                        </i>
                      </template>
                    </ConfirmationModal>
                    <div class="d-flex justify-end" style="width: 100%">
                      <v-btn
                        v-if="policyChanged(policyGroup)"
                        style="margin-right: 5px"
                        class="float-right"
                        color="primary"
                        @click="savePolicy(policyGroup)"
                        >Save
                      </v-btn>
                      <!--                      <v-btn-->
                      <!--                        v-if="!policyGroup.generation_in_progress"-->
                      <!--                        color="primary"-->
                      <!--                        alt="Generate policies"-->
                      <!--                        @click="generateCsPolicy(policyGroup)"-->
                      <!--                      >-->
                      <!--                        Generate policy-->
                      <!--                      </v-btn>-->
                      <!--                      <v-btn-->
                      <!--                        v-else-->
                      <!--                        color="primary"-->
                      <!--                        disabled-->
                      <!--                        style="margin-left: auto"-->
                      <!--                        alt="Generate policies"-->
                      <!--                      >-->
                      <!--                        <v-icon class="rotating">mdi-loading</v-icon>-->
                      <!--                      </v-btn>-->
                    </div>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="8"
                    class="ml-3"
                    style="margin-left: 0 !important"
                  >
                    <div style="font-size: 13px">
                      <v-checkbox
                        v-model="policyGroup.pause_on_policy_detection"
                        label="Pause on Policy Detection"
                        dense
                        hide-details
                        @change="policyChange(policyGroup)"
                      />
                    </div>
                    <div style="margin-bottom: 15px; font-size: 13px">
                      <v-checkbox
                        v-model="policyGroup.escalate_on_policy_detection"
                        label="Escalate on Policy Detection"
                        dense
                        hide-details
                        @change="policyChange(policyGroup)"
                      />
                    </div>
                    <div style="font-size: 13px; margin-top: 35px">
                      <multi-property-picker
                        v-model="policyGroup.listing_ids"
                        @input="policyChange(policyGroup)"
                      />
                    </div>
                  </v-col>
                  <v-col
                    cols="12"
                    style="padding: 0; padding-top: 10px; padding-left: 12px"
                  >
                    <div class="d-flex" style="flex-direction: column">
                      <v-textarea
                        v-model="policyGroup.description"
                        outlined
                        dense
                        hide-details
                        class="flex"
                        :placeholder="policyGroup.default_rule"
                        :label="$t('Description')"
                        @input="policyChange(policyGroup)"
                      />
                    </div>
                  </v-col>
                </v-row>

                <v-row class="mt-3">
                  <v-col cols="12">
                    <v-btn color="primary" @click="addPolicy('cs')">
                      Add Policy
                    </v-btn>
                  </v-col>
                </v-row>
              </div>
            </v-col>
          </v-row>
          <v-row
            v-if="selectedSettings === 'cs' && hasAiConfig && tabsUpdating"
          >
            <v-progress-circular
              v-if="isMobileOrIPad"
              class="centerize"
              indeterminate
              color="primary"
              :size="50"
            />
            <Lottie
              v-else
              class="centerize"
              :options="defaultOptions"
              :height="400"
              style="max-width: 600px"
              renderer="svg"
            />
          </v-row>
          <v-row v-if="selectedSettings === 'sales'">
            <v-col cols="12" sm="8" class="ml-3">
              <div class="text-h6">AI Sales config</div>
              <div
                v-if="selectedSettings === 'sales' && hasAiConfig"
                class="comp-wrapper mt-4 mb-4"
              >
                <template>
                  <v-timeline>
                    <v-timeline-item dot-color="purple-lighten-2" fill-dot>
                      <template #icon>
                        <span class="white--text bolder">1</span>
                      </template>
                      <v-card>
                        <v-card-title class="bg-purple-lighten-2">
                          <v-icon
                            class="me-4"
                            icon="mdi-magnify"
                            size="large"
                          ></v-icon>
                          <h2 class="font-weight-light">General information</h2>
                        </v-card-title>
                        <v-card-text>
                          <div style="font-size: 13px">
                            <v-checkbox
                              v-model="settings.sales.auto_pilot_follow_up"
                              label="Automatic AI follow up"
                              dense
                              hide-details
                            />
                          </div>
                          <div style="margin-top: 15px">
                            <v-textarea
                              v-if="settings.sales.auto_pilot_follow_up"
                              v-model="settings.sales.max_follow_ups"
                              outlined
                              dense
                              hide-details
                              rows="1"
                              :label="$t('Max follow ups without answer')"
                            /></div
                        ></v-card-text>
                      </v-card>
                    </v-timeline-item>

                    <v-timeline-item
                      dot-color="amber-lighten-1"
                      size="x-small"
                      fill-dot
                    >
                      <template #icon>
                        <span class="white--text bolder">2</span>
                      </template>
                      <v-card>
                        <v-card-title class="bg-amber-lighten-1 justify-end">
                          <h2 class="me-4 font-weight-light">Negotiation</h2>
                          <v-icon icon="mdi-home-outline" size="large"></v-icon>
                        </v-card-title>
                        <v-card-text>
                          <div style="font-size: 13px">
                            <v-checkbox
                              v-model="settings.sales.discount_allowed"
                              label="Offer discounts to inqueries"
                              dense
                              hide-details
                            />
                          </div>
                          <div style="margin-top: 15px">
                            <v-textarea
                              v-if="settings.sales.discount_allowed"
                              outlined
                              dense
                              hide-details
                              rows="1"
                              :label="$t('Max discount percent')"
                            />
                          </div>
                        </v-card-text>
                      </v-card>
                    </v-timeline-item>

                    <v-timeline-item dot-color="cyan-lighten-1" fill-dot>
                      <template #icon>
                        <span class="white--text bolder">3</span>
                      </template>
                      <v-card>
                        <v-card-title class="bg-cyan-lighten-1">
                          <v-icon
                            class="me-4"
                            icon="mdi-email-outline"
                            size="large"
                          ></v-icon>
                          <h2 class="font-weight-light">
                            Alternatives Properties
                          </h2>
                        </v-card-title>
                        <v-card-text>
                          <div style="font-size: 13px">
                            <v-checkbox
                              v-model="settings.sales.allow_alternatives"
                              label="Send alternatives properties"
                              dense
                              hide-details
                            />
                          </div>
                        </v-card-text>
                      </v-card>
                    </v-timeline-item>

                    <v-timeline-item
                      dot-color="red-lighten-1"
                      size="x-small"
                      fill-dot
                    >
                      <template #icon>
                        <span class="white--text bolder">4</span>
                      </template>
                      <v-card>
                        <v-card-title class="bg-red-lighten-1 justify-end">
                          <h2 class="me-4 font-weight-light">Finalize</h2>
                          <v-icon
                            icon="mdi-account-multiple-outline"
                            size="large"
                          ></v-icon>
                        </v-card-title>
                        <v-card-text>
                          <div style="font-size: 13px">
                            <v-checkbox
                              v-model="settings.sales.offer_direct"
                              label="Offer direct booking site links to non OTA leads"
                              dense
                              hide-details
                            />
                          </div>
                        </v-card-text>
                      </v-card>
                    </v-timeline-item>
                  </v-timeline>
                </template>
              </div>
              <v-row class="justify-space-between align-start">
                <v-col cols="auto">
                  <span
                    class="text-subtitle-1 font-weight-medium secondary--text text--darken-1"
                  >
                    Co Pilot
                  </span>
                </v-col>
                <v-col cols="auto">
                  <v-switch
                    v-model="settings.sales.co_pilot"
                    class="ma-0"
                    hide-details
                    inset
                  />
                </v-col>
              </v-row>
              <div v-if="settings.sales.co_pilot">
                <v-checkbox
                  v-model="settings.sales.co_pilot_auto_run"
                  label="Auto run co pilot"
                  :disabled="!settings.sales.co_pilot"
                  dense
                  hide-details
                />
              </div>
              <div v-if="settings.sales.co_pilot">
                <v-checkbox
                  v-model="settings.sales.co_pilot_follow_up"
                  label="Automatic AI Follow Up"
                  :disabled="!settings.sales.co_pilot"
                  dense
                  hide-details
                />
              </div>
            </v-col>
          </v-row>
          <v-divider v-if="selectedSettings === 'sales'" class="my-3" />
          <v-row v-if="selectedSettings === 'sales'">
            <v-col cols="12" sm="8" class="ml-3">
              <v-row class="justify-space-between align-start">
                <v-col cols="auto">
                  <span
                    class="text-subtitle-1 font-weight-medium secondary--text text--darken-1"
                  >
                    Auto Pilot
                  </span>
                </v-col>
                <v-col cols="auto">
                  <v-switch
                    v-model="settings.sales.auto_pilot"
                    class="ma-0"
                    hide-details
                    inset
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-row
              v-show="settings.sales.auto_pilot"
              class="justify-space-between align-start"
            >
              <v-col cols="12" sm="8" class="ml-3">
                <div style="margin-bottom: 15px; font-size: 13px">
                  <v-checkbox
                    v-model="settings.sales.pause_on_escalation"
                    label="Pause on Escalation"
                    dense
                    hide-details
                  />
                </div>
              </v-col>
              <v-col
                cols="12"
                sm="8"
                class="ml-3"
                style="margin-bottom: -38px; margin-top: -15px"
              >
                <multi-property-picker
                  v-model="settings.sales.auto_pilot_listing_ids"
                  :disabled="!settings.sales.auto_pilot"
                />
              </v-col>
              <v-col cols="12" sm="8" class="ml-3">
                <div style="margin-bottom: 10px; font-size: 13px">
                  <strong>Work hours</strong>
                </div>
                <v-select
                  v-model="settings.sales.auto_pilot_start_time"
                  :items="timeEntries"
                  dense
                  outlined
                  :label="$t('From')"
                  style="margin-bottom: -10px"
                  :disabled="!settings.sales.auto_pilot"
                >
                </v-select>
                <v-select
                  v-model="settings.sales.auto_pilot_end_time"
                  :items="timeEntries"
                  dense
                  outlined
                  :label="$t('To')"
                  style="margin-bottom: -30px"
                  :disabled="!settings.sales.auto_pilot"
                >
                </v-select>
              </v-col>
              <v-col cols="12" sm="8" class="ml-3">
                <div style="margin-bottom: 10px; font-size: 13px">
                  <strong>Work days</strong>
                </div>
                <div style="display: flex">
                  <div
                    v-for="day in salesDays"
                    :key="day.name"
                    :class="`${dayItemClassFor(day)} ${
                      day.selected ? 'day-item-selected' : ''
                    } ${
                      settings.sales.auto_pilot
                        ? 'day-item-enabled'
                        : 'day-item-disabled'
                    }`"
                    @click="toggleSalesDay(day)"
                  >
                    {{ day.name }}
                  </div>
                </div>
              </v-col>
            </v-row>
            <div
              v-if="isSuperAdmin && selectedSettings === 'sales' && hasAiConfig"
              class="comp-wrapper"
              style="margin-top: 10px; margin-bottom: 10px"
            >
              <h4>Super admin settings</h4>
              <v-checkbox
                v-model="settings.sales.debug_mode"
                label="Debug Mode"
                dense
                hide-details
              />
              <v-checkbox
                v-model="settings.sales.co_pilot_log_reject_reason"
                label="Ask for Reject Reason"
                :disabled="!settings.sales.co_pilot"
                dense
                hide-details
              />
              <div>
                <v-checkbox
                  v-model="settings.sales.tag_conversations"
                  label="Tag conversation and update guest satisfaction score"
                  :disabled="!settings.sales.co_pilot"
                  dense
                  hide-details
                />
              </div>
            </div>
            <!--            <v-col cols="12" sm="8" class="ml-3">-->
            <!--              <v-select-->
            <!--                v-model="settings.sales.auto_pilot_tone"-->
            <!--                :items="aiAgentTones"-->
            <!--                dense-->
            <!--                outlined-->
            <!--                :label="$t('Tone')"-->
            <!--                style="margin-bottom: -10px"-->
            <!--                :disabled="!settings.sales.auto_pilot"-->
            <!--              >-->
            <!--              </v-select>-->
            <!--              <v-select-->
            <!--                v-model="settings.sales.auto_pilot_answer_only_after"-->
            <!--                :items="aiAnswerOnlyAfter"-->
            <!--                dense-->
            <!--                outlined-->
            <!--                :label="$t('Answer only after')"-->
            <!--                style="margin-bottom: -10px"-->
            <!--                :disabled="!settings.sales.auto_pilot"-->
            <!--              >-->
            <!--              </v-select>-->
            <!--              <v-select-->
            <!--                v-model="settings.sales.auto_pilot_notify_slack_channel"-->
            <!--                :items="aiSalesNotifySlackChannels"-->
            <!--                dense-->
            <!--                outlined-->
            <!--                :label="$t('Notify Slack Channel')"-->
            <!--                style="margin-bottom: -30px"-->
            <!--                :disabled="!settings.sales.auto_pilot"-->
            <!--              >-->
            <!--              </v-select>-->
            <!--            </v-col>-->
          </v-row>
          <v-row
            v-if="selectedSettings === 'sales' && hasAiConfig && !tabsUpdating"
            style="width: 90%"
          >
            <v-col cols="12" class="ml-3">
              <v-row class="justify-space-between align-start mt-5">
                <v-col cols="auto">
                  <span
                    class="text-subtitle-1 font-weight-medium secondary--text text--darken-1"
                  >
                    <strong>Sales Policies</strong>
                  </span>
                </v-col>
                <v-col class="text-end">
                  <v-btn
                    v-if="
                      !salesPolicyGroups ||
                      !salesPolicyGroups.find(e => e.generation_in_progress)
                    "
                    class="float-right"
                    small
                    alt="Generate policies"
                    @click="generateAllSalesPolicies"
                  >
                    Generate policies
                  </v-btn>
                  <v-btn
                    v-else
                    disabled
                    class="float-right"
                    alt="Generate policies"
                  >
                    <v-icon class="rotating">mdi-loading</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <v-row class="policy-tab" align="center">
                <v-col
                  md="auto"
                  style="padding: 0; display: flex; align-items: center"
                >
                  <v-tabs
                    v-model="selectedTab"
                    class="pa-0 ma-0"
                    style="background-color: white; width: 95%"
                    @change="salesPolicyTopicChange"
                  >
                    <v-tab v-for="item in salesPolicyTabs" :key="item">{{
                      item
                    }}</v-tab>
                  </v-tabs>
                  <div
                    style="
                      display: flex;
                      flex-direction: row;
                      margin-left: 28px;
                    "
                  >
                    <div style="display: flex; flex-direction: column">
                      <i
                        class="v-icon notranslate mdi mdi-plus"
                        style="
                          margin-top: 35px;
                          cursor: pointer;
                          margin-left: -45px;
                        "
                        @click="addPolicyGroup('sales')"
                      >
                      </i>
                      <ConfirmationModal
                        v-if="settings.sales_policies_with_defaults.length"
                        :text="removePolicyGroupText('sales')"
                        @action="removePolicyGroup('sales')"
                      >
                        <template #activator="{ on }">
                          <i
                            v-if="settings.sales_policies_with_defaults.length"
                            class="v-icon notranslate mdi mdi-minus"
                            style="
                              margin-top: 12px;
                              cursor: pointer;
                              margin-left: -45px;
                            "
                            v-on="on"
                          >
                          </i>
                        </template>
                      </ConfirmationModal>
                    </div>
                    <i
                      v-if="settings.sales_policies_with_defaults.length"
                      class="v-icon notranslate mdi mdi-pencil"
                      style="margin-top: 148%; cursor: pointer; zoom: 80%"
                      @click="editPolicyGroup('sales')"
                    >
                    </i>
                  </div>
                </v-col>
              </v-row>
              <div>
                <v-row
                  v-for="policyGroup in salesPolicyGroups"
                  :key="`${policyGroup.id}`"
                  class="mt-3"
                  style="margin-right: 0"
                >
                  <v-col
                    cols="12"
                    style="
                      display: flex;
                      padding: 0;
                      padding-left: 12px;
                      margin-top: 30px;
                    "
                  >
                    <div>
                      <strong>{{ policyGroup.subject }}</strong>
                    </div>
                    <i
                      class="v-icon notranslate mdi mdi-pencil"
                      style="margin-left: 8px; cursor: pointer; zoom: 80%"
                      @click="editPolicy(policyGroup)"
                    >
                    </i>
                    <ConfirmationModal
                      :text="removePolicyText(policyGroup.subject)"
                      @action="removePolicy(policyGroup)"
                    >
                      <template #activator="{ on }">
                        <i
                          class="v-icon notranslate mdi mdi-minus"
                          style="cursor: pointer; margin-left: 8px"
                          v-on="on"
                        >
                        </i>
                      </template>
                    </ConfirmationModal>
                    <div class="d-flex justify-end" style="width: 100%">
                      <v-btn
                        v-if="policyChanged(policyGroup)"
                        style="margin-right: 5px"
                        class="float-right"
                        color="primary"
                        @click="savePolicy(policyGroup)"
                        >Save
                      </v-btn>
                      <!--                      <v-btn-->
                      <!--                        v-if="!policyGroup.generation_in_progress"-->
                      <!--                        color="primary"-->
                      <!--                        alt="Generate policies"-->
                      <!--                        @click="generateSalesPolicy(policyGroup)"-->
                      <!--                      >-->
                      <!--                        Generate policy-->
                      <!--                      </v-btn>-->
                      <!--                      <v-btn-->
                      <!--                        v-else-->
                      <!--                        color="primary"-->
                      <!--                        disabled-->
                      <!--                        style="margin-left: auto"-->
                      <!--                        alt="Generate policies"-->
                      <!--                      >-->
                      <!--                        <v-icon class="rotating">mdi-loading</v-icon>-->
                      <!--                      </v-btn>-->
                    </div>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="8"
                    class="ml-3"
                    style="margin-left: 0 !important"
                  >
                    <div style="font-size: 13px">
                      <v-checkbox
                        v-model="policyGroup.pause_on_policy_detection"
                        label="Pause on Policy Detection"
                        dense
                        hide-details
                        @change="policyChange(policyGroup)"
                      />
                    </div>
                    <div style="margin-bottom: 15px; font-size: 13px">
                      <v-checkbox
                        v-model="policyGroup.escalate_on_policy_detection"
                        label="Escalate on Policy Detection"
                        dense
                        hide-details
                        @change="policyChange(policyGroup)"
                      />
                    </div>
                    <div style="font-size: 13px; margin-top: 35px">
                      <multi-property-picker
                        v-model="policyGroup.listing_ids"
                        @input="policyChange(policyGroup)"
                      />
                    </div>
                  </v-col>
                  <v-col
                    cols="12"
                    style="padding: 0; padding-top: 10px; padding-left: 12px"
                  >
                    <div class="d-flex" style="flex-direction: column">
                      <v-textarea
                        v-model="policyGroup.description"
                        outlined
                        dense
                        hide-details
                        class="flex"
                        :placeholder="policyGroup.default_rule"
                        :label="$t('Description')"
                        @input="policyChange(policyGroup)"
                      />
                    </div>
                  </v-col>
                </v-row>

                <v-row class="mt-3">
                  <v-col cols="12">
                    <v-btn color="primary" @click="addPolicy('sales')">
                      Add Policy
                    </v-btn>
                  </v-col>
                </v-row>
              </div>
            </v-col>
          </v-row>
          <v-row
            v-if="selectedSettings === 'sales' && hasAiConfig && tabsUpdating"
          >
            <v-progress-circular
              v-if="isMobileOrIPad"
              class="centerize"
              indeterminate
              color="primary"
              :size="50"
            />
            <Lottie
              v-else
              class="centerize"
              :options="defaultOptions"
              :height="400"
              style="max-width: 600px"
              renderer="svg"
            />
          </v-row>
          <v-row v-if="selectedSettings === 'operations'">
            <v-col cols="12" sm="8" class="ml-3">
              <div class="text-h6">AI Dispatch config</div>
              <div style="margin-top: 10px; margin-bottom: 10px">
                <v-checkbox
                  v-model="settings.operations.debug_mode"
                  label="Debug Mode"
                  dense
                  hide-details
                />
              </div>
              <v-row class="justify-space-between align-start">
                <v-col cols="auto">
                  <span
                    class="text-subtitle-1 font-weight-medium secondary--text text--darken-1"
                  >
                    Co Pilot
                  </span>
                </v-col>
                <v-col cols="auto">
                  <v-switch
                    v-model="settings.operations.co_pilot"
                    class="ma-0"
                    hide-details
                    inset
                  />
                </v-col>
              </v-row>
              <div>
                <v-checkbox
                  v-model="settings.operations.co_pilot_auto_run"
                  label="Auto run co pilot"
                  :disabled="!settings.operations.co_pilot"
                  dense
                  hide-details
                />
              </div>
              <div>
                <v-checkbox
                  v-model="settings.operations.co_pilot_log_reject_reason"
                  label="Log Reject Reason"
                  :disabled="!settings.operations.co_pilot"
                  dense
                  hide-details
                />
              </div>
              <div>
                <v-checkbox
                  v-model="settings.operations.suggested_response"
                  label="Suggested response"
                  :disabled="!settings.operations.co_pilot"
                  dense
                  hide-details
                />
              </div>
              <div>
                <v-checkbox
                  v-model="settings.operations.tag_conversations"
                  label="Tag conversation and update guest satisfaction score"
                  :disabled="!settings.operations.co_pilot"
                  dense
                  hide-details
                />
              </div>
              <div>
                <v-checkbox
                  v-model="settings.operations.co_pilot_follow_up"
                  label="Automatic AI Follow Up"
                  :disabled="!settings.operations.co_pilot"
                  dense
                  hide-details
                />
              </div>
            </v-col>
          </v-row>
          <v-divider v-if="selectedSettings === 'operations'" class="my-3" />
          <v-row v-if="selectedSettings === 'operations'">
            <v-col cols="12" sm="8" class="ml-3">
              <v-row class="justify-space-between align-start">
                <v-col cols="auto">
                  <span
                    class="text-subtitle-1 font-weight-medium secondary--text text--darken-1"
                  >
                    Auto Pilot
                  </span>
                </v-col>
                <v-col cols="auto">
                  <v-switch
                    v-model="settings.operations.auto_pilot"
                    class="ma-0"
                    hide-details
                    inset
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-row
              v-show="settings.operations.auto_pilot"
              class="justify-space-between align-start"
            >
              <v-col cols="12" sm="8" class="ml-3">
                <div style="font-size: 13px">
                  <v-checkbox
                    v-model="settings.operations.auto_pilot_follow_up"
                    label="Automatic AI Follow Up"
                    dense
                    hide-details
                  />
                </div>
                <div style="margin-top: 15px">
                  <v-textarea
                    v-model="settings.operations.max_follow_ups"
                    outlined
                    dense
                    hide-details
                    rows="1"
                    :label="$t('Max Follow Ups')"
                  />
                </div>
                <div style="margin-bottom: 15px; font-size: 13px">
                  <v-checkbox
                    v-model="settings.operations.pause_on_escalation"
                    label="Pause on Escalation"
                    dense
                    hide-details
                  />
                </div>
              </v-col>
              <v-col
                cols="12"
                sm="8"
                class="ml-3"
                style="margin-bottom: -38px; margin-top: -15px"
              >
                <multi-property-picker
                  v-model="settings.operations.auto_pilot_listing_ids"
                  :disabled="!settings.operations.auto_pilot"
                />
              </v-col>
              <v-col cols="12" sm="8" class="ml-3">
                <div style="margin-bottom: 10px; font-size: 13px">
                  <strong>Work hours</strong>
                </div>
                <v-select
                  v-model="settings.operations.auto_pilot_start_time"
                  :items="timeEntries"
                  dense
                  outlined
                  :label="$t('From')"
                  style="margin-bottom: -10px"
                  :disabled="!settings.operations.auto_pilot"
                >
                </v-select>
                <v-select
                  v-model="settings.operations.auto_pilot_end_time"
                  :items="timeEntries"
                  dense
                  outlined
                  :label="$t('To')"
                  style="margin-bottom: -30px"
                  :disabled="!settings.operations.auto_pilot"
                >
                </v-select>
              </v-col>
              <v-col cols="12" sm="8" class="ml-3">
                <div style="margin-bottom: 10px; font-size: 13px">
                  <strong>Work days</strong>
                </div>
                <div style="display: flex">
                  <div
                    v-for="day in operationsDays"
                    :key="day.name"
                    :class="`${dayItemClassFor(day)} ${
                      day.selected ? 'day-item-selected' : ''
                    } ${
                      settings.operations.auto_pilot
                        ? 'day-item-enabled'
                        : 'day-item-disabled'
                    }`"
                    @click="toggleDispatchDay(day)"
                  >
                    {{ day.name }}
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-row>
          <v-row
            v-if="
              selectedSettings === 'operations' && hasAiConfig && !tabsUpdating
            "
            style="width: 90%"
          >
            <v-col cols="12" class="ml-3">
              <v-row class="justify-space-between align-start mt-5">
                <v-col cols="auto">
                  <span
                    class="text-subtitle-1 font-weight-medium secondary--text text--darken-1"
                  >
                    <strong>Operations Policies</strong>
                  </span>
                </v-col>

                <v-col class="text-end">
                  <v-btn
                    v-if="
                      !operationsPolicyGroups ||
                      !operationsPolicyGroups.find(
                        e => e.generation_in_progress
                      )
                    "
                    small
                    class="float-right"
                    alt="Generate policies"
                    @click="generateAllDispatchPolicies"
                  >
                    Generate policies
                  </v-btn>
                  <v-btn
                    v-else
                    disabled
                    small
                    class="float-right"
                    alt="Generate policies"
                  >
                    <v-icon class="rotating">mdi-loading</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <v-row class="policy-tab" align="center">
                <v-col
                  md="auto"
                  style="padding: 0; display: flex; align-items: center"
                >
                  <v-tabs
                    v-model="selectedTab"
                    class="pa-0 ma-0"
                    style="background-color: white; width: 95%"
                    @change="operationsPolicyTopicChange"
                  >
                    <v-tab v-for="item in operationsPolicyTabs" :key="item">{{
                      item
                    }}</v-tab>
                  </v-tabs>
                  <div
                    style="
                      display: flex;
                      flex-direction: row;
                      margin-left: 28px;
                    "
                  >
                    <div style="display: flex; flex-direction: column">
                      <i
                        class="v-icon notranslate mdi mdi-plus"
                        style="
                          margin-top: 35px;
                          cursor: pointer;
                          margin-left: -45px;
                        "
                        @click="addPolicyGroup('operations')"
                      >
                      </i>
                      <ConfirmationModal
                        v-if="settings.operations_policies_with_defaults.length"
                        :text="removePolicyGroupText('operations')"
                        @action="removePolicyGroup('operations')"
                      >
                        <template #activator="{ on }">
                          <i
                            v-if="settings.sales_policies_with_defaults.length"
                            class="v-icon notranslate mdi mdi-minus"
                            style="
                              margin-top: 12px;
                              cursor: pointer;
                              margin-left: -45px;
                            "
                            v-on="on"
                          >
                          </i>
                        </template>
                      </ConfirmationModal>
                    </div>
                    <i
                      v-if="settings.operations_policies_with_defaults.length"
                      class="v-icon notranslate mdi mdi-pencil"
                      style="margin-top: 148%; cursor: pointer; zoom: 80%"
                      @click="editPolicyGroup('operations')"
                    >
                    </i>
                  </div>
                </v-col>
              </v-row>

              <div>
                <v-row
                  v-for="policyGroup in operationsPolicyGroups"
                  :key="`${policyGroup.id}`"
                  class="mt-3"
                  style="margin-right: 0"
                >
                  <v-col
                    cols="12"
                    style="
                      display: flex;
                      padding: 0;
                      padding-left: 12px;
                      margin-top: 30px;
                    "
                  >
                    <div>
                      <strong>{{ policyGroup.subject }}</strong>
                    </div>
                    <i
                      class="v-icon notranslate mdi mdi-pencil"
                      style="margin-left: 8px; cursor: pointer; zoom: 80%"
                      @click="editPolicy(policyGroup)"
                    >
                    </i>
                    <ConfirmationModal
                      :text="removePolicyText(policyGroup.subject)"
                      @action="removePolicy(policyGroup)"
                    >
                      <template #activator="{ on }">
                        <i
                          class="v-icon notranslate mdi mdi-minus"
                          style="cursor: pointer; margin-left: 8px"
                          v-on="on"
                        >
                        </i>
                      </template>
                    </ConfirmationModal>
                    <div class="d-flex justify-end" style="width: 100%">
                      <v-btn
                        v-if="policyChanged(policyGroup)"
                        style="margin-right: 5px"
                        class="float-right"
                        color="primary"
                        @click="savePolicy(policyGroup)"
                        >Save
                      </v-btn>
                      <!--                      <v-btn-->
                      <!--                        v-if="!policyGroup.generation_in_progress"-->
                      <!--                        color="primary"-->
                      <!--                        alt="Generate policies"-->
                      <!--                        @click="generateOperationsPolicy(policyGroup)"-->
                      <!--                      >-->
                      <!--                        Generate policy-->
                      <!--                      </v-btn>-->
                      <!--                      <v-btn-->
                      <!--                        v-else-->
                      <!--                        color="primary"-->
                      <!--                        disabled-->
                      <!--                        style="margin-left: auto"-->
                      <!--                        alt="Generate policies"-->
                      <!--                      >-->
                      <!--                        <v-icon class="rotating">mdi-loading</v-icon>-->
                      <!--                      </v-btn>-->
                    </div>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="8"
                    class="ml-3"
                    style="margin-left: 0 !important"
                  >
                    <div style="font-size: 13px">
                      <v-checkbox
                        v-model="policyGroup.pause_on_policy_detection"
                        label="Pause on Policy Detection"
                        dense
                        hide-details
                        @change="policyChange(policyGroup)"
                      />
                    </div>
                    <div style="margin-bottom: 15px; font-size: 13px">
                      <v-checkbox
                        v-model="policyGroup.escalate_on_policy_detection"
                        label="Escalate on Policy Detection"
                        dense
                        hide-details
                        @change="policyChange(policyGroup)"
                      />
                    </div>
                    <div style="font-size: 13px; margin-top: 35px">
                      <multi-property-picker
                        v-model="policyGroup.listing_ids"
                        @input="policyChange(policyGroup)"
                      />
                    </div>
                  </v-col>
                  <v-col
                    cols="12"
                    style="padding: 0; padding-top: 10px; padding-left: 12px"
                  >
                    <div class="d-flex" style="flex-direction: column">
                      <v-textarea
                        v-model="policyGroup.description"
                        outlined
                        dense
                        hide-details
                        class="flex"
                        :placeholder="policyGroup.default_rule"
                        :label="$t('Description')"
                        @input="policyChange(policyGroup)"
                      />
                    </div>
                  </v-col>
                </v-row>

                <v-row class="mt-3">
                  <v-col cols="12">
                    <v-btn color="primary" @click="addPolicy('operations')">
                      Add Policy
                    </v-btn>
                  </v-col>
                </v-row>
              </div>
            </v-col>
          </v-row>
          <v-row
            v-if="
              selectedSettings === 'operations' && hasAiConfig && tabsUpdating
            "
          >
            <v-progress-circular
              v-if="isMobileOrIPad"
              class="centerize"
              indeterminate
              color="primary"
              :size="50"
            />
            <Lottie
              v-else
              class="centerize"
              :options="defaultOptions"
              :height="400"
              style="max-width: 600px"
              renderer="svg"
            />
          </v-row>
        </v-sheet>
      </v-col>
      <v-col cols="12" class="text-end">
        <v-btn
          v-if="settingsChange"
          color="primary"
          class="float-right"
          @click="save"
          >Save
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import isEmpty from 'lodash/isEmpty'
import axios from 'axios'
import MultiPropertyPicker from '../multi-property-picker.vue'
import Lottie from 'vue-lottie'
import Pc from '@/assets/images/pc.json'
import DeviceMixin from '../mixins/device-mixin'
import ConfirmationModal from 'components/modals/confirmation-modal'

export default {
  components: {
    MultiPropertyPicker,
    Lottie,
    ConfirmationModal,
  },
  mixins: [DeviceMixin, CommonFunctions, PermissionsMixin],
  data() {
    return {
      policyUpdated: true,
      settingsChange: false,
      settings: null,
      selectedSettings: 'reviews',
      selectedTab: 0,
      csPolicyDownload: false,
      salesPolicyDownload: false,
      tabsUpdating: false,
      csDays: [
        { name: 'SUN', selected: false },
        { name: 'MON', selected: false },
        { name: 'TUE', selected: false },
        { name: 'WED', selected: false },
        { name: 'THU', selected: false },
        { name: 'FRI', selected: false },
        { name: 'SAT', selected: false },
      ],
      salesDays: [
        { name: 'SUN', selected: false },
        { name: 'MON', selected: false },
        { name: 'TUE', selected: false },
        { name: 'WED', selected: false },
        { name: 'THU', selected: false },
        { name: 'FRI', selected: false },
        { name: 'SAT', selected: false },
      ],
      operationsDays: [
        { name: 'SUN', selected: false },
        { name: 'MON', selected: false },
        { name: 'TUE', selected: false },
        { name: 'WED', selected: false },
        { name: 'THU', selected: false },
        { name: 'FRI', selected: false },
        { name: 'SAT', selected: false },
      ],
      defaultOptions: {
        animationData: Pc,
      },
    }
  },
  watch: {
    settings: {
      handler(newValue, oldValue) {
        if (!isEmpty(oldValue)) {
          this.settingsChange = true
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.settings = { ...this.settingsGetter }
    this.csDays.forEach(e => {
      e.selected = (this.settings.cs.auto_pilot_days || []).includes(e.name)
    })
    this.salesDays.forEach(e => {
      e.selected = (this.settings.sales.auto_pilot_days || []).includes(e.name)
    })
    this.operationsDays.forEach(e => {
      e.selected = (this.settings.operations.auto_pilot_days || []).includes(
        e.name
      )
    })
    this.csPolicyGroups = this.filterCsPolicyGroups()
    this.salesPolicyGroups = this.filterSalesPolicyGroups()
    this.operationsPolicyGroups = this.filterOperationsPolicyGroups()
    this.timeEntries = this.generateTimeEntries()
    this.aiAgentTones = this.generateAiAgentTones()
    this.aiAnswerOnlyAfter = this.generateAiAnswerOnlyAfter()
    this.aiCsNotifySlackChannels = this.generateAiCsNotifySlackChannels()
    this.aiSalesNotifySlackChannels = this.generateAiSalesNotifySlackChannels()
    this.aiDispatchNotifySlackChannels =
      this.generateAiDispatchNotifySlackChannels()
  },
  computed: {
    settingsGetter() {
      return this.$store.state.AiConfigs
    },
    hasAiConfig() {
      return this.BoomUser || this.hasAbility('ai-config')
    },
    csPolicyTabs() {
      return Array.from(
        new Set(this.settings.cs_policies_with_defaults.map(e => e.topic))
      )
    },
    salesPolicyTabs() {
      return Array.from(
        new Set(this.settings.sales_policies_with_defaults.map(e => e.topic))
      )
    },
    operationsPolicyTabs() {
      return Array.from(
        new Set(
          this.settings.operations_policies_with_defaults.map(e => e.topic)
        )
      )
    },
  },
  methods: {
    policyChanged(policy) {
      return policy.changed && !this.policyUpdated
    },
    filterCsPolicyGroups() {
      return this.settings.cs_policies_with_defaults.filter(
        e => e.topic === this.csPolicyTabs[this.selectedTab] && e.subject
      )
    },
    filterSalesPolicyGroups() {
      return this.settings.sales_policies_with_defaults.filter(
        e => e.topic === this.salesPolicyTabs[this.selectedTab] && e.subject
      )
    },
    filterOperationsPolicyGroups() {
      return this.settings.operations_policies_with_defaults.filter(
        e =>
          e.topic === this.operationsPolicyTabs[this.selectedTab] && e.subject
      )
    },
    save() {
      const {
        cs_policies,
        cs_tools,
        cs_policies_with_defaults,
        cs_agent_instructions,
        sales_tools,
        sales_policies,
        sales_policies_with_defaults,
        sales_agent_instructions,
        operations_policies,
        operations_tools,
        operations_policies_with_defaults,
        operations_agent_instructions,
        ...permittedUpdateSettings
      } = this.settings
      this.$store.dispatch('updateAiConfigs', permittedUpdateSettings)
      this.settingsChange = false
    },
    async savePolicy(policy) {
      axios.put(`/api/policies/${policy.id}`, { policy: policy })
      policy.changed = false
      this.policyUpdated = true
    },
    policyChange(policy) {
      policy.changed = true
      this.policyUpdated = false
    },
    csPolicyTopicChange() {
      this.csPolicyGroups = this.filterCsPolicyGroups()
    },
    salesPolicyTopicChange() {
      this.salesPolicyGroups = this.filterSalesPolicyGroups()
    },
    operationsPolicyTopicChange() {
      this.operationsPolicyGroups = this.filterOperationsPolicyGroups()
    },
    async generateAllCsPolicies() {
      this.settings.cs_policy_generation_in_progress = true
      const url = '/api/policies/generate?department=cs'
      axios.post(url)
      this.pullAllPolicies('cs')
    },
    async generateCsPolicy(policy) {
      this.$set(policy, 'generation_in_progress', true)
      const url = `/api/policies/generate?department=cs&id=${policy.id}`
      axios.post(url)
      this.pullPolicy(policy)
    },
    async pullAllPolicies(department) {
      let intervalId

      const fetchAllPoliesData = async () => {
        try {
          const response = await axios.get(`/api/policies`)
          const targetPolicies = response.data.policies

          targetPolicies.forEach(policy => {
            let targetPolicy
            if (department === 'cs') {
              targetPolicy = this.csPolicyGroups.find(e => e.id === policy.id)
            } else if (department === 'sales') {
              targetPolicy = this.salesPolicyGroups.find(
                e => e.id === policy.id
              )
            } else if (department === 'operations') {
              targetPolicy = this.operationsPolicyGroups.find(
                e => e.id === policy.id
              )
            }
            if (
              policy.generation_in_progress !==
              targetPolicy.generation_in_progress
            ) {
              this.updatePolicyData(targetPolicy, policy)
            }
          })

          if (
            targetPolicies.select(e => e.generation_in_progress).length === 0
          ) {
            clearInterval(intervalId)
            return
          }
        } catch (error) {
          console.error('Error fetching policy data:', error)
        }
      }

      intervalId = setInterval(fetchAllPoliesData, 5000)
    },
    updatePolicyData(policy, response_data) {
      this.$set(policy, 'description', response_data.policy.description)
      this.$set(
        policy,
        'generation_in_progress',
        response_data.policy.generation_in_progress
      )
    },
    async pullPolicy(policy) {
      let intervalId

      const fetchPolicyData = async () => {
        try {
          const response = await axios.get(`/api/policies/${policy.id}`)
          this.updatePolicyData(policy, response.data)

          if (!policy.generation_in_progress) {
            clearInterval(intervalId)
            console.log('Generation complete. Interval cleared.')
          }
        } catch (error) {
          console.error('Error fetching policy data:', error)
        }
      }

      intervalId = setInterval(fetchPolicyData, 5000)
    },
    async generateAllSalesPolicies() {
      this.settings.sales_policy_generation_in_progress = true
      const url = '/api/policies/generate?department=sales'
      axios.post(url)
      this.pullAllPolicies('sales')
    },
    async generateSalesPolicy(policy) {
      this.$set(policy, 'generation_in_progress', true)
      const url = `/api/policies/generate?department=sales&id=${policy.id}`
      axios.post(url)
      this.pullPolicy(policy)
    },
    async generateAllDispatchPolicies() {
      this.settings.operations_policy_generation_in_progress = true
      const url = '/api/policies/generate?department=operations'
      axios.post(url)
      this.pullAllPolicies('operations')
    },
    async generateDispatchPolicy(policy) {
      this.$set(policy, 'generation_in_progress', true)
      const url = `/api/policies/generate?department=operations&id=${policy.id}`
      axios.post(url)
      this.pullPolicy(policy)
    },
    toggleCsDay(day) {
      if (!this.settings.cs.auto_pilot) {
        return
      }
      day.selected = !day.selected
      this.settings.cs.auto_pilot_days = this.csDays
        .filter(e => e.selected)
        .map(e => e.name)
      this.settingsChange = true
    },
    toggleSalesDay(day) {
      if (!this.settings.sales.auto_pilot) {
        return
      }
      day.selected = !day.selected
      this.settings.sales.auto_pilot_days = this.salesDays
        .filter(e => e.selected)
        .map(e => e.name)
      this.settingsChange = true
    },
    toggleDispatchDay(day) {
      if (!this.settings.operations.auto_pilot) {
        return
      }
      day.selected = !day.selected
      this.settings.operations.auto_pilot_days = this.operationsDays
        .filter(e => e.selected)
        .map(e => e.name)
      this.settingsChange = true
    },
    dayItemClassFor(day) {
      if (day.name === 'SUN') {
        return 'day-item first-day-item'
      } else if (day.name === 'SAT') {
        return 'day-item last-day-item'
      } else {
        return 'day-item'
      }
    },
    generateTimeEntries() {
      let entries = []
      for (let i = 0; i < 24; i++) {
        let hour = i < 10 ? `0${i}` : `${i}`
        entries.push(`${hour}:00`)
        entries.push(`${hour}:30`)
      }
      return entries
    },
    generateAiAgentTones() {
      return [
        'Relaxed, Sales-Oriented',
        'Professional, Informative',
        'Friendly, Customer Service',
        'Empathetic, Supportive',
        'Enthusiastic, Motivational',
        'Calm, Reassuring',
        'Authoritative, Commanding',
        'Playful, Entertaining',
        'Sincere, Heartfelt',
        'Analytical, Data-Driven',
      ]
    },
    generateAiAnswerOnlyAfter() {
      return [
        'Immediate',
        'Brief Thinking Period (1-2 seconds)',
        'Post-Analysis Delay (3-5 seconds)',
        'Randomized Response Time',
      ]
    },
    generateAiCsNotifySlackChannels() {
      return [
        'High Priority Ticket Alerts',
        'Customer Satisfaction Scores',
        'Frequent Issue Tracking',
        'Knowledge Base Updates',
        'Weekly Support Summary',
      ]
    },
    generateAiSalesNotifySlackChannels() {
      return [
        'AI Sales Inquiries',
        'Pipeline Status Updates',
        'Competitor Activity Insights',
      ]
    },
    generateAiDispatchNotifySlackChannels() {
      return ['AI Dispatch']
    },
    async editPolicyGroup(department) {
      let topic
      if (department == 'cs') {
        topic = this.csPolicyTabs[this.selectedTab]
      } else if (department == 'sales') {
        topic = this.salesPolicyTabs[this.selectedTab]
      } else if (department == 'operations') {
        topic = this.operationsPolicyTabs[this.selectedTab]
      }

      this.$store.commit('showModal', {
        name: 'AddPolicyModal',
        maxWidth: 400,
        props: {
          edit: true,
          group: true,
          policy: {
            department: department,
          },
          current_topic: topic,
          beforeSubmitEvent: async () => {
            this.tabsUpdating = true
          },
          afterSubmitEvent: async () => {
            await this.$store.dispatch('getAiConfigs')
            this.settings = { ...this.settingsGetter }
            this.csPolicyGroups = this.filterCsPolicyGroups()
            this.salesPolicyGroups = this.filterSalesPolicyGroups()
            this.operationsPolicyGroups = this.filterOperationsPolicyGroups()
            this.tabsUpdating = false
          },
        },
      })
    },
    async editPolicy(policyGroup) {
      this.$store.commit('showModal', {
        name: 'AddPolicyModal',
        maxWidth: 400,
        props: {
          policy: policyGroup,
          beforeSubmitEvent: async () => {
            this.tabsUpdating = true
          },
          afterSubmitEvent: async () => {
            await this.$store.dispatch('getAiConfigs')
            this.settings = { ...this.settingsGetter }
            this.csPolicyGroups = this.filterCsPolicyGroups()
            this.salesPolicyGroups = this.filterSalesPolicyGroups()
            this.operationsPolicyGroups = this.filterOperationsPolicyGroups()
            this.tabsUpdating = false
          },
        },
      })
    },
    async addPolicyGroup(department) {
      this.$store.commit('showModal', {
        name: 'AddPolicyModal',
        maxWidth: 400,
        props: {
          policy: {
            department: department,
          },
          beforeSubmitEvent: async () => {
            this.tabsUpdating = true
          },
          afterSubmitEvent: async () => {
            await this.$store.dispatch('getAiConfigs')
            this.settings = { ...this.settingsGetter }
            if (department == 'cs') {
              this.selectedTab = this.csPolicyTabs.length - 1
            } else if (department == 'sales') {
              this.selectedTab = this.salesPolicyTabs.length - 1
            } else if (department == 'operations') {
              this.selectedTab = this.operationsPolicyTabs.length - 1
            }
            this.csPolicyGroups = this.filterCsPolicyGroups()
            this.salesPolicyGroups = this.filterSalesPolicyGroups()
            this.operationsPolicyGroups = this.filterOperationsPolicyGroups()
            this.tabsUpdating = false
          },
        },
      })
    },
    async addPolicy(department) {
      let topic
      if (department == 'cs') {
        topic = this.csPolicyTabs[this.selectedTab]
      } else if (department == 'sales') {
        topic = this.salesPolicyTabs[this.selectedTab]
      } else if (department == 'operations') {
        topic = this.operationsPolicyTabs[this.selectedTab]
      }
      this.$store.commit('showModal', {
        name: 'AddPolicyModal',
        maxWidth: 400,
        props: {
          policy: {
            topic: topic,
            department: department,
          },
          beforeSubmitEvent: async () => {
            this.tabsUpdating = true
          },
          afterSubmitEvent: async () => {
            await this.$store.dispatch('getAiConfigs')
            this.settings = { ...this.settingsGetter }
            this.csPolicyGroups = this.filterCsPolicyGroups()
            this.salesPolicyGroups = this.filterSalesPolicyGroups()
            this.operationsPolicyGroups = this.filterOperationsPolicyGroups()
            this.tabsUpdating = false
          },
        },
      })
    },
    removePolicyGroupText(department) {
      let topic
      if (department == 'cs') {
        topic = this.csPolicyTabs[this.selectedTab]
      } else if (department == 'sales') {
        topic = this.salesPolicyTabs[this.selectedTab]
      } else if (department == 'operations') {
        topic = this.operationsPolicyTabs[this.selectedTab]
      }

      return `Are you sure you want remove '${topic}' Policy Group?`
    },
    async removePolicyGroup(department) {
      this.tabsUpdating = true

      let topic
      if (department == 'cs') {
        topic = this.csPolicyTabs[this.selectedTab]
      } else if (department == 'sales') {
        topic = this.salesPolicyTabs[this.selectedTab]
      } else if (department == 'operations') {
        topic = this.operationsPolicyTabs[this.selectedTab]
      }
      await axios.post('/api/policies/delete-policy-group', {
        policy: {
          department: department,
          topic: topic,
        },
      })
      await this.$store.dispatch('getAiConfigs')
      this.settings = { ...this.settingsGetter }
      this.selectedTab = 0
      this.csPolicyGroups = this.filterCsPolicyGroups()
      this.salesPolicyGroups = this.filterSalesPolicyGroups()
      this.operationsPolicyGroups = this.filterOperationsPolicyGroups()
      this.tabsUpdating = false
    },
    removePolicyText(subject) {
      return `Are you sure you want remove '${subject}' Policy?`
    },
    async removePolicy(policy) {
      this.tabsUpdating = true
      await axios.delete(`/api/policies/${policy.id}`)
      await this.$store.dispatch('getAiConfigs')
      this.settings = { ...this.settingsGetter }
      this.csPolicyGroups = this.filterCsPolicyGroups()
      this.salesPolicyGroups = this.filterSalesPolicyGroups()
      this.operationsPolicyGroups = this.filterOperationsPolicyGroups()
      this.tabsUpdating = false
    },
  },
}
</script>
<style>
.policy-tab .v-tabs-bar {
  background-color: white !important;
  margin-top: 35px;
}
.rotating {
  animation: rotation 2s infinite linear;
}
.day-item-selected {
  background-color: var(--v-primary-darken2) !important;
}
.day-item-disabled {
  cursor: default !important;
  opacity: 0.3;
}
.first-day-item {
  border-top-left-radius: 13px;
  border-bottom-left-radius: 13px;
}
.last-day-item {
  border-top-right-radius: 13px;
  border-bottom-right-radius: 13px;
}
.day-item-enabled {
  cursor: pointer;
}
.day-item-enabled:hover {
  background-color: var(--v-primary-darken1) !important;
}
.day-item {
  color: white;
  padding: 20px;
  background-color: var(--v-light100-darken1);
  border: 0.5px solid white;
}
.centerize {
  padding: 6px;
  top: 25%;
  left: 50%;
}
.listings-hint {
  color: red;
  margin-left: 24px;
  font-size: 12px;
  margin-top: 4px;
}
.bold {
  font-weight: bold;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
</style>
