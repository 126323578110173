<template>
  <v-container v-if="currentPricing && calendarOnly">
    <v-layout wrap mt-2>
      <v-flex xs6 sm2>
        <v-text-field
          v-model="currentPricing.base_price"
          prepend-icon="money"
          label="Base price"
          required
          @change="sendPreview"
        ></v-text-field>
      </v-flex>
      <v-flex xs6 sm2>
        <v-text-field
          v-model="currentPricing.min_price_weekend"
          prepend-icon="vertical_align_bottom"
          label="Min price weekends"
          required
          @change="sendPreview"
        ></v-text-field>
      </v-flex>
      <v-flex xs6 sm2>
        <v-text-field
          v-model="currentPricing.min_price_weekday"
          prepend-icon="vertical_align_bottom"
          label="Min price weekdays"
          required
          @change="sendPreview"
        ></v-text-field>
      </v-flex>
      <v-flex xs6 sm2>
        <v-text-field
          v-model="currentPricing.max_price"
          prepend-icon="vertical_align_top"
          label="Max price"
          required
          @change="sendPreview"
        ></v-text-field>
      </v-flex>
      <v-layout align-center justify-end class="mr-3">
        <v-flex xs6 sm2 class="mr-4">
          <v-btn
            color="primary"
            class="white--text"
            small
            depressed
            @click="showExtraFilters = !showExtraFilters"
          >
            <v-icon :class="showExtraFilters ? 'fa-rotate-90' : ''" left small
              >$filters
            </v-icon>
            Filters
          </v-btn>
        </v-flex>
        <v-flex xs6 sm2>
          <v-btn v-if="enableSave" small class="cyan-icon" @click="save()"
            >Save</v-btn
          >
        </v-flex>
        <v-flex v-show="showExtraFilters">
          <pricing-page-extra-factors
            v-if="calendar"
            :save="save"
            :get-pricing-preview="sendPreview"
          ></pricing-page-extra-factors>
        </v-flex>
      </v-layout>
    </v-layout>
    <pricing-calendar
      v-if="currentPricing.days_rates && !loading"
      :calendar.sync="calendar"
    />
  </v-container>
  <v-container
    v-else-if="currentPricing && !calendarOnly"
    row
    fluid
    class="comp-wrapper"
  >
    <v-layout wrap>
      <v-flex xs12 class="ml-3 mr-3 grey lighten-4 pa-4">
        <v-layout wrap mt-2 align-center>
          <v-layout column>
            <div class="grey-text">
              <v-avatar rounded :size="70">
                <v-img :src="currentPricing.main_listing.picture" />
              </v-avatar>
              {{ currentPricing.address }}
              <span v-if="isMultiUnit" class="cyan-icon bolder pl-2"
                >#Multi Unit</span
              >
              <span class="ml-3">
                <v-icon class="icon alt purple-icon">fas fa-bed</v-icon>:
                {{ currentPricing.beds }}
                <v-icon class="icon alt purple-icon ml-3">fas fa-shower</v-icon
                >:
                {{ currentPricing.baths }}
              </span>
            </div>
          </v-layout>

          <v-spacer></v-spacer>
          <v-flex class="xs2">
            <v-icon
              small
              :color="currentPricing.listed ? 'success' : 'secondary'"
            >
              {{
                currentPricing.listed
                  ? 'fas fa-check-circle'
                  : 'fas fa-times-circle'
              }}
            </v-icon>
            <span
              class="pl-2"
              :class="currentPricing.listed ? 'green--text' : 'red--text'"
            >
              {{ currentPricing.listed ? $t('Listed') : $t('Unlisted') }}
            </span>
            <confirmation-modal
              :text="`Are you sure you want to ${
                !currentPricing.activated ? 'Deactivate' : 'Activate'
              } pricing sync?`"
              title="Pricing sync"
              @action="save"
              @cancel="currentPricing.activated = !currentPricing.activated"
            >
              <template #activator="{ on }">
                <v-switch
                  v-model="currentPricing.activated"
                  :class="currentPricing.activated ? 'cyan-icon' : 'grey-text'"
                  :label="'SYNC: ' + (currentPricing.activated ? 'ON' : 'OFF')"
                  :input-value="currentPricing.activated"
                  v-on="on"
                />
              </template>
            </confirmation-modal>
          </v-flex>
        </v-layout>
        <v-layout v-if="purchasePrice">
          <stat-card
            label="Listing cost"
            :value="dollarFormatter(purchasePrice, 0)"
          />
          <stat-card
            label="Yearly ADR"
            :value="dollarFormatter(currentPricing.main_listing.ytd_adr, 0)"
          />
          <stat-card
            label="Yearly Occ"
            :value="toPercent(currentPricing.main_listing.ytd_occupancy, 0)"
          />
          <stat-card
            label="Ytd Cash"
            :value="toPercent(currentPricing.main_listing.ytd_cash_on_cash, 0)"
          />
        </v-layout>
        <v-layout wrap mt-2>
          <v-flex xs6 sm2>
            <v-text-field
              v-model="currentPricing.base_price"
              prepend-icon="money"
              label="Base price"
              required
              @change="sendPreview"
            ></v-text-field>
          </v-flex>
          <v-flex xs6 sm2>
            <v-text-field
              v-model="currentPricing.min_price_weekend"
              prepend-icon="vertical_align_bottom"
              label="Min price weekends"
              required
              @change="sendPreview"
            ></v-text-field>
          </v-flex>
          <v-flex xs6 sm2>
            <v-text-field
              v-model="currentPricing.min_price_weekday"
              prepend-icon="vertical_align_bottom"
              label="Min price weekdays"
              required
              @change="sendPreview"
            ></v-text-field>
          </v-flex>
          <v-flex xs6 sm2>
            <v-text-field
              v-model="currentPricing.max_price"
              prepend-icon="vertical_align_top"
              label="Max price"
              required
              @change="sendPreview"
            ></v-text-field>
          </v-flex>
          <v-layout align-center justify-end class="mr-3">
            <v-flex xs6 sm2 class="mr-4">
              <v-btn
                color="primary"
                class="white--text"
                small
                depressed
                @click="showExtraFilters = !showExtraFilters"
              >
                <v-icon
                  :class="showExtraFilters ? 'fa-rotate-90' : ''"
                  left
                  small
                  >$filters
                </v-icon>
                Filters
              </v-btn>
            </v-flex>
            <v-flex xs6 sm2>
              <v-btn v-if="enableSave" small class="cyan-icon" @click="save()"
                >Save</v-btn
              >
            </v-flex>
          </v-layout>
        </v-layout>
      </v-flex>
      <v-flex v-show="showExtraFilters" xs8 offset-xs2 class="pt-3">
        <pricing-page-extra-factors
          :get-pricing-preview="sendPreview"
          :save="save"
        ></pricing-page-extra-factors>
      </v-flex>
      <v-flex xs12 sm4 md5 class="mt-3">
        <v-col v-if="currentPricing.airbnb_link" cols="6">
          <a target="_blank" :href="currentPricing.airbnb_link">Airbnb link</a>
        </v-col>
        <v-col v-if="currentPricing.airbnb_score" cols="6">
          <b>Score: </b
          ><span>{{ roundWith(currentPricing.airbnb_score, 2) }}</span>
        </v-col>
        <v-col cols="12">
          <score-bar
            :occupancy-month="currentPricing.yearly_stats['30_days']"
            :occupancy-three-month="currentPricing.yearly_stats['90_days']"
            :occupancy-two-weeks="currentPricing.yearly_stats['14_days']"
            :occupancy-month-back="currentPricing.yearly_stats['30_days_back']"
            :occupancy-three-month-back="
              currentPricing.yearly_stats['90_days_back']
            "
            :occupancy-two-weeks-back="
              currentPricing.yearly_stats['14_days_back']
            "
            :score="currentPricing.yearly_stats.health"
          ></score-bar>
        </v-col>
        <v-col v-if="currentPricing.pricing_logs" class="sm-3 mt-3" wrap>
          <v-flex>
            <v-expansion-panels>
              <v-expansion-panel key="final" class="pb-2">
                <v-expansion-panel-header class="grey--text">
                  Log ({{ Object.keys(currentPricing.pricing_logs).length }})
                  <span v-if="currentPricing.pricing_logs.length">
                    <span class="bolder ml-3"
                      >{{
                        parseDate(currentPricing.pricing_logs[0]['created_at'])
                      }}
                      - {{ currentPricing.pricing_logs[0]['log_type'] }}
                    </span>
                    {{
                      truncateBy(
                        currentPricing.pricing_logs[0]['description'],
                        100
                      )
                    }}
                    by {{ currentPricing.pricing_logs[0]['done_by'] }}</span
                  >
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-divider></v-divider>
                  <v-flex
                    v-for="(logMessage, key) in currentPricing.pricing_logs"
                    :key="key"
                    xs12
                    class="grey--text"
                  >
                    <v-layout wrap>
                      <v-flex xs6 class="purple-icon big-text bolder pl-2"
                        >{{ parseDate(logMessage.created_at) }}
                      </v-flex>
                      <v-flex
                        xs6
                        class="cyan-icon big-text bolder pr-2"
                        style="text-align: right"
                        >{{ logMessage.done_by }}
                      </v-flex>
                      <v-flex xs12 class="align-left pa-2 bolder">
                        {{ logMessage.description }}
                      </v-flex>
                    </v-layout>
                    <v-divider></v-divider>
                  </v-flex>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-flex>
        </v-col>
        <v-col xs12 sm7>
          <pricing-monthly v-if="$store.state.currentPricingMonthly">
          </pricing-monthly>
        </v-col>
        <v-col xs12 sm7 mt-1>
          <pricing-yearly v-if="$store.state.currentPricingYearly">
          </pricing-yearly>
        </v-col>
      </v-flex>
      <v-flex xs12 sm7 small="6" mt-3>
        <pricing-calendar
          v-if="currentPricing.days_rates && !loading"
          :calendar.sync="calendar"
        />
        <velocity-summary
          :velocity-data="currentPricing.velocity_data"
          :show-table="true"
        ></velocity-summary>
      </v-flex>
    </v-layout>
    <v-layout
      v-if="currentPricing && !isEmpty(currentPricing.city_revenue_per_bed)"
      wrap
    >
      <v-flex xs10 offset-xs1 mt-2>
        <v-layout wrap class="comp-wrapper">
          <v-flex>
            <v-layout wrap class="bb-1 mb-1 mt-1">
              <v-layout wrap class="center-text bt-1 br-1 bl-1" justify-center>
                <v-flex xs4 class="br-1">
                  50:
                  <span class="red-warn bolder">{{
                    calcTotal(
                      currentPricing.city_revenue_per_bed,
                      '50'
                    ).toLocaleString('en')
                  }}</span>
                </v-flex>
                <v-flex xs4 class="br-1">
                  75:
                  <span class="yellow-health bolder">{{
                    calcTotal(
                      currentPricing.city_revenue_per_bed,
                      '75'
                    ).toLocaleString('en')
                  }}</span>
                </v-flex>
                <v-flex xs4>
                  90:
                  <span class="green-health bolder">{{
                    calcTotal(
                      currentPricing.city_revenue_per_bed,
                      '90'
                    ).toLocaleString('en')
                  }}</span>
                </v-flex>
              </v-layout>
              <v-flex
                v-for="(monthData, key) in currentPricing.city_revenue_per_bed"
                :key="key"
                xs12
                sm3
                class="comp-wrapper center-text"
              >
                {{ monthName(monthData.month) }} <br />
                <span class="yellow-health mr-1 bolder">
                  50% : {{ monthData.percentiles['50'] }}
                </span>
                <span class="cyan-icon mr-1 bolder">
                  75% : {{ monthData.percentiles['75'] }}
                </span>
                <span class="green-health bolder">
                  90% : {{ monthData.percentiles['90'] }}
                </span>
                <v-layout wrap>
                  <v-flex xs12>
                    <v-layout wrap light-grey purple-icon>
                      <v-flex xs3 class="center-text"> Year </v-flex>
                      <v-flex xs3 class="center-text"> Total </v-flex>
                      <v-flex xs3 class="center-text"> Confirmed </v-flex>
                      <v-flex xs3 class="center-text"> Available </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12 class="bb-1">
                    <v-layout wrap>
                      <v-flex xs3 class="center-text">
                        {{ yearNow() - 1 }}
                      </v-flex>
                      <v-flex xs3 class="center-text">
                        {{ monthData.our_last_year }}
                      </v-flex>
                      <v-flex xs3 class="center-text">
                        {{
                          currentPricing.stats['summary'][yearNow() - 1][
                            'months'
                          ][monthData.month - 1].confirmed_sum
                        }}
                      </v-flex>
                      <v-flex xs3 class="center-text">
                        {{
                          currentPricing.stats['summary'][yearNow() - 1][
                            'months'
                          ][monthData.month - 1].unconfirmed_sum
                        }}
                      </v-flex>
                    </v-layout>
                  </v-flex>

                  <v-flex xs12 class="bb-1">
                    <v-layout wrap>
                      <v-flex xs3 class="center-text yellowish">
                        {{ yearNow() }}
                      </v-flex>
                      <v-flex xs3 class="center-text yellowish">
                        {{ monthData.our_current_year }}
                      </v-flex>
                      <v-flex xs3 class="center-text yellowish">
                        {{
                          currentPricing.stats['summary'][yearNow()]['months'][
                            monthData.month - 1
                          ].confirmed_sum
                        }}
                      </v-flex>
                      <v-flex xs3 class="center-text yellowish">
                        {{
                          currentPricing.stats['summary'][yearNow()]['months'][
                            monthData.month - 1
                          ].unconfirmed_sum
                        }}
                      </v-flex>
                    </v-layout>
                  </v-flex>

                  <v-flex xs12 class="bb-1">
                    <v-layout wrap>
                      <v-flex xs3 class="center-text">
                        {{ yearNow() + 1 }}
                      </v-flex>
                      <v-flex xs3 class="center-text">
                        {{
                          currentPricing.stats['summary'][yearNow() + 1][
                            'months'
                          ][monthData.month - 1].unconfirmed_sum +
                          currentPricing.stats['summary'][yearNow() + 1][
                            'months'
                          ][monthData.month - 1].confirmed_sum
                        }}
                      </v-flex>
                      <v-flex xs3 class="center-text">
                        {{
                          currentPricing.stats['summary'][yearNow() + 1][
                            'months'
                          ][monthData.month - 1].confirmed_sum
                        }}
                      </v-flex>
                      <v-flex xs3 class="center-text">
                        {{
                          currentPricing.stats['summary'][yearNow() + 1][
                            'months'
                          ][monthData.month - 1].unconfirmed_sum
                        }}
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import PricingMonthly from 'components/pricing-monthly'
import PricingYearly from 'components/pricing-yearly'
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import PricingCalendar from 'components/calendar/pricing-calendar'
import VelocitySummary from 'components/velocity-summary'
import ScoreBar from './score-bar'
import StatCard from './common/stat-card'
import FormattersMixin from 'components/mixins/formatters-mixin'
import get from 'lodash/fp/get'
import purchasePrice from '@/assets/icons/purchase-price.vue'
import PricingPageExtraFactors from 'components/pricing-page-extra-factors.vue'
import ConfirmationModal from 'components/modals/confirmation-modal.vue'

export default {
  name: 'PricingPage',

  components: {
    ConfirmationModal,
    PricingPageExtraFactors,
    StatCard,
    ScoreBar,
    PricingCalendar,
    PricingMonthly,
    PricingYearly,
    VelocitySummary,
  },
  mixins: [CommonFunctions, PermissionsMixin, FormattersMixin],
  data: function () {
    return {
      showExtraFilters: false,
      enableSave: false,
      calendar: null,
    }
  },
  props: ['calendarOnly'],
  mounted() {
    this.$store.dispatch('getPricingStrategies')
  },
  computed: {
    isMultiUnit() {
      return (
        this.currentPricing.multi_unit_pricings &&
        this.currentPricing.multi_unit_pricings.length > 0
      )
    },
    currentPricing() {
      return this.$store.state.currentPricing
    },
    purchasePrice() {
      return get('main_listing.purchase_price', this.currentPricing)
    },
  },
  methods: {
    calcTotal(monthsData, precentile) {
      let res = 0
      monthsData.forEach(month => {
        res += month.percentiles[precentile]
      })
      return res
    },
    back() {
      this.$router.push('/dashboard/pricing-panel')
    },
    save() {
      this.enableSave = false
      this.$store.dispatch('savePricing', {
        pricing: this.currentPricing,
        title: this.calendar.view.title,
      })
    },
    sendPreview(options = {}) {
      this.enableSave = true
      this.$store.dispatch('getPricingPreview', {
        pricing: this.currentPricing,
        title: this.calendar.view.title,
        strat_id: options.strat_id,
      })
    },
  },
}
</script>
