<template>
  <v-container>
    <v-progress-linear
      v-show="listingLoading"
      color="secondary"
      style="margin: 0"
      :indeterminate="true"
    />
    <v-row>
      <v-col cols="12">
        <v-row no-gutters class="justify-space-between">
          <v-col cols="auto" class="text-h6"> Listing Search</v-col>
          <reservation-create-modal
            v-if="hasAbility(['create-reservation']) || isHotelReception"
            class="ml-auto"
            @oncreate="createReservation"
          />
        </v-row>
      </v-col>
      <v-col cols="12" md="4">
        <date-picker
          v-model="range"
          :range="true"
          :hide-details="true"
          label="Dates"
          @change="getListings"
        />
      </v-col>
      <v-col v-if="!resortsZone" cols="6" md="4">
        <v-select
          v-model="cityName"
          label="City"
          :items="listingCities"
          outlined
          :hide-details="true"
          dense
          class="cyan-icon"
          prepend-inner-icon="accessibility"
          @change="getListings"
        />
      </v-col>
      <v-col cols="6" md="4">
        <v-select
          v-model="guestAmount"
          label="Guests"
          :items="[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17]"
          outlined
          :hide-details="true"
          dense
          class="cyan-icon"
          prepend-inner-icon="accessibility"
          @change="getListings"
        />
      </v-col>
      <v-col cols="6" md="4">
        <v-text-field
          v-model="fromPrice"
          label="From price"
          hide-details
          outlined
          dense
          @change="getListings"
        >
          <template #prepend-inner
            ><v-icon>${{ currency }}</v-icon>
          </template>
        </v-text-field>
      </v-col>
      <v-col cols="6" md="4">
        <v-text-field
          v-model="toPrice"
          label="To price"
          hide-details
          dense
          outlined
          @change="getListings"
        >
          <template #prepend-inner
            ><v-icon>${{ currency }}</v-icon>
          </template>
        </v-text-field>
      </v-col>
      <v-col v-if="!resortsZone" cols="auto">
        <v-checkbox
          v-model="pool"
          label="Must have pool"
          class="checkbox-txt ma-0"
          hide-details
          @change="getListings"
        />
      </v-col>
      <v-col v-if="resortsZone" cols="auto">
        <v-autocomplete
          v-model="multiId"
          label="Unit type"
          multiple
          outlined
          :items="multiUnitsItems"
          dense
          hide-details
          @change="getListings"
        />
      </v-col>
      <v-col v-if="!resortsZone" cols="auto">
        <v-checkbox
          v-model="hottub"
          label="Must have hottub"
          class="checkbox-txt ma-0"
          hide-details
          @change="getListings"
        />
      </v-col>
    </v-row>

    <v-data-table
      :headers="headers"
      :items="optionalListings"
      class="elevation-1"
      :options.sync="pagination"
    >
      <template #item.actions="{ item }">
        <div v-if="canReserve">
          <reservation-create-modal
            v-if="hasAbility(['create-reservation']) || isHotelReception"
            class="ml-auto"
            :pre-filled="{
              range: range,
              listingId: item.id,
              guests_count: guestAmount,
            }"
            @oncreate="createReservation"
          >
            <template #customBtn="{ on }">
              <v-btn text color="blue" small v-on="on">Reserve</v-btn>
            </template>
          </reservation-create-modal>
        </div>
      </template>
      <template #item.nickname="{ item }">
        <div>{{ item.nickname }}</div>
      </template>
      <template #item.city_name="{ item }">
        <div>{{ item.city_name }}</div>
      </template>
      <template #item.unit="{ item }">
        <div>{{ multiUnitsMap[item.container_multi_id] }}</div>
      </template>
      <template #item.accommodates="{ item }">
        <div>{{ item.accommodates }}</div>
      </template>
      <template #item.discount="{ item }">
        <div>
          % {{ item.discount['start'] }} -
          {{ item.discount['max'] }}
        </div>
      </template>
      <template #item.pool="{ item }">
        <v-icon v-if="item.pool" color="primary">done_all </v-icon>
        <v-icon v-else class="purple-icon">close </v-icon>
      </template>
      <template #item.hot_tub="{ item }">
        <v-icon v-if="item.hot_tub" color="primary">done_all </v-icon>
        <v-icon v-else class="purple-icon">close </v-icon>
      </template>
      <template #item.price.total_price="{ item }">
        <div>{{ dollarFormatter(item.price.total_price) }}</div>
      </template>
      <template #item.airbnb="{ item }">
        <div v-if="item.links.Airbnb" class="d-flex align-center">
          <a
            target="_blank"
            class="text-decoration-none"
            :href="item.links.Airbnb"
            ><v-icon small color="info">mdi-open-in-new</v-icon></a
          >
          <copy-clipboard :text="item.links.Airbnb" />
        </div>
      </template>
      <template #item.homeaway="{ item }">
        <div v-if="item.links.HomeAway" class="d-flex align-center">
          <a
            target="_blank"
            class="text-decoration-none"
            :href="item.links.HomeAway"
            ><v-icon small color="info">mdi-open-in-new</v-icon></a
          >
          <copy-clipboard :text="item.links.HomeAway" />
        </div>
      </template>
      <template #item.bookingcom="{ item }">
        <div v-if="item.links['Booking.com']" class="d-flex align-center">
          <a
            target="_blank"
            class="text-decoration-none"
            :href="item.links['Booking.com']"
            ><v-icon small color="info">mdi-open-in-new</v-icon></a
          >
          <copy-clipboard :text="item.links['Booking.com']" />
        </div>
      </template>
      <template #item.designedvrcom="{ item }">
        <div v-if="item.links['Designedvr.com']" class="d-flex align-center">
          <a
            target="_blank"
            class="text-decoration-none"
            :href="item.links['Designedvr.com']"
            ><v-icon small color="info">mdi-open-in-new</v-icon>
          </a>
          <copy-clipboard :text="item.links['Designedvr.com']" />
        </div>
      </template>
    </v-data-table>
    <div
      v-if="!isEmpty(optionalListings) && !listingLoading && !resortsZone"
      class="my-3"
    >
      <v-select
        label="Create message for channel"
        :items="['Airbnb', 'HomeAway', 'Booking.com', 'Designedvr.com']"
        class="cyan-icon"
        dense
        outlined
        prepend-inner-icon="accessibility"
        @change="generateMessageFor($event)"
      />

      <v-textarea
        v-if="generatedMsg"
        v-model="generatedMsg"
        outlined
        dense
        placeholder="message..."
      />
    </div>
  </v-container>
</template>

<script>
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import CopyClipboard from 'components/copy-clipboard'
import ReservationCreateModal from 'components/reservation/reservation-create-modal'
import DatePicker from 'components/form-fields/date-picker'
import FormattersMixin from 'components/mixins/formatters-mixin'
import { mapGetters, mapState } from 'vuex'
import get from 'lodash/fp/get'
import axios from '@/axios/config'
import { keyBy } from 'lodash/fp'

export default {
  components: { DatePicker, ReservationCreateModal, CopyClipboard },
  mixins: [CommonFunctions, PermissionsMixin, FormattersMixin],
  props: ['preConfig', 'canReserve'],
  data() {
    var tomorrow = new Date()
    tomorrow.setDate(new Date().getDate() + 1)
    return {
      fromPrice: 0,
      range: [],
      headers: [],
      toPrice: 10000,
      from: new Date().toISOString().substr(0, 10),
      to: tomorrow.toISOString().substr(0, 10),
      guestAmount: 1,
      menu: false,
      hottub: false,
      pool: false,
      multiId: null,
      cityName: null,
      menu2: false,
      multiUnitsItems: [],
      multiUnitsMap: {},
      pagination: {
        itemsPerPage: 100,
      },
      generatedMsg: null,

      allHeaders: [
        {
          text: 'Listing',
          value: 'nickname',
          align: 'center',
          sortable: true,
        },
        { text: 'City', align: 'center', value: 'city_name', sortable: false },
        {
          text: 'Guests',
          align: 'center',
          value: 'accommodates',
          sortable: false,
        },
        { text: 'Pool', align: 'center', sortable: false, value: 'pool' },
        { text: 'Hot tub', align: 'center', sortable: false, value: 'hot_tub' },
        {
          text: 'Price',
          align: 'center',
          sortable: true,
          value: 'price.total_price',
        },
        { text: 'Airbnb', align: 'center', sortable: false, value: 'airbnb' },
        {
          text: 'HomeAway',
          align: 'center',
          sortable: false,
          value: 'homeaway',
        },
        {
          text: 'Booking.com',
          align: 'center',
          sortable: false,
          value: 'bookingcom',
        },
        {
          text: 'Designedvr.com',
          align: 'center',
          sortable: false,
          value: 'designedvrcom',
        },
      ],
    }
  },
  computed: {
    ...mapGetters(['listingCities', 'currentUser']),
    ...mapState(['optionalListings']),
    resortsZone() {
      return !!this.currentUser.zone && this.currentUser.zone.name === 'Resorts'
    },
    currency() {
      return get('config.currency', window) || 'USD'
    },
  },
  async mounted() {
    const { data } = await axios.get('/api/listings/multi')
    this.multiUnitsMap = data.reduce((obj, item) => {
      obj[item.id] = item.nickname
      return obj
    }, {})
    this.multiUnitsItems = data.map(unit => ({
      text: unit.nickname,
      value: unit.id,
    }))
    this.range = [this.parseDate(this.from), this.parseDate(this.to)]
    if (this.preConfig) {
      this.from = this.preConfig.from
      this.to = this.preConfig.to
      this.range = [this.parseDate(this.from), this.parseDate(this.to)]
      this.guestAmount = this.preConfig.guestAmount
    }
    this.headers = this.headersItems()
    this.getListings()
  },
  methods: {
    headersItems() {
      let headers = []
      if (this.resortsZone) {
        headers = [
          {
            text: 'Listing',
            value: 'nickname',
            align: 'center',
            sortable: true,
          },
          {
            text: 'Unit',
            value: 'unit',
            align: 'center',
            sortable: false,
          },
          {
            text: 'Accommodates',
            align: 'center',
            value: 'accommodates',
            sortable: false,
          },
          {
            text: 'Price',
            align: 'center',
            sortable: true,
            value: 'price.total_price',
          },
          {
            text: 'Booking.com',
            align: 'center',
            sortable: false,
            value: 'bookingcom',
          },
          {
            text: 'Designedvr.com',
            align: 'center',
            sortable: false,
            value: 'designedvrcom',
          },
        ]
      } else {
        headers = this.allHeaders
      }
      if (this.canReserve) {
        headers.unshift({
          text: '',
          align: 'center',
          value: 'actions',
          sortable: false,
        })
      }
      return headers
    },
    generateMessageFor(channel) {
      let msg = ''
      if (channel) {
        this.optionalListings.forEach(listing => {
          if (listing.links[channel]) {
            msg =
              msg +
              `${listing.links[channel]} - ${listing.title} / bedrooms: ${listing.beds} baths: ${listing.baths}\n`
          }
        })
        this.generatedMsg = msg
      }
    },
    createReservation(resId) {
      window.open(`/dashboard/reservation/${resId}`, '_blank')
    },
    getListings() {
      if (this.range && this.guestAmount) {
        this.$store.dispatch('getOptionalListings', {
          from: this.range[0],
          to: this.range[1],
          multi_unit_id: this.multiId,
          guest_amount: this.guestAmount,
          city_name: this.cityName,
          hottub: this.hottub,
          pool: this.pool,
          from_price: this.fromPrice,
          to_price: this.toPrice,
        })
      }
    },
  },
}
</script>
