<template>
  <v-container fluid>
    <v-row class="align-center">
      <v-col cols="12" sm="auto" align="center">
        <v-select
          v-model="filters.source"
          label="Source"
          :items="[
            'All',
            'CSV Import',
            'XERO',
            'QUICKBOOKS',
            'PLAID',
            'INTERNAL',
          ]"
          outlined
          hide-details
          @change="fetchData(1)"
        />
      </v-col>
      <v-col cols="12" sm="3">
        <v-text-field
          v-model="filters.search"
          label="Search..."
          prepend-inner-icon="search"
          outlined
          clearable
          hide-details
          @input="fetchDataDebounce(1)"
        />
      </v-col>
      <v-col cols="12" sm="auto">
        <v-btn color="primary" @click="addNewAccount">
          <v-icon>mdi-plus</v-icon>
          Add Internal Account
        </v-btn>
      </v-col>
      <plaid-link :update="true" />
      <v-col cols="12" sm="auto">
        <v-checkbox
          v-model="filters.only_connected"
          label="Only Connected"
          hide-details
          class="d-inline-block mt-0"
          @change="fetchData(1)"
        />
      </v-col>
    </v-row>
    <v-row class="mt-5">
      <v-data-table
        :headers="tableHeaders"
        :items="items"
        :options="pagination"
        :server-items-length="pagination.itemsLength"
        :loading="fetchingData"
        class="elevation-1 w-100 mx-3"
        :footer-props="{
          'items-per-page-options': [10, 25, 50, 100],
        }"
        @pagination="paginationChanged($event)"
      >
        <template #item.account_number="{ item }">
          <div>
            <v-text-field
              class="input-auto"
              :value="item.account_number"
              dense
              flat
              hide-details
              :readonly="item.source === 'XERO'"
              @change="updateAccount({ id: item.id, account_number: $event })"
            />
          </div>
        </template>
        <template #item.routing_number="{ item }">
          <div>
            <v-text-field
              class="input-auto"
              :value="item.routing_number"
              dense
              flat
              hide-details
              @change="updateAccount({ id: item.id, routing_number: $event })"
            />
          </div>
        </template>
        <template #item.account_name="{ item }">
          <div>
            <v-text-field
              class="input-auto"
              :value="item.account_name"
              dense
              flat
              hide-details
              :readonly="item.source !== 'INTERNAL'"
              @change="updateAccount({ id: item.id, account_name: $event })"
            />
          </div>
        </template>
        <template #item.code="{ item }">
          <div>
            <v-text-field
              class="input-auto"
              :value="item.code"
              dense
              flat
              hide-details
              :readonly="item.source === 'XERO'"
            />
          </div>
        </template>
        <template #item.is_default_cleaning="{ item }">
          <div v-if="item.is_default_cleaning" class="text-overline">
            is Default
          </div>
          <v-btn
            v-else
            color="primary"
            small
            @click="updateAccount({ id: item.id, is_default_cleaning: true })"
            >set default</v-btn
          >
        </template>
        <template #item.is_default_booking="{ item }">
          <div v-if="item.is_default_booking" class="text-overline">
            is Default
          </div>
          <v-btn
            v-else
            color="primary"
            small
            @click="updateAccount({ id: item.id, is_default_booking: true })"
            >set default</v-btn
          >
        </template>
        <template #item.is_default_management="{ item }">
          <div v-if="item.is_default_management" class="text-overline">
            is Default
          </div>
          <v-btn
            v-else
            color="primary"
            small
            @click="updateAccount({ id: item.id, is_default_management: true })"
            >set default</v-btn
          >
        </template>
        <template #item.is_default_expense="{ item }">
          <div v-if="item.is_default_expense" class="text-overline">
            is Default
          </div>
          <v-btn
            v-else
            color="primary"
            small
            @click="updateAccount({ id: item.id, is_default_expense: true })"
            >set default</v-btn
          >
        </template>
        <template #item.is_default_payment="{ item }">
          <div v-if="item.is_default_payment" class="text-overline">
            is Default
          </div>
          <v-btn
            v-else
            color="primary"
            small
            @click="updateAccount({ id: item.id, is_default_payment: true })"
            >set default</v-btn
          >
        </template>
        <template #item.company_account="{ item }">
          {{ item.company_account ? 'True' : 'False' }}
        </template>
        <template #item.user_id="{ item }">
          {{ nameById(item.user_id) }}
        </template>
        <template #item.listings="{ item }">
          <div class="mt-5">
            <multi-property-picker
              :disabled="!isEmpty(item.owner_listings.map(l => l.id))"
              class="input-auto"
              :value="item.listings.map(l => l.id)"
              :label="$t('Connected Listings')"
              :hide-btns="true"
              @input="updateAccount({ id: item.id, listing_ids: $event })"
            />
          </div>
        </template>
        <template #item.owner_listings="{ item }">
          <div class="mt-5">
            <multi-property-picker
              :disabled="!isEmpty(item.listings.map(l => l.id))"
              class="input-auto"
              :value="item.owner_listings.map(l => l.id)"
              :label="$t('Connected Owner Listings')"
              :hide-btns="true"
              @input="updateAccount({ id: item.id, owner_listing_ids: $event })"
            />
          </div>
        </template>
        <template #item.balance="{ item }">
          {{ dollarFormatter(item.balance) }}
        </template>
      </v-data-table>
    </v-row>
  </v-container>
</template>

<script>
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import { debounce, findIndex } from 'lodash'
import FormattersMixin from 'components/mixins/formatters-mixin'
import MultiPropertyPicker from 'components/multi-property-picker'
import { mapActions } from 'vuex'
import PlaidLink from 'components/plaid/plaid-link.vue'

export default {
  name: 'BankAccounts',
  components: { MultiPropertyPicker, PlaidLink },
  mixins: [CommonFunctions, PermissionsMixin, FormattersMixin],
  data: function () {
    return {
      filters: {
        source: 'All',
        search: '',
        only_connected: true,
      },
      tableHeaders: [
        { text: 'Account Name', value: 'account_name', sortable: false },
        { text: 'Account Number', value: 'account_number', sortable: false },
        { text: 'Routing Number', value: 'routing_number', sortable: false },
        { text: 'Source', value: 'source', sortable: false },
        { text: 'Account Id', value: 'account_id', sortable: false },
        { text: 'Status', value: 'status', sortable: false },
        ...(this.$store.state.app.configuration.has_xero && [
          { text: 'Code', value: 'code', sortable: false },
        ]),
        ...(this.$store.state.app.configuration.has_xero && [
          {
            name: 'is default cleaning',
            text: 'Is Default Cleaning',
            sortable: false,
            value: 'is_default_cleaning',
          },
        ]),
        ...(this.$store.state.app.configuration.has_xero && [
          {
            name: 'is default booking',
            text: 'Is Default Booking',
            sortable: false,
            value: 'is_default_booking',
          },
        ]),
        ...(this.$store.state.app.configuration.has_xero && [
          {
            name: 'is default management',
            text: 'Is Default Management',
            sortable: false,
            value: 'is_default_management',
          },
        ]),
        {
          name: 'is default expense',
          text: 'Is Default Expense',
          sortable: false,
          value: 'is_default_expense',
        },
        {
          name: 'is default payment',
          text: 'Is Default Payment',
          sortable: false,
          value: 'is_default_payment',
        },
        {
          text: 'Is Company Account',
          value: 'company_account',
          sortable: false,
        },
        { text: 'Balance', value: 'balance', sortable: false },
        { text: 'User', value: 'user_id', sortable: false },
        { text: 'Listings', value: 'listings', sortable: false },
        { text: 'Owner Listings', value: 'owner_listings', sortable: false },
      ],
      fetchingData: true,
      pagination: {
        page: 1,
        itemsPerPage: 25,
        itemsLength: null,
      },
      items: [],
      fetchDataDebounce: debounce(this.fetchData, 300),
    }
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    ...mapActions('bankAccounts', [
      'searchBankAccounts',
      'updateBankAccount',
      'createNewBankAccount',
    ]),
    fetchData(page) {
      this.fetchingData = true
      if (page) this.pagination.page = page
      this.searchBankAccounts({
        page: this.pagination.page,
        per_page: this.pagination.itemsPerPage,
        ...this.filters,
      })
        .then(data => {
          this.items = data.items
          this.pagination = {
            page: parseInt(data.pagi_info.page),
            itemsPerPage: parseInt(data.pagi_info.per_page),
            itemsLength: parseInt(data.pagi_info.count),
          }
        })
        .finally(() => {
          this.fetchingData = false
        })
    },
    paginationChanged(pagination) {
      const page = this.pagination.page
      const itemsPerPage = this.pagination.itemsPerPage
      this.pagination = pagination
      if (
        page !== pagination.page ||
        itemsPerPage !== pagination.itemsPerPage
      ) {
        this.fetchData()
      }
    },
    updateAccount(payload) {
      this.updateBankAccount(payload).then(() => {
        const accountIndex = findIndex(this.items, { id: payload.id })
        if (accountIndex !== -1) {
          this.items[accountIndex] = { ...this.items[accountIndex], ...payload }
        }
        this.fetchData()
      })
    },
    addNewAccount() {
      this.createNewBankAccount({ account_name: 'NEW BANK ACCOUNT' }).then(
        () => {
          this.filters.source = 'All'
          this.filters.only_connected = false
          this.filters.search = 'NEW BANK ACCOUNT'
          this.fetchData()
        }
      )
    },
  },
}
</script>

<style scoped></style>
