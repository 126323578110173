<template>
  <v-container>
    <v-card>
      <v-card-title>
        General Ledger
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
          class="mr-4"
          @input="fetchTransactions"
        ></v-text-field>
      </v-card-title>

      <v-card-text>
        <div class="d-flex align-center mb-4">
          <div class="d-flex">
            <div class="text-h6 mr-4">
              Total Debit: {{ $options.filters.currency(meta.total_debit) }}
            </div>
            <div class="text-h6 mr-4">
              Total Credit: {{ $options.filters.currency(meta.total_credit) }}
            </div>
            <div class="text-h6">
              Balance: {{ $options.filters.currency(meta.balance) }}
            </div>
          </div>
        </div>

        <v-row>
          <v-col cols="3">
            <v-menu
              v-model="fromMenu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="filters.dateFrom"
                  label="From Date"
                  readonly
                  v-on="on"
                  outlined
                  dense
                  clearable
                  @click:clear="handleFilterChange"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="filters.dateFrom"
                no-title
                @input="handleFilterChange"
              ></v-date-picker>
            </v-menu>
          </v-col>

          <v-col cols="3">
            <v-menu
              v-model="toMenu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="filters.dateTo"
                  label="To Date"
                  readonly
                  v-on="on"
                  outlined
                  dense
                  clearable
                  @click:clear="handleFilterChange"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="filters.dateTo"
                no-title
                @input="handleFilterChange"
              ></v-date-picker>
            </v-menu>
          </v-col>

          <v-col cols="3">
            <v-select
              v-model="filters.accountType"
              :items="accountTypes"
              label="Account Type"
              outlined
              dense
              clearable
              @change="handleFilterChange"
            ></v-select>
          </v-col>

          <v-col cols="3">
            <v-select
              v-model="filters.sourceType"
              :items="sourceTypes"
              label="Source Type"
              outlined
              dense
              clearable
              @change="handleFilterChange"
            ></v-select>
          </v-col>
        </v-row>
      </v-card-text>

      <v-data-table
        :headers="headers"
        :items="transactions"
        :loading="loading"
        :options="paginationServer"
        :server-items-length="paginationServer.count"
        :footer-props="{
          itemsPerPageOptions: [10, 50, 100, 200],
        }"
        class="elevation-1"
        @pagination="paginationChanged($event)"
      >
        <template v-slot:item.posted_at="{ item }">
          {{ new Date(item.posted_at).toLocaleDateString() }}
        </template>

        <template v-slot:item.account="{ item }">
          <v-btn
            small
            text
            color="primary"
            @click="viewAccount(item.ledger_account)"
          >
            {{ item.ledger_account.name }}
          </v-btn>
        </template>

        <template v-slot:item.amount="{ item }">
          <span :class="getAmountClass(item)">
            {{ $options.filters.currency(item.amount) }}
          </span>
        </template>

        <template v-slot:item.transaction_type="{ item }">
          <v-chip
            small
            :color="getTransactionTypeColor(item.transaction_type)"
            :text-color="getTransactionTypeTextColor(item.transaction_type)"
            label
            outlined
          >
            {{ item.transaction_type }}
          </v-chip>
        </template>

        <template v-slot:item.transactionable_type="{ item }">
          <v-chip small>
            {{ formatTransactionableType(item.transactionable_type) }}
          </v-chip>
        </template>

        <template v-slot:item.reference="{ item }">
          <v-btn
            v-if="item.reference_id"
            small
            text
            color="primary"
            @click="viewReference(item)"
          >
            {{ getReferenceText(item) }}
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import CommonFunctions from 'components/mixins/common_functions'
import LedgerFunctions from 'components/mixins/ledger-functions-mixin'
import axios from 'axios'

export default {
  name: 'GeneralLedger',
  mixins: [CommonFunctions, LedgerFunctions],
  // ... mixins and filters stay the same ...

  data: () => ({
    search: '',
    loading: false,
    transactions: [],
    fromMenu: false,
    toMenu: false,
    paginationServer: {
      page: 1,
      itemsPerPage: 10,
      count: 0,
    },
    filters: {
      dateFrom: null,
      dateTo: null,
      accountType: null,
      sourceType: null,
    },
    headers: [
      { text: 'Date', value: 'posted_at' },
      { text: 'Account', value: 'account' },
      { text: 'Description', value: 'description' },
      { text: 'Type', value: 'transaction_type' },
      { text: 'Amount', value: 'amount' },
      { text: 'Source Type', value: 'transactionable_type' },
      { text: 'Reference', value: 'reference' },
    ],
    accountTypes: [
      { text: 'Asset', value: 'asset' },
      { text: 'Liability', value: 'liability' },
      { text: 'Equity', value: 'equity' },
      { text: 'Income', value: 'income' },
      { text: 'Expense', value: 'expense' },
    ],
    sourceTypes: [], // Will be populated from API
    meta: {
      total_debit: 0,
      total_credit: 0,
      balance: 0,
    },
  }),

  watch: {
    options: {
      handler() {
        this.fetchTransactions()
      },
      deep: true,
    },
  },

  methods: {
    paginationChanged(pagination) {
      if (this.paginationServer.itemsPerPage !== pagination.itemsPerPage) {
        this.paginationServer.itemsPerPage = pagination.itemsPerPage
        this.paginationServer.page = 1
        this.fetchTransactions()
      } else if (this.paginationServer.page !== pagination.page) {
        this.paginationServer.page = pagination.page
        this.fetchTransactions()
      }
    },

    handleFilterChange() {
      this.paginationServer.page = 1
      this.fetchTransactions()
    },

    async fetchTransactions() {
      this.loading = true
      try {
        const params = {
          page: this.paginationServer.page,
          per_page: this.paginationServer.itemsPerPage,
          search: this.search,
          ...this.filters,
        }

        const response = await axios.get('/api/ledger-accounts/transactions', {
          params,
        })
        this.transactions = response.data.transactions
        this.paginationServer.count = response.data.pagination.count
        this.meta = response.data.meta
        this.updateSourceTypes(response.data.source_types)
      } catch (error) {
        console.error('Error fetching transactions:', error)
        this.$store.dispatch('showError', 'Failed to load transactions')
      } finally {
        this.loading = false
      }
    },

    updateSourceTypes(types) {
      this.sourceTypes = types.map(type => ({
        text: this.formatTransactionableType(type),
        value: type,
      }))
    },
  },

  created() {
    this.fetchTransactions()
  },
}
</script>
