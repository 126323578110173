<template>
  <v-data-table
    :headers="headers"
    :expanded.sync="expanded"
    show-expand
    item-key="id"
    :items="items"
  >
    <template #item.type="{ item }">
      <div class="text-capitalize">
        {{ item.type }}
      </div>
    </template>
    <template #item.amount="{ item }">
      <div>
        {{ dollarFormatter(item.amount) }}
      </div>
    </template>
    <template #item.paid_at="{ item }">
      <div>
        {{ item.paid_at }}
      </div>
    </template>
    <template #item.status="{ item }">
      <div
        :class="[
          'text-capitalize',
          'text-caption font-weight-medium',
          'mr-2',
          'line-spacing',
          item.status === 'succeeded' || item.status === 'requires_capture'
            ? 'green--text'
            : 'red--text',
        ]"
      >
        {{ formatStatus(item.status) }}
      </div>
    </template>
    <template #item.actions="{ item }">
      <div class="d-flex justify-end align-center grid-gap-10">
        <refund-dialog
          v-if="
            (isAdmin || hasAbility('refund')) &&
            item.status === 'succeeded' &&
            item.isRefundable
          "
          type="refund"
          :intent-id="item.payment_intent_id"
          :total="item.amount"
          @success="getReservation"
        />
        <span
          v-if="item.status === 'succeeded' && !item.isRefundable"
          class="text-caption green--text"
        >
          Fully refunded
        </span>
        <CopyClipboard
          description="Copy payment link"
          :text="`${portalDomain}/checkout/${item.payment_intent_id}`"
          >Copy Link</CopyClipboard
        >
        <a
          class="text-decoration-noneå"
          :target="isMobile ? '_self' : '_blank'"
          :href="`${portalDomain}/checkout/${item.payment_intent_id}`"
        >
          <v-btn x-small color="green lighten-3" depressed>Payment link</v-btn>
        </a>
        <refund-dialog
          v-if="
            (isAdmin || hasAbility('refund') || isCommunicationManager) &&
            item.type === 'deposit' &&
            item.status === 'requires_capture'
          "
          type="capture"
          :intent-id="item.payment_intent_id"
          :total="item.amount"
        />
        <charge-split-modal
          v-if="
            ((isCommunicationAgent || managementRoles) &&
              item.type === 'accommodation' &&
              item.status === 'requires_source') ||
            item.status === 'requires_payment_method'
          "
          :balance="reservation.balance_due"
          :intent="item"
          :on-change="getReservation"
        />
        <charge-modal
          v-if="
            ((isCommunicationAgent || managementRoles) &&
              item.type === 'accommodation' &&
              item.status === 'requires_source') ||
            item.status === 'requires_payment_method'
          "
          :can-charge-third="isHotelReception || isAdmin"
          :guest-email="reservation.guest.emails[0]"
          :balance="item.amount"
          :intent="item"
          @charge-success="getReservation"
          @payment-canceled="getReservation({ refresh: true })"
        />
        <span
          v-if="
            item.type === 'deposit' &&
            item.status === 'succeeded' &&
            item.isRefundable
          "
          class="text-caption green--text"
        >
          Captured({{ dollarFormatter(item.amount_received) }})
        </span>
      </div>
    </template>
    <template #expanded-item="{ item }">
      <td></td>
      <td>
        <div v-for="charge in item.charges" :key="charge.id" class="py-1">
          <span v-if="charge.reason">
            Refund
            <info-tooltip>{{ charge.reason }}</info-tooltip>
          </span>
          <span v-else>Charged</span>
          <span
            v-if="
              charge.payment_method_details &&
              charge.payment_method_details.card
            "
          >
            (xxxx-{{ charge.payment_method_details.card.last4 }})
          </span>
        </div>
      </td>
      <td>
        <div v-for="charge in item.charges" :key="charge.id" class="py-1">
          {{ dollarFormatter(charge.amount / 100) }}
        </div>
      </td>
      <td>
        <div
          v-for="charge in item.charges"
          :key="charge.id"
          class="py-1"
          :class="charge.status === 'succeeded' ? 'green--text' : 'red--text'"
        >
          {{ charge.status }}
        </div>
      </td>
      <td>
        <div v-for="charge in item.charges" :key="charge.id" class="py-1">
          {{
            parseTimestamp(charge.created, 'ddd, MMM Do HH:mm', {
              local: true,
            })
          }}
        </div>
      </td>
    </template>
  </v-data-table>
</template>

<script>
import InfoTooltip from 'components/common/info-tooltip'
import FormattersMixin from 'components/mixins/formatters-mixin'
import ChargeModal from 'components/common/charge-modal'
import RefundDialog from 'components/stripe/refund-dialog'
import ChargeSplitModal from 'components/common/charge-split-modal'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import Common_functions from 'components/mixins/common_functions'
import { paymentIntents } from 'components/reservation/config'
import CopyClipboard from 'components/copy-clipboard.vue'
import deviceMixin from 'components/mixins/device-mixin'
export default {
  name: 'ReservationIntentsTable',
  components: {
    CopyClipboard,
    RefundDialog,
    ChargeModal,
    InfoTooltip,
    ChargeSplitModal,
  },
  mixins: [FormattersMixin, PermissionsMixin, Common_functions, deviceMixin],
  props: ['reservation', 'getReservation'],
  data() {
    return {
      expanded: [],
      headers: [
        { text: 'Payment Intents', value: 'type', width: '20%' },
        { text: 'Amount', value: 'amount' },
        { text: 'Status', value: 'status' },
        { text: 'Paid at', value: 'paid_at' },
        { text: '', value: 'actions' },
      ],
    }
  },
  computed: {
    portalDomain() {
      return 'https://guest.easyair.io'
    },
    items() {
      return paymentIntents(this.reservation)
    },
  },
}
</script>

<style scoped>
.line-spacing {
  letter-spacing: 0.32px;
}
.grid-gap-10 {
  grid-gap: 15px;
}
</style>
