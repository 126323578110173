<template>
  <form-dialog
    :max-width="650"
    title="Create new catalog item"
    @submit="saveCatalogItem"
  >
    <template #activator="{ on }">
      <v-btn class="text-capitalize" elevation="0" v-on="on"
        >Create Catalog</v-btn
      >
    </template>
    <v-row no-gutters>
      <v-col cols="12">
        <v-select
          v-model="item.division"
          outlined
          dense
          :rules="[required]"
          :items="divisions"
          class="small-select"
          label="Division"
        />
      </v-col>
      <v-col cols="12">
        <v-combobox
          v-model="item.department"
          outlined
          dense
          :rules="[required]"
          :items="listingTaskTypes"
          class="small-select"
          label="Topic"
        />
      </v-col>
      <v-col v-if="item.department" cols="12">
        <v-row no-gutters>
          <v-col cols="12">
            <v-combobox
              v-model="item.sub_topic"
              outlined
              dense
              :rules="[required]"
              :items="subTopics(item.department)"
              class="small-select"
              label="Category"
            />
          </v-col>
        </v-row>
      </v-col>
      <v-col v-if="isDesignedVr && item.sub_topic && item.department" cols="12">
        <v-text-field
          v-model="item.description"
          outlined
          dense
          class="small-text-input"
          label="action description"
        />
      </v-col>
      <v-col cols="12">
        <v-textarea
          v-model="item.long_description"
          rows="3"
          outlined
          dense
          label="Long description"
        />
      </v-col>
      <v-col cols="12">
        <contractor-select
          icon="$account_alt"
          :enable-sug="true"
          :value.sync="item.user_id"
        />
      </v-col>
      <v-col cols="12">
        <v-select
          v-model="item.approver_id"
          :items="approvers"
          label="Approver"
          item-text="name"
          item-value="id"
          dense
          outlined
        />
      </v-col>
      <v-col cols="12">
        <div class="font-weight-medium">{{ $t('Charged to') }}:</div>
        <v-radio-group v-model="item.charged_by" row>
          <v-radio value="client" label="Owner" />
          <v-radio value="business" label="Company" />
        </v-radio-group>
      </v-col>
      <v-col cols="12">
        <expenses-types-autocomplete
          v-model="item.auto_expense_type"
          :is-business-expense="item.charged_by === 'business'"
          outlined
          :label="$t('Expense Type')"
        />
      </v-col>
      <v-col cols="12">
        <lt-sub-task-list-item
          :after-change="checkListChange"
          :existing-items="item.checklist"
        />
      </v-col>
      <v-col cols="12">
        <v-checkbox
          v-if="item.checklist.length"
          v-model="item.inspection_mode"
          dense
          class="checkbox-txt mt-0"
          label="Inspection Mode"
        />
      </v-col>
    </v-row>
  </form-dialog>
</template>

<script>
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import FormRulesMixin from 'components/mixins/form-rules-mixin'
import FormDialog from 'components/common/form-dialog'
import ContractorSelect from 'components/contractor-select'
import LtSubTaskListItem from 'components/listing-tasks/lt-sub-task-list-item'
import { mapActions, mapGetters, mapState } from 'vuex'
import ExpensesTypesAutocomplete from 'components/expenses-types-autocomplete.vue'

export default {
  components: {
    LtSubTaskListItem,
    ContractorSelect,
    FormDialog,
    ExpensesTypesAutocomplete,
  },
  mixins: [CommonFunctions, PermissionsMixin, FormRulesMixin],
  mounted() {
    this.getTaskTypes()
  },
  data() {
    return {
      item: {
        checklist: [],
        charged_by: 'client',
        inspection_mode: true,
      },
      search: null,
      createNewSubTopic: false,
    }
  },
  computed: {
    ...mapGetters(['listingTaskTypes']),
    ...mapGetters('users', ['approvers']),
    ...mapState('zones', ['zones']),
    zoneItems() {
      return this.zones.map(a => ({ text: a.name, value: a.id }))
    },
  },
  methods: {
    ...mapActions(['getTaskTypes']),
    checkListChange(val) {
      this.item.checklist = val
    },
    saveCatalogItem() {
      this.$store.dispatch('createListingTaskCatalogItem', this.item)
    },
  },
}
</script>
