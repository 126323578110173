import round from 'lodash/round'
import get from 'lodash/fp/get'

const METER_PER_MILE = 0.000621371192

export default {
  data() {
    return {
      availabilityTextMap: {
        'Check-in and Checkout today': 'Check-in/out',
        'Check-in today': 'Check-in',
        'Checkout today': 'Check-out',
        'Free today': 'Free',
        'Blocked today': 'Blocked',
      },
    }
  },
  computed: {
    currencySign() {
      const locale = get('config.locale', window) || 'US'
      const currency = new Intl.NumberFormat(`en-${locale}`, {
        style: 'currency',
        currency: get('config.currency', window) || 'USD',
        maximumFractionDigits: 0,
      })
      return currency.formatToParts(0).find(c => c.type === 'currency')?.value
    },
  },
  methods: {
    toMoney(num, decimal = 2, currency = '$') {
      return num ? `${currency}${round(num, decimal).toLocaleString()}` : null
    },
    sourceName(name = '') {
      if (name?.toLowerCase() === 'homeaway') {
        return 'VRBO'
      }
      return name
    },
    toPercent(num, decimal = 0) {
      return num && `${num ? num.toFixed(decimal) : '0'}%`
    },
    parseDate: function (date, format = 'YYYY-MM-DD', config = {}) {
      if (date) {
        const parsedDate = config.local
          ? this.$moment(date)
          : this.$moment.utc(date)
        if (parsedDate) {
          return parsedDate.format(format)
        }
      } else {
        return ''
      }
    },
    parseTimestamp: function (date, format = 'YYYY-MM-DD', config = {}) {
      if (date) {
        const parsedDate = config.local
          ? this.$moment.unix(date)
          : this.$moment.unix(date).utc()
        if (parsedDate) {
          return parsedDate.format(format)
        }
      } else {
        return ''
      }
    },
    fromNow(time) {
      return this.$moment(time).fromNow()
    },
    dollarFormatter(amount, maximumFractionDigits = 2) {
      if (!amount && amount !== 0) return null
      const fixedAmount = amount > -1 && amount < 1 ? 0 : amount
      const locale = get('config.locale', window) || 'US'
      return new Intl.NumberFormat(`en-${locale}`, {
        style: 'currency',
        currency: get('config.currency', window) || 'USD',
        maximumFractionDigits,
      }).format(fixedAmount)
    },
    kFormatter(num, decimal = 2) {
      return Math.abs(num) > 999 && this.$vuetify.breakpoint.xs
        ? this.dollarFormatter(
            Math.sign(num) * (Math.abs(num) / 1000).toFixed(1),
            decimal
          ) + 'k'
        : this.dollarFormatter(Math.sign(num) * Math.abs(num), decimal)
    },
    metersToMiles(meters, decimals = 1) {
      return round(meters * METER_PER_MILE, decimals)
    },
    round(val, decimals = 0) {
      return val ? round(val, decimals) : null
    },
  },
}
